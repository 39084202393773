import { Button, Popover, PopoverButton, PopoverPanel, Textarea } from "@headlessui/react"
import { connect } from "react-redux";
import React, { Component } from 'react'
import Spinner from "../../components/Spinner"
import invoicesActions from "../../redux/invoices/actions";
import moment from "moment";

const {
  invoicesUpdateInvoiceStatus,
} = invoicesActions;

const btnTextMap = {
  "TO_REVIEW"  : "Mark invoice as reviewed",
  "TO_APPROVE" : "Approve",
  "TO_RELEASE" : "Release",
  "TO_PAY"     : "Pay",
}

const workflow_data = {
  "TO_REVIEW": "CREATED",
  "TO_APPROVE": "REVIEWED",
  "TO_PAY": "APPROVED",
  "PAID": "RELEASED",
}

class InvoiceWorkflow extends Component {
  state = {
    description: "",
  }

  displayWorkflow = () => {
    const { invoice } = this.props;
    if(!invoice || !invoice?.history){ return null };

    const history = {...invoice?.history}
    var history_data = {};

    ["TO_REVIEW", "TO_APPROVE", "TO_PAY", "PAID"].forEach(status => {
      if(history[status]){ history_data[status] = history[status].latest };
    })

    return (
      <div className="flex items-center gap-4 mb-4">
        {Object.keys(history_data).map((status, idx) => (
        <>
        {idx > 0 && <span>&rarr;</span>}
        <div key={`stage-${status}`} className="flex flex-col justify-center px-4 py-2 rounded border bg-slate-100 text-slate-500 min-h-24">
          <span className="block font-semibold">{workflow_data[status]}</span>
          <span className="text-sm">by: {history_data[status].user_name}</span>
            <span className="text-sm">date: {moment(history_data[status].created_at).format("L")}</span>
          {history_data[status]?.description &&
          <Popover className="relative">
            <PopoverButton as="span" role="button" className="text-nowrap text-sm underline hover:no-underline">View notes</PopoverButton>
            <PopoverPanel className="absolute px-2 py-1 rounded text-sm bg-slate-700 text-white min-w-80">
              {history_data[status]?.description}
            </PopoverPanel>
          </Popover>
          }
        </div>
        </>
        ))}
      </div>
    )
  }

  render() {
    const {
      userData,
      invoices_working,
      invoice,
    } = this.props

    return (
      <div>
        <h3 className="font-semibold text-lg mb-3">Workflow</h3>
        {this.displayWorkflow()}
        {["PAID", "REJECTED", "HOLD"].includes(invoice?.invoice_status)
        ? null
        : <>
          <Textarea
            placeholder="Provide notes"
            className="w-full"
            value={this.state.description}
            onChange={(e) => this.setState({description: e.target.value})}
          />
          <div className="flex justify-between mt-1">
              <Button
                className="btn-success"
                disabled={invoices_working}
                onClick={() => {
                  this.props.invoicesUpdateInvoiceStatus({
                    client_id: userData?.active_client?.id,
                    invoice_id: invoice?.id,
                    move_to_next: true,
                    description: this.state.description,
                  })
                  this.setState({description: ""});
                }}
              >
                <span className="flex gap-1 justify-center items-center">
                  {invoices_working && <Spinner />}
                  <span>{btnTextMap[invoice?.invoice_status]}</span>
                </span>
              </Button>
              <div className="flex gap-4">
                {invoice?.invoice_status !== 'PAID' &&
                <Button
                  className="btn-warning"
                  disabled={invoices_working}
                  onClick={() => {
                    this.props.invoicesUpdateInvoiceStatus({
                      client_id: userData?.active_client?.id,
                      invoice_id: invoice?.id,
                      new_status: "HOLD",
                      description: this.state.description,
                    })
                    this.setState({description: ""});
                  }}
                >
                  <span className="flex gap-1 justify-center items-center">
                    {invoices_working && <Spinner />}
                    <span>Hold Invoice</span>
                  </span>
                </Button>
                }
                {(invoice?.invoice_status === 'TO_REVIEW' || invoice?.invoice_status === 'TO_APPROVE') &&
                <Button
                  className="btn-danger"
                  disabled={invoices_working}
                  onClick={() => {
                    this.props.invoicesUpdateInvoiceStatus({
                      client_id: userData?.active_client?.id,
                      invoice_id: invoice?.id,
                      new_status: "REJECTED",
                      description: this.state.description,
                    })
                    this.setState({description: ""});
                  }}

                >
                  <span className="flex gap-1 justify-center items-center">
                    {invoices_working && <Spinner />}
                    <span>Reject Invoice</span>
                  </span>
                </Button>
                }
              </div>
            </div>
          </>
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    invoices_working: state.Invoices.get("invoices_working"),
    invoice: state.Invoices.get("invoice"),
  }),
  {
    invoicesUpdateInvoiceStatus,
  }
)(InvoiceWorkflow)
