const companiesActions = {
	COMPANIES_GET_USERS: 'COMPANIES_GET_USERS',

  companiesGetUsers: (data) => ({
    type: companiesActions.COMPANIES_GET_USERS,
    data
  }),
}

export default companiesActions;
