import { all, takeLatest, put, fork, call } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* userGetData() {
	yield takeLatest(actions.USER_GET_DATA, function*(payload) {
		try {
			const res = yield call(apiCalls.get, apiEndpoints.user.user+"?full=true", null);
			if (res.data.error) {
				yield put(actions.userGetDataError());
			} else {
				yield put(actions.userGetDataSuccess(res.data));
        const { email, username } = res.data.data;
        window.CRISP_TOKEN_ID = username;
        window.$crisp.push(["do", "session:reset"]);
        window.$crisp.push(["set", "user:email", [email]]);
        window.$crisp.push(["set", "user:nickname", [username]]);
			}
		} catch (error) {
				yield put(actions.userGetDataError());
		}
	})
}

export function* userUpdateData() {
  yield commonSaga(
    actions.USER_UPDATE_DATA,
    apiCalls.put,
    apiEndpoints.user.user,
    {
      ...default_config,
      ...{
        success_show: true
      }
    }
  );
}

export function* userUpdateSettings() {
  yield commonSaga(
    actions.USER_UPDATE_SETTINGS,
    apiCalls.put,
    apiEndpoints.user.settings,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* userPasswordReset() {
  yield commonSaga(
    actions.USER_PASSWORD_RESET,
    apiCalls.put,
    apiEndpoints.user.password,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* userSwitchClient() {
  yield commonSaga(
    actions.USER_SWITCH_CLIENT,
    apiCalls.post,
    apiEndpoints.user.client_switch,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export default function* authSaga() {
	yield all([
		fork(userGetData),
		fork(userUpdateData),
		fork(userPasswordReset),
    fork(userUpdateSettings),
    fork(userSwitchClient),
	]);
}
