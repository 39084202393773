import { Map } from "immutable";

const initState = new Map({
  accounts: {},
  accounts_working: false,
  accounts_search: [],
  accounts_search_working: false,
  forms_1099: [],
  forms_1099_working: false,
});

export default function accountingReducer(state = initState, action) {
  switch (action.type) {

    case "ACCOUNTING_UPDATE_ACCOUNT":
      return state
        .set("accounts_working", true)
    case "ACCOUNTING_UPDATE_ACCOUNT_SUCCESS":
      var accounts_update = state.get("accounts")
      accounts_update.data = accounts_update.data.map(item => {
        if(item.id === action.data.data.id){ return action.data.data }
        return item;
      })
      return state
        .set("accounts", {...accounts_update})
        .set("accounts_working", false)
    case "ACCOUNTING_UPDATE_ACCOUNT_FAILED":
      return state
        .set("accounts_working", false)

    case "ACCOUNTING_ADD_ACCOUNT":
      return state
        .set("accounts_working", true)
    case "ACCOUNTING_ADD_ACCOUNT_SUCCESS":
      var accounts_add = state.get("accounts")
      accounts_add.data.unshift({...action.data.data});
      return state
        .set("accounts", {...accounts_add})
        .set("accounts_working", false)
    case "ACCOUNTING_ADD_ACCOUNT_FAILED":
      return state
        .set("accounts_working", false)

    case "ACCOUNTING_ALL_ACCOUNTS":
      return state
        .set("accounts", {})
        .set("accounts_working", true)
    case "ACCOUNTING_ALL_ACCOUNTS_SUCCESS":
      return state
        .set("accounts", {...action.data.data})
        .set("accounts_working", false)
    case "ACCOUNTING_ALL_ACCOUNTS_FAILED":
      return state
        .set("accounts", {})
        .set("accounts_working", false)

    case "ACCOUNTING_GL_1099_ALL":
      return state
        .set("forms_1099", [])
        .set("forms_1099_working", true)
    case "ACCOUNTING_GL_1099_ALL_SUCCESS":
      return state
        .set("forms_1099", [...action.data.data])
        .set("forms_1099_working", false)
    case "ACCOUNTING_GL_1099_ALL_FAILED":
      return state
        .set("forms_1099", [])
        .set("forms_1099_working", false)

    case "ACCOUNTING_GL_ACCOUNTS_SEARCH":
      return state
        .set("accounts_search", [])
        .set("accounts_search_working", true)
    case "ACCOUNTING_GL_ACCOUNTS_SEARCH_SUCCESS":
      return state
        .set("accounts_search", [...action.data.data])
        .set("accounts_search_working", false)
    case "ACCOUNTING_GL_ACCOUNTS_SEARCH_FAILED":
      return state
        .set("accounts_search", [])
        .set("accounts_search_working", false)

		default:
			return state;
  }
}
