import { Button, Field, Input, Label, Radio, RadioGroup, Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react"
import React, { Component } from 'react'
import { CountrySelect, StateSelect } from 'react-country-state-city'
import ReactInputMask from "react-input-mask"
import Select from 'react-select'
import Spinner from "../../components/Spinner"

const tax_classification = [
  {value: 'individual_single_member_llc', label: 'Individual/sole proprietor or single-member LLC'},
  {value: 'c_corporation', label: 'C Corporation'},
  {value: 's_corporation', label: 'S Corporation'},
  {value: 'partnership', label: 'Partnership'},
  {value: 'trust_estate', label: 'Trust/estate'},
  {value: 'llc', label: 'Limited Liability Company'},
  {value: 'other', label: 'Other'}
]

const llc_tax_classification = [
  { value: 'llc_c_corporation', label: 'C corporation' },
  { value: 'llc_s_corporation', label: 'S corporation' },
  { value: 'llc_partnership', label: 'Partnership' },
]

class QuickAddVendor extends Component {
  state = {
    new_vendor_data: null,
    tax_classification: null,
    tax_tin_type: 'ssn',
    vendors_working: false
  }

  setVendorData = (key, value) => {
    const { new_vendor_data } = this.state;
    if(new_vendor_data){
      var new_vendor = {...new_vendor_data};
      new_vendor[key] = value;
      if(key === "address_country_data"){
        new_vendor["address_country"] = value.name;
      }
      if(key === "address_state_data"){
        new_vendor["address_state"] = value.name;
      }
      this.setState({new_vendor_data: new_vendor})
    } else {
      this.setState({new_vendor_data: {[key]: value}})
      if(key === "address_country_data"){
        this.setState({address_country: value.name});
      }
      if(key === "address_state_data"){
        this.setState({address_state: value.name});
      }
    }
  }

  render() {
    const { saveAndSelect, cancel } = this.props

    return (
    <div className="p-3 bg-slate-50 mt-4 rounded">
      <TabGroup>
        <TabList>
          <Tab>General</Tab>
          <Tab>Banking Info (ACH)</Tab>
          <Tab>W-9 Info</Tab>
        </TabList>
        <TabPanels className="mt-4">
          <TabPanel>
            <div className="grid grid-cols-2 gap-4">
              <Field className="field">
                <Label>Vendor Name</Label>
                <Input
                  type="text"
                  required
                  onChange={(e) => this.setVendorData("name", e.target.value)} 
                />
              </Field>
              <Field className="field">
                <Label>Vendor Account Number</Label>
                <Input type="text" />
              </Field>
            </div>
            <div className="mt-4">
              <Field className="field">
                <Label>Address Line 1</Label>
                <Input
                  type="text"
                  required
                  onChange={(e) => this.setVendorData("address_line_1", e.target.value)}
                />
              </Field>
              <Field className="field mt-4">
                <Label>Address Line 2</Label>
                <Input
                  type="text"
                  onChange={(e) => this.setVendorData("address_line_2", e.target.value)}
                />
              </Field>
              <div className="flex gap-4 mt-4">
                <Field className="field flex-1">
                  <Label>Country</Label>
                  <CountrySelect
                    placeHolder="Select country"
                    showFlag={false}
                    onChange={e => this.setVendorData("address_country_data", e)}
                  />
                </Field>
                <Field className="field flex-1">
                  <Label>State/Province</Label>
                  <StateSelect
                    placeHolder="Select state/province"
                    countryid={this.state.new_vendor_data?.address_country_data?.id}
                    onChange={e => this.setVendorData("address_state_data", e)}
                  />
                </Field>
              </div>
              <div className="flex gap-4 mt-4">
                <Field className="field flex-1">
                  <Label>City</Label>
                  <Input
                    type="text"
                    onChange={(e) => this.setVendorData("address_city", e.target.value)}
                  />
                </Field>
                <Field className="field flex-1">
                  <Label>Zip Code</Label>
                  <Input
                    type="text"
                    className="max-w-36"
                    onChange={(e) => this.setVendorData("address_zip", e.target.value)}
                  />
                </Field>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <Field className="field">
              <Label>Account No.</Label>
              <ReactInputMask mask="999999999999999999999999999999" maskChar="" />
              <small className="text-slate-400">Usually 5 - 17 digits</small>
            </Field>
            <Field className="field mt-4">
              <Label>Routing No.</Label>
              <ReactInputMask mask="999999999" maskChar="" />
              <small className="text-slate-400">Routing number is 9 digits</small>
            </Field>
          </TabPanel>
          <TabPanel>
            <Field className="field">
              <Label>Legal Name</Label>
              <div className="grid grid-cols-3 gap-4">
                <Input type="text" placeholder="First Name" />
                <Input type="text" placeholder="Middle Name" />
                <Input type="text" placeholder="Last Name" />
              </div>
            </Field>
            <Field className="field mt-4">
              <Label>Business Name</Label>
              <Input type="text" />
            </Field>
            <Field className="field mt-4">
              <Label>Federal Tax Classification</Label>
              <Select
                options={tax_classification}
                value={this.state.tax_classification}
                onChange={selected => this.setState({ tax_classification: selected })}
              />
            </Field>
            {(this.state.tax_classification?.value !== 'llc' && this.state.tax_classification?.value !== 'other') &&
            <Field className="field mt-4">
              <Label>Exemptions</Label>
              <div className="flex gap-4">
                <div className="flex-1">
                  <Input type="text" className="w-full" />
                  <small className="text-slate-500 block">Exempt payee code (if any)</small>
                </div>
                <div className="flex-1">
                  <Input type="text" className="w-full" />
                  <small className="text-slate-500 block">Exemption from FATCA reporting code (if any)</small>
                </div>
              </div>
            </Field>
            }
            {this.state.tax_classification?.value === 'llc' &&
            <Field className="field mt-4">
              <Label>LLC Tax Classification</Label>
              <small className="text-slate-400">Enter the tax classification for Limited Liability Company</small>
              <Select
                options={llc_tax_classification}
              />
            </Field>
            }
            {this.state.tax_classification?.value === 'other' &&
            <Field className="field mt-4">
              <Input type="text" placeholder='Specify "Other" here' />
            </Field>
            }
            <div className="mt-4">
              <h2 className="font-semibold mb-2">Taxpayer Identification Number (TIN)</h2>
              <RadioGroup value={this.state.tax_tin_type} onChange={(selected) => this.setState({ tax_tin_type: selected })}>
                <Field className="flex items-center gap-2">
                  <Radio className="radio" value="ssn" />
                  <Label>Use Social Security Number (SSN)</Label>
                </Field>
                <Field className="flex items-center gap-2 mt-4">
                  <Radio className="radio" value="ein" />
                  <Label>Use Employer Identification Number (EIN)</Label>
                </Field>
              </RadioGroup>
              {this.state.tax_tin_type === 'ssn' ?
              <Field className="field mt-4">
                <ReactInputMask mask="999-99-9999" alwaysShowMask/>
              </Field>
              :
              <Field className="field mt-4">
                <ReactInputMask mask="99-9999999" alwaysShowMask/>
              </Field>
              }
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
      <div className="flex justify-between mt-6">
        <Button
          className="btn-secondary"
          disabled={this.state.vendors_working && !this.state.new_vendor_data}
          onClick={() => {
            this.setState({ vendors_working: true })
            setTimeout(() => this.setState({ vendors_working: false }), 1000)
            // save vendor to db, then auto select new vendor on Select dropdown
            saveAndSelect({id: 99, ...this.state.new_vendor_data})
          }}
        >
          <span className="flex gap-1 justify-center items-center">
            {this.state.vendors_working && <Spinner fillColorClass="fill-blue-500" />}
            <span>Save and select vendor</span>
          </span>
        </Button>
        <Button className="btn-tertiary" onClick={cancel}>Cancel</Button>
      </div>
    </div>
    )
  }
}

export default QuickAddVendor