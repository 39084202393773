import { Map } from "immutable";

const initState = new Map({
  vendors: {},
  vendors_working: false,
  vendors_autofill: {},
  vendors_autofill_working: false,
});

export default function vendorsReducer(state = initState, action) {
  switch (action.type) {

    case "VENDORS_AUTOFILL_SEARCH":
      return state
        .set("vendors_autofill", {})
        .set("vendors_autofill_working", true)
    case "VENDORS_AUTOFILL_SEARCH_SUCCESS":
      return state
        .set("vendors_autofill", {...action.data.data})
        .set("vendors_autofill_working", false)
    case "VENDORS_AUTOFILL_SEARCH_FAILED":
      return state
        .set("vendors_autofill", {})
        .set("vendors_autofill_working", false)

    case "VENDORS_UPDATE_VENDOR":
      return state
        .set("vendors_working", true)
    case "VENDORS_UPDATE_VENDOR_SUCCESS":
      var vendors_update = {...state.get("vendors")};
      if(!vendors_update?.data){ vendors_update.data = [] };
      vendors_update.data = vendors_update.data.map(item => {
        if(item.id === action.data.data.id){
          item = {...action.data.data}
        }
        return item;
      })
      return state
        .set("vendors", {...vendors_update})
        .set("vendors_working", false)
    case "VENDORS_UPDATE_VENDOR_FAILED":
      return state
        .set("vendors_working", false)

    case "VENDORS_ADD_VENDOR":
      return state
        .set("vendors_working", true)
    case "VENDORS_ADD_VENDOR_SUCCESS":
      var vendors_add = {...state.get("vendors")}
      if(vendors_add?.data){
        vendors_add.data = [action.data.data, ...vendors_add?.data];
      } else {
        vendors_add.data = [action.data.data];
      }
      return state
        .set("vendors", {...vendors_add})
        .set("vendors_working", false)
    case "VENDORS_ADD_VENDOR_FAILED":
      return state
        .set("vendors_working", false)

    case "VENDORS_GET_ALL_FOR_CLIENT":
      return state
        .set("vendors", {})
        .set("vendors_working", true)
    case "VENDORS_GET_ALL_FOR_CLIENT_SUCCESS":
      return state
        .set("vendors", {...action.data.data})
        .set("vendors_working", false)
    case "VENDORS_GET_ALL_FOR_CLIENT_FAILED":
      return state
        .set("vendors", {})
        .set("vendors_working", false)

		default:
			return state;
  }
}
