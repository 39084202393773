import { Button, Field, Input, Label, Radio, RadioGroup, Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import React, { Component, useState, useCallback } from "react";
import Spinner from "../../components/Spinner";
import { CountrySelect, StateSelect } from 'react-country-state-city'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
//import { sdMessage } from "../../helpers/utils";
import vendorsActions from "../../redux/vendors/actions";
import SelectGLAccount from "../../components/SelectGLAccount";
import SelectProperty from "../../components/SelectProperty";
import Select1099Box from "../../components/Select1099Box";
import Select from 'react-select'
import ReactInputMask from "react-input-mask";
import { useDropzone } from "react-dropzone"
import { ReactComponent as IconDownload } from "../../assets/icons/icon-download.svg"
import SearchVendorDuplicates from "./SearchVendorDuplicates";
import { debounce } from "lodash";

const callDebounce = debounce((func, query, searchField) => func(query, searchField), 3000);

const {
  vendorsAddVendor,
  vendorsUpdateVendor,
  vendorsAutofillSearch,
} = vendorsActions;

const tax_classification = [
  {value: 'individual_single_member_llc', label: 'Individual/sole proprietor or single-member LLC'},
  {value: 'c_corporation', label: 'C Corporation'},
  {value: 's_corporation', label: 'S Corporation'},
  {value: 'partnership', label: 'Partnership'},
  {value: 'trust_estate', label: 'Trust/estate'},
  {value: 'llc', label: 'Limited Liability Company'},
  {value: 'other', label: 'Other'}
]

const llc_tax_classification = [
  { value: 'llc_c_corporation', label: 'C corporation' },
  { value: 'llc_s_corporation', label: 'S corporation' },
  { value: 'llc_partnership', label: 'Partnership' },
]

const MAX_FILESIZE_NUMERIC = 5
const MAX_FILESIZE_BYTES = MAX_FILESIZE_NUMERIC * 1048576

function FileUpload({ setFile }) {
  const [fileName, setFileName] = useState()
  const [errors, setErrors] = useState([])

  const onDrop = useCallback((acceptedFiles, rejectedFiles)  => {
    setErrors([])
    if(acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      setFileName(file.name)
      setFile(file)
    }

    if(rejectedFiles.length > 0) {
      const file = rejectedFiles[0]
      setErrors(file.errors)
    }
  }, [setFile])

  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept: {'application/pdf': ['.pdf']},
    maxSize: MAX_FILESIZE_BYTES,
    onDrop
  })

  return (
    <div {...getRootProps()} className="border bg-white rounded-md px-5 py-12 cursor-pointer text-center">
      <input {...getInputProps()} />
      {fileName && <p className="font-semibold">{fileName}</p>}
      {errors.length > 0 && errors.map(err => <p key={err.code} className="text-red-500">{err.message}</p>)}
      <div className="text-slate-500">
        <p>Drag and drop your PDF file here.</p>
        <p>Max size: {MAX_FILESIZE_NUMERIC} MB</p>
      </div>
    </div>
  )
}

class VendorForm extends Component {
  state = {
    new_vendor_data: null,
    tax_classification: null,
    tax_tin_type: 'ssn',
    ignoreAutofill: true,
  }

  addVendor = () => {
    var data = {...this.state.new_vendor_data};
    data["client_id"] = this.props.userData?.active_client?.id;
    this.props.vendorsAddVendor(data)
  }

  updateVendor = () => {
    var data = {...this.state.new_vendor_data};
    data["client_id"] = this.props.userData?.active_client?.id;
    data["id"] = this.props.data?.id;
    this.props.vendorsUpdateVendor(data)
  }

  setVendorData = (key, value) => {
    const { new_vendor_data } = this.state;
    if(new_vendor_data){
      var new_vendor = {...new_vendor_data};
      new_vendor[key] = value;
      if(key === "address_country_data"){
        new_vendor["address_country"] = value.name;
      }
      if(key === "address_state_data"){
        new_vendor["address_state"] = value.name;
      }
      this.setState({new_vendor_data: new_vendor})
    } else {
      this.setState({new_vendor_data: {[key]: value}})
      if(key === "address_country_data"){
        this.setState({address_country: value.name});
      }
      if(key === "address_state_data"){
        this.setState({address_state: value.name});
      }
    }
  }

  searchVendorAutofill = (query, searchField) => {
    this.props.vendorsAutofillSearch({
      field: searchField,
      query: query,
    })
    this.setState({ ignoreAutofill: false });
  }

  autofillSelect = (data) => {
    this.setState({new_vendor_data: data})
  }

  render() {
    const {
      back,
      vendors_working,
    } = this.props;

    const {
      new_vendor_data,
    } = this.state;

    console.log("aaaaaaaaaaaaaaaaaa", this.state.new_vendor_data)
    console.log("aaaaaaaaaaaaaaaaaa", this.props.data)

    return (
      <>
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-xl font-semibold leading-none">Add New Vendor</h1>
          < Button className="btn-tertiary" onClick={back}>&larr; Back</Button>
        </div>
        <TabGroup className="mb-8">
          <TabList className="mb-5">
            <Tab>Vendor Info</Tab>
            <Tab>W-9 Info</Tab>
          </TabList>
          <TabPanels>
            {/* Vendor Information */}
            <TabPanel>
              <div className="flex gap-4">
                <div className="basis-4/6">
                  <div className="bg-slate-100/75 rounded-lg p-4">
                    <h2 className="font-semibold mb-4">General</h2>
                    <Field className="field">
                      <Label>Vendor Name*</Label>
                      <div className="relative">
                        <Input
                          className="w-full"
                          type="text"
                          required
                          defaultValue={this.props?.data?.name}
                          value={new_vendor_data?.name}
                          onChange={(e) => {
                            this.setVendorData("name", e.target.value);
                            callDebounce(this.searchVendorAutofill, e.target.value, "name")
                          }}
                        />
                        <SearchVendorDuplicates
                          searchField="name"
                          onSelect={this.autofillSelect}
                          ignore={this.state.ignoreAutofill}
                          ignoreFindings={() => this.setState({ignoreAutofill: true})}
                        />
                      </div>
                    </Field>
                    <Field className="field mt-4">
                      <Label>Vendor Account Number</Label>
                      <Input
                        type="text"
                        defaultValue={this.props?.data?.account_number}
                        value={new_vendor_data?.account_number}
                        onChange={(e) => this.setVendorData("account_number", e.target.value)}
                      />
                    </Field>
                  </div>
                  <div className="bg-slate-100/75 rounded-lg mt-5 p-4">
                    <h2 className="font-semibold mb-4">Address</h2>
                    <Field className="field">
                      <Label>Address Line 1*</Label>
                      <div className="relative">
                        <Input
                          type="text"
                          required
                          defaultValue={this.props?.data?.address_line_1}
                          value={new_vendor_data?.address_line_1}
                          onChange={(e) => {
                            this.setVendorData("address_line_1", e.target.value)
                            callDebounce(this.searchVendorAutofill, e.target.value, "address_line_1")
                          }}
                          className="w-full"
                        />
                        <SearchVendorDuplicates
                          searchField="address_line_1"
                          onSelect={this.autofillSelect}
                          ignore={this.state.ignoreAutofill}
                          ignoreFindings={() => this.setState({ignoreAutofill: true})}
                        />
                      </div>
                    </Field>
                    <Field className="field mt-4">
                      <Label>Address Line 2</Label>
                      <Input
                        type="text"
                        defaultValue={this.props?.data?.address_line_2}
                        value={new_vendor_data?.address_line_2}
                        onChange={(e) => this.setVendorData("address_line_2", e.target.value)}
                      />
                    </Field>
                    <div className="flex gap-4 mt-4">
                      <Field className="field flex-1">
                        {/* Assumed all countries, not sure if we only deal with specific countries */}
                        <Label>Country*</Label>
                        <CountrySelect
                          placeHolder="Select country"
                          showFlag={false}
                          defaultValue={new_vendor_data?.address_country_data
                            ? new_vendor_data?.address_country_data
                            : this.props.data?.address_country_data
                              ? this.props.data?.address_country_data
                              : null
                          }
                          onChange={e => this.setVendorData("address_country_data", e)}
                        />
                      </Field>
                      <Field className="field flex-1">
                        {/* from dropdown, some countries have no state/province */}
                        <Label>State/Province</Label>
                        <StateSelect
                          placeHolder="Select state/province"
                          countryid={this.state.new_vendor_data?.address_country_data?.id}
                          defaultValue={new_vendor_data?.address_state_data
                            ? new_vendor_data?.address_state_data
                            : this.props.data?.address_state_data
                              ? this.props.data?.address_state_data
                              : null
                          }
                          disabled={!this.state.new_vendor_data?.address_country_data && this.props.mode === 'add-vendor'}
                          onChange={e => this.setVendorData("address_state_data", e)}
                        />
                      </Field>
                    </div>
                    <div className="flex gap-4 mt-4">
                      <Field className="field flex-1">
                        {/* from dropdown, some countries have no state/province, therefore city dropdown empty, for now we use text input field */}
                        <Label>City</Label>
                        {/* <CitySelect
                          placeHolder="Select city"
                          countryid={this.state.countryObj?.id}
                          stateid={this.state.stateObj?.id}
                          disabled={!this.state.stateObj}
                          onChange={e => this.setState({ cityObj: {id: e.id, name: e.name} })}
                        /> */}
                        <Input
                          type="text"
                          defaultValue={this.props?.data?.address_city}
                          value={new_vendor_data?.address_city}
                          onChange={(e) => this.setVendorData("address_city", e.target.value)}
                        />
                      </Field>
                      <Field className="field flex-1">
                        <Label>Zip Code*</Label>
                        <Input
                          type="text"
                          className="max-w-36"
                          defaultValue={this.props?.data?.address_zip
                            ? this.props?.data?.address_zip
                            : null
                          }
                          value={new_vendor_data?.address_zip
                            ? new_vendor_data?.address_zip
                            : null
                          }
                          onChange={(e) => this.setVendorData("address_zip", e.target.value)}
                        />
                      </Field>
                    </div>
                  </div>
                  <div className="bg-slate-100/75 rounded-lg mt-5 p-4">
                    <h2 className="font-semibold mb-4">Accounting</h2>
                    <div className="grid grid-cols-2 gap-4">
                      <Field className="field">
                        <Label>Account</Label>
                        <SelectGLAccount
                          menuPlacement="top"
                        />
                      </Field>
                      <Field className="field">
                        <Label>Property</Label>
                        <SelectProperty />
                      </Field>
                    </div>
                    <Field className="field mt-4">
                      <Label>Description</Label>
                      <Input type="text" />
                    </Field>
                    <Field className="field mt-4">
                      <Label>1099 Box</Label>
                      <Select1099Box
                        menuPlacement="top"
                      />
                    </Field>
                  </div>
                </div>
                <div className="basis-2/6">
                  <div className="bg-slate-100/75 rounded-lg mb-5 p-4">
                    <h2 className="font-semibold mb-4">Contact</h2>
                    <Field className="field">
                      <Label>Contact Person</Label>
                      <Input
                        type="text"
                        defaultValue={this.props?.data?.contact_person
                          ? this.props?.data?.contact_person
                          : null
                        }
                        value={new_vendor_data?.contact_person
                          ? new_vendor_data?.contact_person
                          : null
                        }
                        onChange={(e) => this.setVendorData("contact_person", e.target.value)}
                      />
                    </Field>
                    <Field className="field mt-4">
                      <Label>Phone</Label>
                      <PhoneInput
                        international
                        defaultCountry={this.props?.data?.address_country_data?.iso2
                          ? this.props?.data?.address_country_data?.iso2
                          : this.state.new_vendor_data?.address_country_data?.iso2
                        }
                        defaultValue={this.props.data?.contact_person_phone
                          ? this.props.data?.contact_person_phone
                          : null
                        }
                        value={new_vendor_data?.contact_person_phone
                          ? new_vendor_data?.contact_person_phone
                          : null
                        }
                        onChange={value => this.setVendorData("contact_person_phone", value)}
                      />
                    </Field>
                    <Field className="field mt-4">
                      <Label>Email</Label>
                      <Input
                        type="email"
                        defaultValue={this.props?.data?.contact_person_email
                          ? this.props?.data?.contact_person_email
                          : null
                        }
                        value={new_vendor_data?.contact_person_email
                          ? new_vendor_data?.contact_person_email
                          : null
                        }
                        onChange={(e) => this.setVendorData("contact_person_email", e.target.value)}
                      />
                    </Field>
                  </div>
                  <div className="bg-slate-100/75 rounded-lg mb-5 p-4">
                    <h2 className="font-semibold mb-4">Banking Information (ACH)</h2>
                    <Field className="field">
                      <Label>Account No.</Label>
                      <div className="relative">
                        <ReactInputMask
                          mask="999999999999999999999999999999"
                          maskChar=""
                          className="w-full"
                          defaultValue={this.props?.data?.bank_account_number
                            ? this.props?.data?.bank_account_number
                            : null
                          }
                          value={new_vendor_data?.bank_account_number
                            ? new_vendor_data?.bank_account_number
                            : null
                          }
                          onChange={(e) => this.setVendorData("bank_account_number", e.target.value)}
                        />
                        <SearchVendorDuplicates
                          searchField="bank_account_number"
                          ignore={this.state.ignoreAutofill}
                          ignoreFindings={() => this.setState({ignoreAutofill: true})}
                          onSelect={this.autofillSelect}
                        />
                      </div>
                      <small className="text-slate-400">Usually 5 - 17 digits</small>
                    </Field>
                    <Field className="field mt-4">
                      <Label>Routing No.</Label>
                      <ReactInputMask mask="999999999" maskChar="" />
                      <small className="text-slate-400">Routing number is 9 digits</small>
                    </Field>
                  </div>
                </div>
              </div>
            </TabPanel>

            {/* W-9 Information */}
            <TabPanel>
              <div className="flex gap-4">
                <div className="basis-3/6 bg-slate-100/75 rounded-lg">
                  <div className="p-4">
                    <Field className="field">
                      <Label>Legal Name</Label>
                      <div className="grid grid-cols-3 gap-4">
                        <Input type="text" placeholder="First Name" />
                        <Input type="text" placeholder="Middle Name" />
                        <Input type="text" placeholder="Last Name" />
                      </div>
                    </Field>
                    <Field className="field mt-4">
                      <Label>Business Name</Label>
                      <Input type="text" />
                    </Field>
                    <Field className="field mt-4">
                      <Label>Federal Tax Classification</Label>
                      <Select
                        options={tax_classification}
                        value={this.state.tax_classification}
                        onChange={selected => this.setState({ tax_classification: selected })}
                      />
                    </Field>
                    {(this.state.tax_classification?.value !== 'llc' || this.state.tax_classification?.value !== 'other') &&
                    <Field className="field mt-4">
                      <Label>Exemptions</Label>
                      <div className="flex gap-4">
                        <div className="flex-1">
                          <Input type="text" className="w-full" />
                          <small className="text-slate-500 block">Exempt payee code (if any)</small>
                        </div>
                        <div className="flex-1">
                          <Input type="text" className="w-full" />
                          <small className="text-slate-500 block">Exemption from FATCA reporting code (if any)</small>
                        </div>
                      </div>
                    </Field>
                    }
                    {this.state.tax_classification?.value === 'llc' &&
                    <Field className="field mt-4">
                      <Label>LLC Tax Classification</Label>
                      <small className="text-slate-400">Enter the tax classification for Limited Liability Company</small>
                      <Select
                        options={llc_tax_classification}
                      />
                    </Field>
                    }
                    {this.state.tax_classification?.value === 'other' &&
                    <Field className="field mt-4">
                      <Input type="text" placeholder='Specify "Other" here' />
                    </Field>
                    }
                  </div>
                </div>
                <div className="basis-3/6">
                  <div className="bg-slate-100/75 rounded-lg p-4">
                    <h2 className="font-semibold mb-4">Taxpayer Identification Number (TIN)</h2>
                    <RadioGroup value={this.state.tax_tin_type} onChange={(selected) => this.setState({ tax_tin_type: selected })}>
                      <Field className="flex items-center gap-2">
                        <Radio className="radio" value="ssn" />
                        <Label>Use Social Security Number (SSN)</Label>
                      </Field>
                      <Field className="flex items-center gap-2 mt-4">
                        <Radio className="radio" value="ein" />
                        <Label>Use Employer Identification Number (EIN)</Label>
                      </Field>
                    </RadioGroup>
                    {this.state.tax_tin_type === 'ssn' ?
                    <Field className="field mt-4">
                      <ReactInputMask mask="999-99-9999" alwaysShowMask/>
                    </Field>
                    :
                    <Field className="field mt-4">
                      <ReactInputMask mask="99-9999999" alwaysShowMask/>
                    </Field>
                    }
                  </div>
                  <div className="bg-slate-100/75 rounded-lg p-4 mt-4">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="font-semibold">Upload W-9 Form</h2>
                      <div className="flex items-center gap-1">
                        <IconDownload className="inline-block text-slate-500" />
                        <span className="underline hover:no-underline text-blue-700 text-sm" role="button">Download W-9 Form</span>
                      </div>
                    </div>
                    <FileUpload />
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
        {this.props.data
          ?
            <Button
              className="btn-primary"
              disabled={vendors_working && !this.state.new_vendor_data}
              onClick={this.updateVendor}
            >
              <span className="flex gap-1 justify-center items-center">
                {vendors_working && <Spinner />}
                <span>Update Vendor</span>
              </span>
            </Button>
          :
            <Button
              className="btn-primary"
              disabled={vendors_working && !this.state.new_vendor_data}
              onClick={this.addVendor}
            >
              <span className="flex gap-1 justify-center items-center">
                {vendors_working && <Spinner />}
                <span>Add Vendor</span>
              </span>
            </Button>
        }
      </>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      vendors_working: state.Vendors.get("vendors_working"),
      vendors_autofill: state.Vendors.get("vendors_autofill"),
      vendors_autofill_working: state.Vendors.get("vendors_autofill_working"),
    }),
    {
      vendorsAddVendor,
      vendorsUpdateVendor,
      vendorsAutofillSearch,
    }
  )(VendorForm)
);
