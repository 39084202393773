const reportsActions = {
	REPORTS_REPORT_GET: 'REPORTS_REPORT_GET',

  reportsReportGet: (data) => ({
    type: reportsActions.REPORTS_REPORT_GET,
    data
  }),
}

export default reportsActions;
