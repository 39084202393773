import { Map } from "immutable";
import { getTokenLocal } from "../../helpers/auth/utils";
import actions from "./actions";

const initState = new Map({
  access_token: null,
  refresh_token: null,
  token_valid: false,
  loginError: false,
	login_failed: false,
	login_failed_message: "",
	login_working: false,
  registration_working: false,
  registration_data: null,
  reset_password_working: false,
  code_sent_success: false,
});

export default function authReducer(
	state = initState.merge(getTokenLocal()),	action) {
	switch (action.type) {

		case "USER_CLEAR_CODE_SENT":
			return state
        .set("code_sent_success", false)

		case "USER_2FA_SEND_CODE":
			return state
				.set("login_working", true)
        .set("code_sent_success", false)
		case "USER_2FA_SEND_CODE_SUCCESS":
			return state
				.set("login_working", false)
        .set("code_sent_success", true)
		case "USER_2FA_SEND_CODE_FAILED":
			return state
				.set("login_working", false)
        .set("code_sent_success", false)

		case actions.USER_PASSWORD_FORGOT_RESET:
			return state
				.set("reset_password_working", true)
		case actions.USER_PASSWORD_FORGOT_RESET_SUCCESS:
			return state
				.set("reset_password_working", false)
		case actions.USER_PASSWORD_FORGOT_RESET_ERROR:
			return state
				.set("reset_password_working", false)

		case actions.USER_PASSWORD_FORGOT:
			return state
				.set("reset_password_working", true)
		case actions.USER_PASSWORD_FORGOT_SUCCESS:
			return state
				.set("reset_password_working", false)
		case actions.USER_PASSWORD_FORGOT_ERROR:
			return state
				.set("reset_password_working", false)

		case actions.ADMIN_LOGIN:
			return state
				.set("access_token", null)
				.set("refresh_token", null)
				.set("loginError", false)
				.set("login_failed", false)
				.set("login_failed_message", "")
				.set("login_working", true)
		case actions.ADMIN_LOGIN_SUCCESS:
			return state
				.set("access_token", action.data.access_token)
				.set("refresh_token", action.data.refresh_token)
				.set("loginError", false)
				.set("login_failed", false)
				.set("login_failed_message", "")
				.set("login_working", false)
		case actions.ADMIN_LOGIN_ERROR:
			return state
				.set("access_token", null)
				.set("refresh_token", null)
				.set("loginError", true)
				.set("login_failed", true)
				.set("login_failed_message", action.data.error)
				.set("login_working", false)

		case "USER_REGISTER":
			return state
				.set("registration_data", null)
				.set("registration_working", true)
		case "USER_REGISTER_SUCCESS":
			return state
				.set("registration_working", false)
				.set("registration_data", {...action.data})
		case "USER_REGISTER_FAILED":
			return state
				.set("registration_working", false)
				.set("registration_data", {...action.data})

		case actions.USER_LOGIN:
			return state
				.set("access_token", null)
				.set("refresh_token", null)
				.set("loginError", false)
				.set("login_failed", false)
				.set("login_failed_message", "")
				.set("login_working", true)
		case actions.USER_LOGIN_SUCCESS:
			return state
				.set("access_token", action.data.access)
				.set("refresh_token", action.data.refresh)
				.set("loginError", false)
				.set("login_failed", false)
				.set("login_failed_message", "")
				.set("login_working", false)
		case actions.USER_LOGIN_ERROR:
			return state
				.set("access_token", null)
				.set("refresh_token", null)
				.set("loginError", true)
				.set("login_failed", true)
				.set("login_failed_message", action.data.error)
				.set("login_working", false)

		case actions.USER_LOGIN_2FA:
			return state
				.set("access_token", null)
				.set("refresh_token", null)
				.set("loginError", false)
				.set("login_failed", false)
				.set("login_failed_message", "")
				.set("login_working", true)
		case actions.USER_LOGIN_2FA_SUCCESS:
			return state
				.set("access_token", action.data.access)
				.set("refresh_token", action.data.refresh)
				.set("loginError", false)
				.set("login_failed", false)
				.set("login_failed_message", "")
				.set("login_working", false)
		case actions.USER_LOGIN_2FA_ERROR:
			return state
				.set("access_token", null)
				.set("refresh_token", null)
				.set("loginError", true)
				.set("login_failed", true)
				.set("login_failed_message", action.data.error)
				.set("login_working", false)

		case actions.USER_LOGOUT:
			return initState;

		case actions.USER_REFRESH_TOKEN_SUCCESS:
			return state
				.set("access_token", action.data.access)
        .set("token_valid", true)
    case actions.REFRESH_TOKEN_ERROR:
      return state.set("token_valid", false)

		case actions.USER_REFRESH_TOKEN_LOCAL:
			const token = getTokenLocal();
			return state
				.set("access_token", token.access_token)
				.set("refresh_token", token.refresh_token)
        .set("token_valid", true)

		default:
			return state;
  }
}
