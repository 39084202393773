import React, { Component } from "react"
import { connect } from "react-redux";
import Select from 'react-select'
import accountingActions from "../redux/accounting/actions";

const {
  accountingGL1099All,
} = accountingActions;

class Select1099Box extends Component {

  componentDidMount = () => {
    if(this.props.forms_1099?.length === 0){
      this.props.accountingGL1099All();
    }
  }

  processOptions = () => {
    if(this.props.forms_1099?.length > 0){
      return this.props.forms_1099?.map(item => {
        item.value = item.id;
        item.label = item.title;
        return item;
      })
    }
    return [];
  }

  render() {
    const { menuPlacement } = this.props
    return (
      <Select
        options={this.processOptions()}
        menuPlacement={menuPlacement ? menuPlacement : 'bottom'}
        diasbled={this.props.forms_1099_working}
        {...this.props}
      />
    )
  }
}

export default connect(
  state => ({
    forms_1099: state.Accounting.get("forms_1099"),
    forms_1099_working: state.Accounting.get("forms_1099_working"),
  }),
  {
    accountingGL1099All,
  }
)(Select1099Box)
