const clientsActions = {
	CLIENTS_GET_FOR_USER: 'CLIENTS_GET_FOR_USER',
	CLIENT_CREATE: 'CLIENT_CREATE',
	CLIENT_UPDATE: 'CLIENT_UPDATE',
	CLIENT_GROUP_ADD: 'CLIENT_GROUP_ADD',
	CLIENT_GROUPS_GET: 'CLIENT_GROUPS_GET',
	CLIENT_GROUPS_DELETE: 'CLIENT_GROUPS_DELETE',

	CLIENT_DASHBOARD_CLIENTS_ACTIVITY: 'CLIENT_DASHBOARD_CLIENTS_ACTIVITY',
	CLIENT_DASHBOARD_CLIENTS_TASKS: 'CLIENT_DASHBOARD_CLIENTS_TASKS',

  clientDashboardClientsTasks: (data) => ({
    type: clientsActions.CLIENT_DASHBOARD_CLIENTS_TASKS,
    data
  }),

  clientDashboardClientsActivity: (data) => ({
    type: clientsActions.CLIENT_DASHBOARD_CLIENTS_ACTIVITY,
    data
  }),

  clientGroupDelete: (data) => ({
    type: clientsActions.CLIENT_GROUPS_DELETE,
    data
  }),

  clientGroupsGet: (data) => ({
    type: clientsActions.CLIENT_GROUPS_GET,
    data
  }),

  clientGroupAdd: (data) => ({
    type: clientsActions.CLIENT_GROUP_ADD,
    data
  }),

  clientUpdate: (data) => ({
    type: clientsActions.CLIENT_UPDATE,
    data
  }),

  clientCreate: (data) => ({
    type: clientsActions.CLIENT_CREATE,
    data
  }),

  clientsGetForUser: (data) => ({
    type: clientsActions.CLIENTS_GET_FOR_USER,
    data
  }),
}

export default clientsActions;
