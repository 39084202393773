const vendorsActions = {
	VENDORS_GET_ALL_FOR_CLIENT: 'VENDORS_GET_ALL_FOR_CLIENT',
	VENDORS_ADD_VENDOR: 'VENDORS_ADD_VENDOR',
	VENDORS_UPDATE_VENDOR: 'VENDORS_UPDATE_VENDOR',
	VENDORS_AUTOFILL_SEARCH: 'VENDORS_AUTOFILL_SEARCH',

  vendorsAutofillSearch: (data) => ({
    type: vendorsActions.VENDORS_AUTOFILL_SEARCH,
    data
  }),

  vendorsUpdateVendor: (data) => ({
    type: vendorsActions.VENDORS_UPDATE_VENDOR,
    data
  }),

  vendorsAddVendor: (data) => ({
    type: vendorsActions.VENDORS_ADD_VENDOR,
    data
  }),

  vendorsGetAllForClient: (data) => ({
    type: vendorsActions.VENDORS_GET_ALL_FOR_CLIENT,
    data
  }),
}

export default vendorsActions;
