export const appConfig = {
	environments: {
		"production": "production",
		"development": "development",
	},
	backendApiUrl: {
    "production": "https://back.eventledger.co",
		"development": "http://127.0.0.1:8000",
	},
	auth: {
		refreshTokenFrequency: 60000*10,
  }
}
