import { Map } from "immutable";

const initState = new Map({
  report: null,
  report_working: false,
});

export default function reportsReducer(state = initState, action) {
  switch (action.type) {

    case "REPORTS_REPORT_GET":
      return state
        .set("report", null)
        .set("report_working", true)
    case "REPORTS_REPORT_GET_SUCCESS":
      return state
        .set("report", {...action.data.data})
        .set("report_working", false)
    case "REPORTS_REPORT_GET_FAILED":
      return state
        .set("report", null)
        .set("report_working", false)

		default:
			return state;
  }
}
