const invoicesActions = {
	INVOICES_GET_ALL_FOR_CLIENT: 'INVOICES_GET_ALL_FOR_CLIENT',
	INVOICES_ADD_INVOICE: 'INVOICES_ADD_INVOICE',
	INVOICES_GET_INVOICE: 'INVOICES_GET_INVOICE',
	INVOICES_UPDATE_INVOICE: 'INVOICES_UPDATE_INVOICE',
	INVOICES_UPDATE_INVOICE_STATUS: 'INVOICES_UPDATE_INVOICE_STATUS',
	INVOICES_RECURRING_GET_ALL: 'INVOICES_RECURRING_GET_ALL',
	INVOICES_RECURRING_UPDATE: 'INVOICES_RECURRING_UPDATE',

  invoicesRecurringUpdate: (data) => ({
    type: invoicesActions.INVOICES_RECURRING_UPDATE,
    data
  }),

  invoicesRecurringGetAll: (data) => ({
    type: invoicesActions.INVOICES_RECURRING_GET_ALL,
    data
  }),

  invoicesUpdateInvoiceStatus: (data) => ({
    type: invoicesActions.INVOICES_UPDATE_INVOICE_STATUS,
    data
  }),

  invoicesUpdateInvoice: (data) => ({
    type: invoicesActions.INVOICES_UPDATE_INVOICE,
    data
  }),

  invoicesGetInvoice: (data) => ({
    type: invoicesActions.INVOICES_GET_INVOICE,
    data
  }),

  invoicesAddInvoice: (data) => ({
    type: invoicesActions.INVOICES_ADD_INVOICE,
    data
  }),

  invoicesGetAllForClient: (data) => ({
    type: invoicesActions.INVOICES_GET_ALL_FOR_CLIENT,
    data
  }),
}

export default invoicesActions;
