import React, { Component } from 'react'
import { digitСonversion, momentDateToISOFormatConversion } from "../../helpers/utils"

class GeneralLedgerReport extends Component {
  formatOutput = (value, options) => {
    let output = value
    if(options.onlyShowNonZero) {
      output = value > 0 ? this.moneyFormat(value) : ''
    } else {
      output = this.moneyFormat(value)
    }
    return output
  }

  moneyFormat = (value) => {
    return value < 0
    ? `(${digitСonversion(Math.abs(value), "currency")})`
    : digitСonversion(value, "currency")
  }

  render() {
    const {
      userData,
      report,
    } = this.props;

    const format_options = {
      onlyShowNonZero: true,
      // can be expanded later
    }

    return (
      <div>
        <div className="border rounded px-4 py-8">
          <div className="text-center mb-6">
            <h3 className="text-2xl mb-2">{userData?.active_client?.name}</h3>
            <div>
              <h4 className="font-semibold">General Ledger Report</h4>
              <p>Period: {momentDateToISOFormatConversion(report?.start_date, 'MM/DD/YYYY')} - {momentDateToISOFormatConversion(report?.end_date, 'MM/DD/YYYY')}</p>
            </div>
          </div>

          <table className="table-auto w-full">
            <thead className="border-b-2 border-slate-200">
              <tr>
                <th className="p-2">Check No</th>
                <th className="p-2">Date</th>
                <th className="p-2">Description</th>
                <th className="p-2 text-right">Debit Amount</th>
                <th className="p-2 text-right">Credit Amount</th>
              </tr>
            </thead>
            <tbody>
              {report?.data?.accounts.map(account => (
              <>
              <tr className="border-b-2 border-slate-200">
                <td colSpan={6} className="font-semibold p-2">{account.account_number} {account.account_description}</td>
              </tr>
              <tr className="border-b-2 border-slate-200 text-blue-700">
                <td className="p-1">Beginning Balance</td>
                <td colSpan={3} className="text-right font-medium">{this.formatOutput(account.beginning_balance, { onlyShowNonZero: false })}</td>
              </tr>
              {account?.transactions.map((t, idx) => (
              <tr key={`transaction-${t.check_no}-${idx}`} className="border-b-2 border-slate-200">
                <td className="p-1">{t.check_no}</td>
                <td>{momentDateToISOFormatConversion(t.date, 'MM/DD/YYYY')}</td>
                <td>{t.description}</td>
                <td className="text-right">{this.formatOutput(t.debit, format_options)}</td>
                <td className="text-right">{this.formatOutput(t.credit, format_options)}</td>
              </tr>
              ))}
              <tr className="font-medium text-blue-700 border-b-2 border-slate-200">
                <td className="p-1">Total</td>
                <td colSpan={3} className="text-right">{this.formatOutput(account.total.debit, format_options)}</td>
                <td colSpan={4} className="text-right">{this.formatOutput(account.total.credit, format_options)}</td>
              </tr>
              <tr className="font-medium text-blue-700 border-b-2 border-slate-200">
                <td className="p-1">Closing Balance</td>
                <td colSpan={3} className="text-right">{this.formatOutput(account.closing_balance.debit, format_options)}</td>
                <td colSpan={4} className="text-right">{this.formatOutput(account.closing_balance.credit, format_options)}</td>
              </tr>
              <tr className="font-medium text-blue-700">
                <td className="p-1">Closing Balance for {account.account_number} {account.account_description}</td>
                <td colSpan={3} className="text-right">{this.formatOutput(account.closing_balance_final.debit, format_options)}</td>
                <td colSpan={4} className="text-right">{this.formatOutput(account.closing_balance_final.credit, format_options)}</td>
              </tr>
              <tr>
                <td colSpan={7}>&nbsp;</td>
              </tr>
              </>
              ))}
            </tbody>
          </table>
          <div>
            <div className="font-semibold py-1 border-b-2">Total Ledger Activity</div>
            <div className="flex justify-between py-1 border-b-2">
              <span>Debit Activity</span>
              <span className="text-right">{this.moneyFormat(report?.data?.total_debit)}</span>
            </div>
            <div className="flex justify-between py-1 border-b-2">
              <span>Credit Activity</span>
              <span className="text-right">{this.moneyFormat(report?.data?.total_credit)}</span>
            </div>
            <div className="flex justify-between py-1 font-semibold">
              <span>Total Activity</span>
              <span className="text-right">{this.moneyFormat(report?.data?.total_activity)}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GeneralLedgerReport
