export default function Spinner({ fillColorClass }) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 20 20" height="20" width="20" className="animate-spin">
      <g fill="none" fillRule="evenodd">
        <path d="M19 0v19H0V0h19ZM9.97 18.412l-.01.002-.056.028-.015.003-.012-.003-.056-.028c-.008-.003-.015 0-.018.004l-.004.009-.013.338.004.016.008.01.082.058.012.003.01-.003.081-.058.01-.013.003-.013-.013-.338c-.002-.008-.007-.013-.013-.015Zm.21-.089-.011.002-.147.073-.008.008-.002.009.015.34.003.01.007.006.16.073c.009.003.017 0 .022-.006l.003-.011-.027-.487c-.002-.01-.008-.015-.015-.017Zm-.567.002a.018.018 0 0 0-.021.004l-.005.011-.027.487c0 .009.006.016.013.018h.013l.159-.074.007-.007.003-.009.014-.34-.002-.01-.008-.007-.146-.073Z"/>
        <path className={fillColorClass ? fillColorClass : "fill-white"} fill="#000" d="M9.5 3.563a5.937 5.937 0 1 0 0 11.874 5.937 5.937 0 0 0 0-11.874ZM1.187 9.5a8.312 8.312 0 1 1 16.625 0 8.312 8.312 0 0 1-16.625 0Z" opacity=".5"/>
        <path className={fillColorClass ? fillColorClass : "fill-white"} fill="#000" d="M9.5 3.563a5.904 5.904 0 0 0-4.107 1.649 1.188 1.188 0 0 1-1.642-1.715 8.28 8.28 0 0 1 5.75-2.31 1.188 1.188 0 0 1 0 2.375Z"/>
      </g>
    </svg>
  )
}