import { Button, Field, Input, Label, Checkbox } from "@headlessui/react"
import { connect } from "react-redux";
import { withRouter } from "react-router";
import React, { Component } from 'react'
import Spinner from "../../../components/Spinner"
import Select from 'react-select'
import { ReactComponent as IconClient } from '../../../assets/icons/icon-client.svg'
import clientsActions from "../../../redux/clients/actions";
import companiesActions from "../../../redux/companies/actions";
import { Link } from "react-router-dom";

const {
  companiesGetUsers,
} = companiesActions;

const {
  clientsGetForUser,
  clientUpdate,
  clientGroupsGet,
} = clientsActions;


class ClientSettings extends Component {
  state = {
    mode: 'list-client',
    data_working: false,
    selectedUsers: null,
    selected_client: null,
    client_name: null,
    client_group: undefined,
  }

  saveData = () => {
    const {
      selected_client,
      client_name,
      selectedUsers,
      client_group,
    } = this.state;

    if(!selected_client){ return }
    var data = {id: selected_client.id}
    if(client_name){data["name"] = client_name;}
    if(selectedUsers && selectedUsers !== selected_client?.assigned_users?.map(item => {return {value: item.id, label: item.full_name}})){
      data["assigned"] = selectedUsers.map(item => {
        return {id: item.value, user: item.label}})
    }
    if(client_group?.value !== selected_client?.client_group?.id){
      data["client_group_id"] = client_group?.value ? client_group?.value : null;
    }
    if(client_name
      || selectedUsers
      || client_group?.value !== selected_client?.client_group?.id
    ){this.props.clientUpdate(data)}
  }

  componentDidMount = () => {
    this.props.clientsGetForUser({assigned: true});
    this.props.companiesGetUsers();
    this.props.clientGroupsGet();
  }

  componentDidUpdate = (pp) => {
    const { user_clients } = this.props;
    const { selected_client } = this.state;
    if(!selected_client || !user_clients){ return; }
    if(pp.user_clients !== user_clients){
      const group = user_clients.find(item => item.id === selected_client?.client_group?.id);
      const client = group?.clients?.find(item => item.id === selected_client?.id);
      this.setState({
        selected_client: {
          ...selected_client,
          ...{
            "accounting_type": client?.accounting_type,
            "accounting_type_human": client?.accounting_type_human,
          }
        }
      })
    }
  }

  clientGroupOptions = () => {
    return this.props.user_client_groups?.map(item => {
      return {value: item.id, label: item.name}
    })
  }

  render() {
    const {
      user_clients,
      //user_switch_client_working,
      //userData,
      companies_users,
      user_clients_update_working,
    } = this.props;

    const {
      selected_client,
      selectedUsers,
      client_group,
    } = this.state;

    const client_group_options = this.clientGroupOptions();

    if(this.state.mode === 'list-client') {
      return (
        <>
          <div className="flex justify-between items-center">
            <h3 className="font-semibold text-lg">All Clients</h3>
            <Link to="/app/bm/add-client" className="btn-link btn-primary">Add Client</Link>
          </div>
          {this.state.mode === 'list-client'}
          {user_clients.map(item => (
          <div key={`group-${item.id}`} className="mt-8">
            <h2 className="uppercase font-semibold text-lg bg-slate-800 text-white px-4 py-1 rounded inline-block mb-2">{item.name}</h2>
            <table className="w-full text-[15px] table-fixed">
              <thead>
                <tr>
                  <th className="text-left border-b-2 border-slate-200">Client Name</th>
                  <th className="border-b-2 border-slate-200">Assigned Users</th>
                  <th className="border-b-2 border-slate-200">Actions</th>
                </tr>
              </thead>
              <tbody>
                {item.clients && item.clients.map(c => (
                <tr key={`c-${c.id}`}>
                  <td className="border-t py-4 border-slate-200">
                    <div className="flex items-center gap-2">
                      <IconClient className="size-6 text-slate-500" />
                      <span className="text-lg font-semibold">{c.name}</span>
                    </div>
                  </td>
                  <td className="border-t py-4 border-slate-200">
                  {c?.assigned_users?.length > 0 ?
                    c?.assigned_users.map(u => (
                      <p key={`user-${c.id}-${u.id}`}>{u.username} <span className="text-slate-500">({u.full_name})</span></p>
                    ))
                  :
                  <p className="text-slate-500">Currently no users assigned to this client.</p>
                  }
                  </td>
                  <td className="border-t py-4 border-slate-200">
                    <Button
                      className="btn-secondary btn-sm"
                      onClick={() => this.setState({
                        mode: 'edit-client',
                        selected_client: c,
                        selectedUsers: null,
                        client_name: null,
                        client_group: undefined,
                      })}
                    >Change Settings</Button>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
          ))}
        </>
      )
    } else if (this.state.mode === 'edit-client') {
      return (
        <>
          <div className="flex justify-between items-center mb-5">
            <div className="flex flex-col gap-1">
              <p className="text-slate-500 font-semibold">Change Settings</p>
              <h1 className="text-xl font-semibold leading-none flex gap-2 items-center">
                <IconClient className="size-6 text-slate-500" />
                <span>{selected_client?.name}</span>
              </h1>
            </div>
            <Button
              className="btn-tertiary"
              onClick={() => {
                this.setState({
                  mode: 'list-client',
                  selected_client: null,
                  selectedUsers: null,
                  client_name: null,
                  client_group: undefined,
                });
                this.props.clientsGetForUser({assigned: true});
              }}
            >&larr; Back</Button>
          </div>
          <div className="flex gap-4">
            <div className="py-3 px-4 mt-3 rounded-lg bg-slate-100/75 flex-1">
              <h2 className="font-semibold mb-5">Client Information</h2>
              <Field className="field">
                <Label>Client Group</Label>
                <Select
                  placeholder="Select client group..."
                  options={client_group_options}
                  value={client_group !== undefined
                    ? client_group
                    : selected_client?.client_group?.id
                      ? client_group_options.filter(item => item.value === selected_client?.client_group?.id)
                      : null
                  }
                  onChange={(e) => this.setState({client_group: e})}
                  isClearable={true}
                />
              </Field>
              <Field className="field mt-4">
                <Label>Client Name</Label>
                <Input
                  type="text"
                  defaultValue={selected_client?.name}
                  onChange={(e) => this.setState({client_name: e.target.value})}
                />
              </Field>
              <Button
                className="btn-primary mt-4"
                onClick={this.saveData}
                disabled={user_clients_update_working
                  || (!this.state.client_name && !selectedUsers && client_group?.value === selected_client?.client_group?.id)}
              >
                <span className="flex gap-1 justify-center items-center">
                  {this.state.data_working && <Spinner />}
                  <span>Save changes</span>
                </span>
              </Button>
            </div>
            <div className="py-3 px-4 mt-3 rounded-lg bg-slate-100/75 flex-1">
              <h2 className="font-semibold mb-5">User Assignment</h2>
              <Field className="field">
                <Label>Select user(s)</Label>
                <Select
                  placeholder="Select one or more users"
                  options={companies_users?.map(item => {return {value: item.id, label: item.full_name}})}
                  value={
                      selectedUsers
                        ? selectedUsers
                        : selected_client?.assigned_users
                          ? selected_client?.assigned_users?.map(item => {
                              return {value: item.id, label: item.full_name}
                            })
                          : []
                  }
                  onChange={selected => this.setState({ selectedUsers: selected })}
                  isMulti
                  isDisabled={this.state.user_assigned}
                />
              </Field>
            </div>
          </div>
      <div className="flex gap-4 mt-4">
        <div className="py-3 px-4 mt-3 rounded-lg bg-slate-100/75 basis-1/2">
          <h2 className="font-semibold mb-3">Security</h2>
          <Field className="flex items-center gap-2">
            <Checkbox
              className="checkbox"
              checked={selected_client.accounting_type_human === "CASH" ? true : false}
              disabled={user_clients_update_working}
              onChange={() => this.props.clientUpdate({
                id: selected_client.id,
                accounting_type: "CASH",
              })}
            />
            <Label>Cash</Label>
          </Field>
          <Field className="flex items-center gap-2">
            <Checkbox
              className="checkbox"
              checked={selected_client.accounting_type_human === "ACCRUAL" ? true : false}
              disabled={user_clients_update_working}
              onChange={() => this.props.clientUpdate({
                id: selected_client.id,
                accounting_type: "ACCRUAL",
              })}
            />
            <Label>Accrual</Label>
          </Field>
        </div>
      </div>
        </>
      )
    }
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      user_clients: state.Clients.get("user_clients"),
      user_clients_working: state.Clients.get("user_clients_working"),
      user_clients_update_working: state.Clients.get("user_clients_update_working"),
      companies_users: state.Companies.get("users"),
      user_client_groups: state.Clients.get("user_client_groups"),
    }),
    {
      clientsGetForUser,
      companiesGetUsers,
      clientUpdate,
      clientGroupsGet,
    }
  )(ClientSettings)
);
