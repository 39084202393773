import { Button, Checkbox, Field, Input, Label } from "@headlessui/react"
import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Spinner from "../../components/Spinner"
import userActions from "../../redux/user/actions";
import { checkPasswordComplexity, checkFormValidForUpdate } from "../../helpers/utils"

const {
  userPasswordReset,
  userUpdateData,
  userUpdateSettings,
} = userActions;

class Account extends Component {
  state = {
    show_pw: false,
    old_pw: null,
    new_pw: null,
    confirm_new_pw: null,
    first_name: null,
    last_name: null,
    input_new_pw_pristine: true
  }

  validatePWForm = () => {
    const { old_pw, new_pw, confirm_new_pw } = this.state;
    //if(!old_pw || !new_pw || !confirm_new_pw) return "Invlaid input";
    if(!old_pw) return false;
    if(new_pw !== confirm_new_pw) return "Passwords need to match";
    return checkPasswordComplexity(new_pw);
  }

  changePassword = () => {
    const { old_pw, new_pw, confirm_new_pw } = this.state;
    this.props.userPasswordReset({
      old_pw: old_pw,
      new_pw: new_pw,
      confirm_new_pw: confirm_new_pw,
    })
  }

  userUpdateData = (formUpdatedValues) => {
    this.props.userUpdateData(formUpdatedValues)
    this.setState({first_name: null, last_name:null})
  }

  render() {
    const { userData, user_data_saving } = this.props;
    const formValidError = this.validatePWForm();
    const formUpdatedValues = checkFormValidForUpdate(userData, ["first_name", "last_name"], this.state);

    return (
      <>
      <div className="flex gap-4">
        <div className="py-3 px-4 mt-3 rounded-lg bg-slate-100/75 flex-1">
          <div className="mb-3 flex justify-between items-center">
            <h2 className="font-semibold">Account Information</h2>
            <span
              className="px-2 py-1 bg-slate-500 rounded-full text-sm text-slate-50 leading-none"
            >
              {userData?.user_type === "super_user"
                ? "Business Manager"
                : "Employee"
              }
            </span>
          </div>
          <Field className="field">
            <Label>Username</Label>
            <Input
              type="text"
              defaultValue={userData?.username}
              disabled
            />
          </Field>
          <div className="flex gap-4 mt-4">
            <Field className="field">
              <Label>First Name</Label>
              <Input
                type="text"
                defaultValue={userData?.first_name}
                onChange={(e) => this.setState({first_name: e.target.value})}
              />
            </Field>
            <Field className="field">
               <Label>Last Name</Label>
               <Input
                type="text"
                defaultValue={userData?.last_name}
                onChange={(e) => this.setState({last_name: e.target.value})}
              />
            </Field>
          </div>
          <Field className="field mt-4">
            <Label>Email</Label>
            <Input
              type="email"
              defaultValue={userData?.email}
              disabled
            />
          </Field>
          <Button
            className="btn-primary mt-4"
            disabled={user_data_saving || !formUpdatedValues}
            onClick={() => this.userUpdateData(formUpdatedValues)}
          >
            <span className="flex gap-1 justify-center items-center">
              {user_data_saving && <Spinner />}
              <span>Save Changes</span>
            </span>
          </Button>
        </div>
        <div className="py-3 px-4 mt-3 rounded-lg bg-slate-100/75 flex-1">
          <h2 className="font-semibold mb-3">Change Password</h2>
          <Field className="field">
            <Label>Current Password</Label>
            <div className="relative">
              <Input
                type={this.state.show_pw ? 'text' : 'password'}
                className="w-full"
                onChange={(e) => this.setState({old_pw: e.target.value})}
              />
              <small
                className="absolute top-3 right-2 cursor-pointer text-slate-500"
                onClick={() => this.setState({ show_pw: !this.state.show_pw })}
              >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
            </div>
          </Field>
          <Field className="field mt-4">
            <Label>New Password</Label>
            <div className="relative">
              <Input
                type={this.state.show_pw ? 'text' : 'password'}
                className="w-full"
                onChange={(e) => this.setState({
                  new_pw: e.target.value,
                  input_new_pw_pristine: false
                })}
              />
              <small
                className="absolute top-3 right-2 cursor-pointer text-slate-500"
                onClick={() => this.setState({ show_pw: !this.state.show_pw })}
              >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
            </div>
          </Field>
          <Field className="field mt-4">
            <Label>Confirm New Password</Label>
            <div className="relative">
              <Input
                type={this.state.show_pw ? 'text' : 'password'}
                className="w-full"
                onChange={(e) => this.setState({
                  confirm_new_pw: e.target.value,
                  input_new_pw_pristine: false
                })}
              />
              <small
                className="absolute top-3 right-2 cursor-pointer text-slate-500"
                onClick={() => this.setState({ show_pw: !this.state.show_pw })}
              >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
            </div>
          </Field>
          {(!this.state.input_new_pw_pristine && formValidError) &&
            <Field className="field mt-4">
              <span className="text-red-500">{formValidError}</span>
            </Field>
          }
          <Button
            className="btn-primary mt-4"
            disabled={user_data_saving || formValidError || this.state.input_new_pw_pristine}
            onClick={() => this.changePassword()}
          >
            <span className="flex gap-1 justify-center items-center">
              {user_data_saving && <Spinner />} {/* Please use separate loading state here */}
              <span>Change Password</span>
            </span>
          </Button>
        </div>
      </div>
      <div className="flex gap-4 mt-4">
        <div className="py-3 px-4 mt-3 rounded-lg bg-slate-100/75 basis-1/2">
          <h2 className="font-semibold mb-3">Security</h2>
          <Field className="flex items-center gap-2">
            <Checkbox
              className="checkbox"
              checked={userData?.settings?.two_factor_auth ? true : false}
              disabled={this.props.user_settings_loading}
              onChange={() => this.props.userUpdateSettings({two_factor_auth: !userData?.settings?.two_factor_auth})}
            />
            <Label>Activate Two-Factor Authentication (2FA)</Label>
          </Field>
        </div>
      </div>
      </>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      user_data_saving: state.User.get("user_data_saving"),
      user_settings_loading: state.User.get("user_settings_loading"),
    }),
    {
      userPasswordReset,
      userUpdateData,
      userUpdateSettings,
    }
  )(Account)
);
