import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Disclosure, DisclosurePanel, Field, Input, Label, Radio, RadioGroup, Switch, Textarea, Transition } from "@headlessui/react"
import { useParams } from "react-router"
import Select from 'react-select'
import PDFViewer from "../../components/PDFViewer"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Spinner from "../../components/Spinner"
import CurrencyInput from "react-currency-input-field"
import { ReactComponent as IconPrint } from '../../assets/icons/icon-print.svg'
import { ReactComponent as IconDownload } from '../../assets/icons/icon-download.svg'
import { ReactComponent as IconMail } from '../../assets/icons/icon-mail.svg'
import { ReactComponent as IconSlack } from '../../assets/icons/icon-slack.svg'
import { Tooltip } from "react-tooltip"
import printJS from "print-js"
import Select1099Box from "../../components/Select1099Box"
import InvoiceGLTable from "./InvoiceGLTable"
import QuickAddVendor from "./QuickAddVendor"
import documentsActions from "../../redux/documents/actions";
import vendorsActions from "../../redux/vendors/actions";
import invoicesActions from "../../redux/invoices/actions";
import moment from "moment";
import RecurringInvoiceSetup from "./RecurringInvoiceSetup";
import InvoiceWorkflow from "./InvoiceWorkflow";

const {
  invoicesAddInvoice,
  invoicesUpdateInvoice,
  invoicesUpdateInvoiceStatus,
} = invoicesActions;

const { vendorsGetAllForClient } = vendorsActions;

const {
  documentsDocumentPreview,
} = documentsActions;

const bank_accounts = [
  {value: 1, label: 'CHASE SAVINGS 1234'},
  {value: 2, label: 'CHASE CHECKING 5678'},
]

const payment_method = [
  {value: 'automatic_debit', label: 'Automatic Debit'},
  {value: 'check', label: 'Check'},
]

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class EnterInvoice extends Component {
  state = {
    selectedVendor: null,
    selectedBankAccountId: null,
    invoice_date: null,
    due_date: null,
    check_date: null,
    type: 'vendor',
    submit_loading: false,
    show_pdf: false,
    forms_1099: null,
    gl_table_key: Math.random(),
    new_invoice_data: null,
    makeRecurring: false,
  }

  setInvoiceData = (key, value) => {
    const { new_invoice_data } = this.state;
    if(new_invoice_data){
      var new_invoice = {...new_invoice_data};
      new_invoice[key] = value;
      this.setState({new_invoice_data: new_invoice})
    } else {
      this.setState({new_invoice_data: {[key]: value}})
    }
  }

  componentDidMount() {
    const { userData, params, edit } = this.props;
    if(!edit){
      this.props.documentsDocumentPreview({
        client_id: userData?.active_client?.id,
        document: params.doc_id
      })
    }
    this.props.vendorsGetAllForClient({client_id: userData?.active_client?.id});
  }

  componentDidUpdate = (pp) => {
    const { vendors, edit, invoice } = this.props;
    if(pp.vendors !== vendors && edit && invoice){
      var vendor = vendors?.data?.find(item => item.id === invoice?.vendor?.id);
      if(vendor){
        vendor.value = vendor.id;
        vendor.label = vendor.name;
        this.setState({selectedVendor: vendor})
      }
    }
    if(edit && !pp.invoice && invoice){
       this.setState({
         forms_1099: {
           value: invoice?.form_1099?.id,
           label: invoice?.form_1099?.title,
         }}
       );
    }
  }

  submit = () => {
    const { new_invoice_data } = this.state;
    const { userData, params } = this.props;

    var data = { ...new_invoice_data }
    data.client_id = userData?.active_client?.id;
    data.document_id = params.doc_id;
    data.invoice_date = data.invoice_date.toISOString();
    if(data.invoice_due_date){
      data.invoice_due_date = data.invoice_due_date.toISOString();
    }
    if(data.invoice_check_date){
      data.invoice_check_date = data.invoice_check_date.toISOString();
    }
    data.accounting = this.prepareAccounting();
    if(this.state.makeRecurring){
      data.recurring = this.prepareRecurring();
    }
    this.props.invoicesAddInvoice(data);
  }

  prepareAccounting = () => {
    const { new_invoice_data  } = this.state;
    if(!new_invoice_data?.gl_accounts){ return null };

    return new_invoice_data?.gl_accounts?.map(item => {
      return {
        client: item.client,
        account: item.account.value,
        description: item.description,
        debit: parseFloat(item?.debit) || 0,
        credit: parseFloat(item?.credit) || 0,
        form_1099_id: item?.form_1099?.value,
      }
    })
  }

  prepareRecurring = () => {
    const { new_invoice_data  } = this.state;
    const recurring = {...new_invoice_data.recurring}
    return {
      is_active: recurring?.is_active ? recurring?.is_active : false,
      template_name: recurring?.template_name ? recurring?.template_name : "",
      due_date_offset: recurring?.due_date_offset ? recurring?.due_date_offset : null,
      check_date_offset: recurring?.check_date_offset ? recurring?.check_date_offset : null,
      recurring_end_date_infinite:
        recurring?.recurring_end_date_infinite === undefined
          ? false
          : true,
      recurring_end_amount: recurring?.recurring_end_amount ? recurring?.recurring_end_amount?.data_value : 0,
      recurring_end_occurrences: recurring?.recurring_end_occurrences ? recurring?.recurring_end_occurrences?.data_value : 0,
      recurring_end_date: recurring?.recurring_end_date ? recurring?.recurring_end_date?.data_value : null,
      image_requirement: recurring?.image_requirement ? recurring?.image_requirement?.value : null,
      amount_type: recurring?.amount_type ? recurring?.amount_type?.value : null,
      amount: recurring?.amount ? recurring?.amount : 0,
      period: recurring?.period ? recurring?.period?.value : null,
      every: recurring?.every ? recurring?.every : null,
      dates: recurring?.dates
        ? recurring?.dates.filter(item => item !== null).join(",")
        : null,
      days: recurring?.days
        ? recurring?.days
        : null,
    }
  }

  update = () => {
    const { new_invoice_data } = this.state;
    const { userData, params } = this.props;

    var data = { ...new_invoice_data }
    delete data.gl_accounts;
    data.client_id = userData?.active_client?.id;
    data.id = params.id;
    if(data.invoice_date){
      data.invoice_date = data.invoice_date.toISOString();
    }
    if(data.invoice_due_date){
      data.invoice_due_date = data.invoice_due_date.toISOString();
    }
    if(data.invoice_check_date){
      data.invoice_check_date = data.invoice_check_date.toISOString();
    }
    data.accounting = this.prepareAccounting();
    this.props.invoicesUpdateInvoice(data);
  }

  checkFormValid = () => {
    const { new_invoice_data } = this.state;
    if(!new_invoice_data){ return false }
    if(!new_invoice_data?.invoice_id
        || !new_invoice_data?.amount
        || !new_invoice_data?.invoice_date
        || !new_invoice_data?.description
        || !new_invoice_data?.vendor_id
    ) { return false }
    if(!this.checkAccountingValid()){ return false; }
    return true;
  }

  generateVendorSelect = () => {
    const { vendors } = this.props;
    const addNewOption = {id: 'new_vendor', name: <span className="text-blue-500">+ Add new vendor</span>}
    let vendorsWithAddNew = [addNewOption, ...vendors?.data || []]
    if(vendorsWithAddNew){
      return vendorsWithAddNew.map(item => {
        item.value = item.id;
        item.label = item.name;
        return item;
      })
    }
    return []
  }

  setDefaultEmptyValue = (field) => {
    return field ? field : '-'
  }

  selectVendor = (selected) => {
    // if vendor changed, empty gl_accounts
    if(selected?.value !== this.state.selectedVendor?.value) {
      // force re render gl table
      this.setState({
        gl_table_key: Math.random(),
      })
      this.setInvoiceData("gl_accounts", []);
    }
    this.setState({ selectedVendor: selected })
  }


  loadAccountingDataForEdit = () => {
    const { userData, edit, invoice } = this.props;
    if(userData && edit && invoice){
      let gl_accounts = [];
      invoice.accounting?.forEach(item => {
        gl_accounts.push({
          id: item.id,
          client: item.client.id,
          account: {
            ...item.account,
            ...{
              label: `${item?.account?.account_number} - ${item?.account?.title}`,
              value: item?.account?.id,
            }
          },
          description: item.description,
          debit: item.debit,
          credit: item.credit,
          form_1099: {
            value: item?.form_1099?.id,
            label: item?.form_1099?.title,
          }
        })
      })
      return gl_accounts;
    }
    return [];
  }

  loadRecurringDataForEdit = () => {
    return {}
  }

  addAccountRow = () => {
    const { userData, edit } = this.props;
    const { new_invoice_data } = this.state;
    let gl_accounts = edit
      ? new_invoice_data?.gl_accounts
        ? [...new_invoice_data.gl_accounts]
        : [...this.loadAccountingDataForEdit()]
      : [...(new_invoice_data?.gl_accounts || [])]

    const nextId = gl_accounts.length + 1
    gl_accounts.push(
      {
        id: nextId,
        client: userData?.active_client?.id,
        account: null,
        property: null,
        description: "",
        forms_1099: null,
      }
    )
    this.setInvoiceData("gl_accounts", gl_accounts);
  }

  deleteAccountRow = (id) => {
    const { edit } = this.props;
    const { new_invoice_data } = this.state;
    let gl_accounts = edit
      ? new_invoice_data?.gl_accounts
        ? [...new_invoice_data.gl_accounts]
        : [...this.loadAccountingDataForEdit()]
      : [...(new_invoice_data?.gl_accounts || [])]

    gl_accounts = gl_accounts.filter(item => item.id !== id)
    this.setInvoiceData("gl_accounts", gl_accounts);
  }

  updateAccountRowData = (id, key, value) => {
    const { edit } = this.props;
    const { new_invoice_data } = this.state;
    let gl_accounts = edit
      ? new_invoice_data?.gl_accounts
        ? [...new_invoice_data.gl_accounts]
        : [...this.loadAccountingDataForEdit()]
      : [...(new_invoice_data?.gl_accounts || [])]

    let data = {...gl_accounts.find(item => item.id === id)}
    data[key] = value;
    gl_accounts = gl_accounts.map(item => {
      return item.id === data.id ? data : item;
    });
    this.setInvoiceData("gl_accounts", gl_accounts);
  }

  updateRecurring = (key, value) => {
    const { new_invoice_data } = this.state;
    if(new_invoice_data?.recurring){
      var new_invoice = {...new_invoice_data};
      new_invoice.recurring[key] = value;
      this.setState({new_invoice_data: new_invoice})
    } else {
      this.setState({
        new_invoice_data: {
          recurring: {[key]: value}
        }
      })
    }
  }

  checkAccountingValid = () => {
    const { new_invoice_data } = this.state;
    const { edit } = this.props;

    if(!new_invoice_data?.gl_accounts && !edit){ return false };
    var valid = true;
    var sum = 0;
    if(new_invoice_data?.gl_accounts){
      new_invoice_data?.gl_accounts.forEach(item => {
        if(valid){
          if(!item.client){ valid = false; };
          if(!item.account){ valid = false; };
          if(!item.description){ valid = false; };
          if(!parseFloat(item.debit) && !parseFloat(item.credit)){ valid = false; };
          if(parseFloat(item.debit) && parseFloat(item.credit)) { valid = false; };
          sum = sum + (parseFloat(item.debit) || 0) - (parseFloat(item.credit) || 0);
        }
      })
      if(sum !== 0){ return false }
    }

    return valid;
  }

  validateRecurringData = () => {
    if(!this.state.makeRecurring){ return true; }
    const { new_invoice_data } = this.state;
    if(!new_invoice_data?.recurring){ return false; }
    const { recurring } = new_invoice_data;
    if(!recurring?.period){ return false; }
    if(!recurring?.every){ return false; }
    if(!recurring?.dates && !recurring?.days){ return false; }
    if(!recurring?.recurring_end_amount
        && !recurring?.recurring_end_date
        && !recurring?.recurring_end_date_infinite
        && !recurring?.recurring_end_occurrences){ return false; }
    if(!recurring?.recurring_end_date_infinite
        && !recurring?.recurring_end_amount?.data_value
        && !recurring?.recurring_end_date?.data_value
        && !recurring?.recurring_end_occurrences?.data_value){ return false; }
    if(!recurring?.amount_type){ return false; }
    if(!recurring?.image_requirement){ return false; }
    return true;
  }

  render() {
    const { data, document_preview_url, invoices_working, edit, edit_template } = this.props;
    const { new_invoice_data } = this.state;

    return (
      <div>
        <div className="flex justify-between items-center mb-5">
          {edit ?
          <div className="flex items-center gap-3">
            <h1 className="text-xl font-semibold">Invoice</h1>
            <span className="inline-block bg-blue-100 text-blue-700 px-2 py-1 font-semibold text-sm rounded">
              {data?.invoice_status.replace('_', ' ')}
            </span>
          </div>
          :
          edit_template ?
            <h1 className="text-xl font-semibold leading-none">Edit Invoice Template</h1>
            :
            <h1 className="text-xl font-semibold leading-none">Enter Invoice</h1>
          }
        </div>
        <div className="flex justify-between">
          <div className="flex-1">
            <Field className="field">
              <Label>Type</Label>
              <RadioGroup value={this.state.type} onChange={selected => this.setState({ type: selected })} className="flex flex-row gap-8">
                <Field className="flex items-center gap-2">
                  <Radio className="radio" value="vendor" />
                  <Label>Vendor</Label>
                </Field>
                <Field className="flex items-center gap-2">
                  <Radio className="radio" value="payee" />
                  <Label>Payee</Label>
                </Field>
              </RadioGroup>
            </Field>
            <Field className="field flex-1 mt-4">
              <Label>Vendor</Label>
              <Select
                placeholder="Select vendor"
                options={this.generateVendorSelect()}
                value={this.state.selectedVendor}
                onChange={selected => {
                  this.setState({ selectedVendor: selected });
                  this.setInvoiceData("vendor_id", selected.id);
                }}
              />
            </Field>
            {this.state.selectedVendor?.value === 'new_vendor' ?
            <QuickAddVendor
              cancel={() => this.setState({ selectedVendor: null })}
              saveAndSelect={newVendor => {}} />
            :
            <div className="flex-1 mt-4">
              <div className="border rounded">
                <div className="group px-3 py-2 shadow-none rounded-none text-slate-500 bg-slate-100 text-left w-full flex justify-between items-center">
                  <span>Vendor Details</span>
                </div>
                <div className="p-3 bg-slate-50/50">
                  <Field className="field">
                    <Label>Address</Label>
                    <span className="block">{this.state.selectedVendor?.address_line_1}</span>
                    <span className="block">{this.state.selectedVendor?.address_line_2}</span>
                  </Field>
                  <div className="grid grid-rows-1 grid-flow-col gap-2 mt-4">
                    <Field className="field">
                      <Label>City</Label>
                      <span>{this.setDefaultEmptyValue(this.state.selectedVendor?.address_city)}</span>
                    </Field>
                    <Field className="field">
                      <Label>State/Province</Label>
                      <span>{this.setDefaultEmptyValue(this.state.selectedVendor?.address_state)}</span>
                    </Field>
                    <Field className="field">
                      <Label>Zip Code</Label>
                      <span>{this.setDefaultEmptyValue(this.state.selectedVendor?.address_zip)}</span>
                    </Field>
                    <Field className="field">
                      <Label>Country</Label>
                      <span>{this.setDefaultEmptyValue(this.state.selectedVendor?.address_country)}</span>
                    </Field>
                  </div>
                  <hr className="mt-4" />
                  <div className="grid grid-rows-1 grid-flow-col gap-4 mt-4">
                    <Field className="field">
                      <Label>Account Number</Label>
                      <span>{this.setDefaultEmptyValue(this.state.selectedVendor?.account_number)}</span>
                    </Field>
                    <Field className="field">
                      <Label>ACH Account No.</Label>
                      <span>{this.setDefaultEmptyValue(this.state.selectedVendor?.ach_account_number)}</span>
                    </Field>
                    <Field className="field">
                      <Label>ACH Routing No.</Label>
                      <span>{this.setDefaultEmptyValue(this.state.selectedVendor?.ach_routing_number)}</span>
                    </Field>
                  </div>
                </div>
              </div>
            </div>
            }
            <div className="grid grid-cols-2 gap-4">
              <Field className="field mt-4">
                <Label>Invoice #</Label>
                <Input
                  type="text"
                  onChange={(e) => this.setInvoiceData("invoice_id", e.target.value)}
                  defaultValue={data?.invoice_id ? data?.invoice_id : null}
                />
              </Field>
              <Field className="field mt-4">
                <Label>Amount</Label>
                <CurrencyInput
                  prefix="$"
                  onValueChange={value => this.setInvoiceData("amount", value)}
                  value={new_invoice_data?.amount
                    ? new_invoice_data.amount
                    : ("amount" in (new_invoice_data || {}))
                      ? null
                      : data?.amount
                        ? data?.amount
                        : null
                  }
                />
              </Field>
            </div>
            <div className="grid grid-flow-col auto-cols-max gap-4 mt-4">
              <Field className="field">
                <Label>Invoice Date</Label>
                <DatePicker
                  selected={new_invoice_data?.invoice_date
                    ? new_invoice_data.invoice_date
                    : ("invoice_date" in (new_invoice_data || {}))
                      ? null
                      : data?.invoice_date
                        ? moment(data.invoice_date)?._d
                        : null
                  }
                  onChange={selected => this.setInvoiceData("invoice_date", selected)}
                  value={data?.invoice_date ? new Date(data?.invoice_date) : null}
                  enableTabLoop={false}
                />
              </Field>
              <Field className="field">
                <Label>Due Date</Label>
                <DatePicker
                  selected={new_invoice_data?.invoice_due_date
                    ? new_invoice_data.invoice_due_date
                    : ("invoice_due_date" in (new_invoice_data || {}))
                      ? null
                      : data?.invoice_due_date
                        ? moment(data.invoice_due_date)?._d
                        : null
                  }
                  minDate={this.state.new_invoice_data?.invoice_date}
                  disabled={!this.state.new_invoice_data?.invoice_date}
                  onChange={selected => this.setInvoiceData("invoice_due_date", selected)}
                  enableTabLoop={false}
                />
              </Field>
              <Field className="field">
                <Label>Check Date</Label>
                <DatePicker
                  selected={new_invoice_data?.invoice_check_date
                    ? new_invoice_data.invoice_check_date
                    : ("invoice_check_date" in (new_invoice_data || {}))
                      ? null
                      : data?.invoice_check_date
                        ? moment(data.invoice_check_date)?._d
                        : null
                  }
                  minDate={this.state.new_invoice_data?.invoice_date}
                  disabled={!this.state.new_invoice_data?.invoice_date}
                  onChange={selected => this.setInvoiceData("invoice_check_date", selected)}
                  enableTabLoop={false}
                />
              </Field>
            </div>
            <div className="grid grid-flow-col grid-cols-2 gap-4 mt-4">
              <Field className="field">
                <Label>Bank Account</Label>
                <Select
                  options={bank_accounts}
                  className="flex-1"
                />
              </Field>
              <Field className="field">
                <Label>Payment Method</Label>
                <Select
                  options={payment_method}
                />
              </Field>
            </div>
            <Field className="field mt-4">
              <Label>1099 Box</Label>
              <Select1099Box
                value={this.state.forms_1099}
                onChange={selected => {
                  this.setState({ forms_1099: selected })
                  this.setInvoiceData("form_1099_id", selected.id);
                }}
              />
            </Field>
            <Field className="field mt-4">
              <Label>Additional Vendor Account Number</Label>
              <Input
                type="text"
                onChange={(e) => this.setInvoiceData("additional_vendor_account_number", e.target.value)}
                defaultValue={data?.additional_vendor_account_number
                  ? data?.additional_vendor_account_number
                  : null
                }
              />
            </Field>
            <div className="grid grid-flow-col grid-cols-2 gap-4 mt-4">
              <Field className="field">
                <Label>Description</Label>
                <Textarea
                  onChange={(e) => this.setInvoiceData("description", e.target.value)}
                  defaultValue={data?.description ? data?.description : null}
                ></Textarea>
              </Field>
              <Field className="field">
                <Label>Check Stub Notes</Label>
                <Textarea
                  onChange={(e) => this.setInvoiceData("check_stub_notes", e.target.value)}
                  defaultValue={data?.check_stub_notes ? data?.check_stub_notes : null}
                />
              </Field>
            </div>
          </div>
          <Transition
            show={document_preview_url ? true : false}
            enter="transition-transform duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
          >
            <div className="pl-6">
              <div className="block mb-1 flex justify-between items-center">
                <div>
                  <span className="text-slate-500">Document ID:</span> <span>{this.props.edit && data ? data?.document : this.props.params.doc_id}</span>
                </div>
                <div className="flex gap-3 text-slate-500 text-sm">
                  <span
                    data-tooltip-id="t-print" className="flex gap-1" role="button"
                    onClick={() => printJS(document_preview_url)}
                  >
                    <IconPrint className="size-4" />
                  </span>
                  <span data-tooltip-id="t-download" className="flex gap-1" role="button">
                    <IconDownload className="size-4" />
                  </span>
                  <span data-tooltip-id="t-mail" className="flex gap-1" role="button">
                    <IconMail className="size-4" />
                  </span>
                  <span data-tooltip-id="t-slack" className="flex gap-1" role="button">
                    <IconSlack className="size-4" />
                  </span>
                  <Tooltip id="t-print" content="Print PDF" />
                  <Tooltip id="t-download" content="Download PDF" />
                  <Tooltip id="t-mail" content="Mail PDF" />
                  <Tooltip id="t-slack" content="Share on Slack" />
                </div>
              </div>
              <PDFViewer file_url={document_preview_url} />
            </div>
          </Transition>
        </div>
        <div className="mt-8">
          <h2 className="font-semibold text-lg mb-2">Accounting</h2>
          <InvoiceGLTable
            key={this.state.gl_table_key}
            data={edit
              ? new_invoice_data?.gl_accounts
                ? new_invoice_data?.gl_accounts
                : this.loadAccountingDataForEdit()
              : new_invoice_data?.gl_accounts || []
            }
            addRow={this.addAccountRow}
            deleteRow={this.deleteAccountRow}
            updateRowData={this.updateAccountRowData}
          />
        </div>
        {(!this.props.edit || this.props.edit_template) &&
        <div className="mt-8">
          <div className="flex items-center gap-4 mb-2">
            <h2 className="font-semibold text-lg">Recurring Invoice</h2>
            {!this.props.edit_template &&
            <Field>
              <Label className="flex items-center gap-2">
                <Switch
                  checked={this.state.makeRecurring}
                  onChange={() => {
                    this.setState({ makeRecurring: !this.state.makeRecurring });
                    this.setInvoiceData("recurring", {});
                  }}
                />
                <span>Make recurring</span>
              </Label>
            </Field>
            }
          </div>
        </div>
        }
        {(this.state.makeRecurring || this.props.edit_template) &&
        <Disclosure as="div" defaultOpen>
          <DisclosurePanel
            transition
            className="origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0 rounded bg-slate-50 p-2"
          >
            <RecurringInvoiceSetup
              data={edit
                ? new_invoice_data?.recurring
                  ? new_invoice_data?.recurring
                  : this.loadRecurringDataForEdit()
                : new_invoice_data?.recurring || []
              }
              updateRecurring={this.updateRecurring}
            />
          </DisclosurePanel>
        </Disclosure>
        }
        {data?.invoice_status &&
        <div className="rounded mt-8 bg-slate-50 p-2">
          <InvoiceWorkflow
            invoice={data}
          />
        </div>
        }
        <div className="flex justify-between mt-8 bg-slate-50 p-2">
          {this.props.data
            ?
              <Button
                className="btn-primary"
                disabled={(invoices_working || !new_invoice_data) || !this.checkAccountingValid()}
                onClick={this.update}
              >
                <span className="flex gap-1 justify-center items-center">
                  {invoices_working && <Spinner />}
                  <span>Update</span>
                </span>
              </Button>
            :
              <Button
                className="btn-primary"
                disabled={invoices_working || !this.checkFormValid() || !this.validateRecurringData()}
                onClick={this.submit}
              >
                <span className="flex gap-1 justify-center items-center">
                  {invoices_working && <Spinner />}
                  <span>Submit</span>
                </span>
              </Button>
          }
          <Button
            className="btn-tertiary"
            onClick={() => this.props.history.goBack()}
          >Cancel</Button>
        </div>
      </div>
    )
  }
}

export default withParams(withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      document_preview_url: state.Documents.get("document_preview_url"),
      document_preview_url_working: state.Documents.get("document_preview_url_working"),
      vendors: state.Vendors.get("vendors"),
      vendors_working: state.Vendors.get("vendors_working"),
      invoices_working: state.Invoices.get("invoices_working"),
      invoice: state.Invoices.get("invoice"),
    }),
    {
      documentsDocumentPreview,
      vendorsGetAllForClient,
      invoicesAddInvoice,
      invoicesUpdateInvoice,
      invoicesUpdateInvoiceStatus,
    }
  )(EnterInvoice)
));
