import React, { Component } from 'react'
import { momentDateToISOFormatConversion } from "../../helpers/utils";

class ChartOfAccountsReport extends Component {

  render() {
    const {
      userData,
      report,
    } = this.props;

    return (
      <div>
        <div className="border rounded px-4 py-8">
          <div className="text-center mb-6">
            <h3 className="text-2xl mb-2">{userData?.active_client?.name}</h3>
            <div>
              <h4 className="font-semibold">Chart of Accounts</h4>
              <p>Period: {momentDateToISOFormatConversion(report?.start_date, 'MM/DD/YYYY')} - {momentDateToISOFormatConversion(report?.end_date, 'MM/DD/YYYY')}</p>
            </div>
          </div>
          <table className="table-auto w-full">
            <thead className="border-b-2 border-slate-200">
              <tr>
                <th className="p-1">Account Number</th>
                <th className="p-1">Title</th>
                <th className="p-1">User Added</th>
                <th className="p-1">Balance</th>
              </tr>
            </thead>
            <tbody>
              {report?.data?.map((item, idx) => (
              <tr key={`${item?.id}-${idx}`} className="border-b-2 border-slate-200">
                <td className="p-1">{item?.account_number}</td>
                <td className="p-1">{item?.title}</td>
                <td className="p-1">{item?.company ? "YES" : "NO"}</td>
                <td className="p-1">{item?.balance_name}</td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default ChartOfAccountsReport;
