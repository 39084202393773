const accountingActions = {
	ACCOUNTING_GL_ACCOUNTS_SEARCH: 'ACCOUNTING_GL_ACCOUNTS_SEARCH',
	ACCOUNTING_GL_1099_ALL: 'ACCOUNTING_GL_1099_ALL',
	ACCOUNTING_ALL_ACCOUNTS: 'ACCOUNTING_ALL_ACCOUNTS',
	ACCOUNTING_ADD_ACCOUNT: 'ACCOUNTING_ADD_ACCOUNT',
	ACCOUNTING_UPDATE_ACCOUNT: 'ACCOUNTING_UPDATE_ACCOUNT',

  accountingUpdateAccount: (data, additional_actions) => ({
    type: accountingActions.ACCOUNTING_UPDATE_ACCOUNT,
    data,
    additional_actions,
  }),

  accountingAddAccount: (data, additional_actions) => ({
    type: accountingActions.ACCOUNTING_ADD_ACCOUNT,
    data
  }),

  accountingAllAccounts: (data) => ({
    type: accountingActions.ACCOUNTING_ALL_ACCOUNTS,
    data
  }),

  accountingGL1099All: (data) => ({
    type: accountingActions.ACCOUNTING_GL_1099_ALL,
    data
  }),

  accountingGLAccountsSearch: (data) => ({
    type: accountingActions.ACCOUNTING_GL_ACCOUNTS_SEARCH,
    data
  }),
}

export default accountingActions;
