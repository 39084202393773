import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { useState } from "react";

export default function DateNumberPicker({ onChange, disabled, value = null }) {
  const[selectedDate, setSelectedDate] = useState(value ? new Date(`2024/01/${value}`) : "")
  return (
    <DatePicker
      renderCustomHeader={() => null}
      dateFormat="D"
      placeholderText="Select..."
      selected={selectedDate}
      disabled={disabled ? true : false}
      className="date-number-picker-input"
      calendarClassName="calendar-date-number-picker"
      enableTabLoop={false}
      calendarStartDay={1}
      onChange={(date) => {
        setSelectedDate(new Date(`2024/01/${moment(date).format('D')}`))
        onChange(moment(date).format('D'));
      }}
    />
  )
}
