import { all, fork } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* documentsGetAllForClient() {
  yield commonSaga(
    actions.DOCUMENTS_GET_ALL_FOR_CLIENT,
    apiCalls.get,
    apiEndpoints.documents.client,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* documentsAddDocument() {
  yield commonSaga(
    actions.DOCUMENTS_ADD_DOCUMENT,
    apiCalls.post,
    apiEndpoints.documents.document,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* documentsDocumentPreview() {
  yield commonSaga(
    actions.DOCUMENTS_DOCUMENT_PREVIEW,
    apiCalls.get,
    apiEndpoints.documents.document_preview,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export default function* documentsSaga() {
	yield all([
		fork(documentsGetAllForClient),
		fork(documentsAddDocument),
		fork(documentsDocumentPreview),
	]);
}
