import { Map } from "immutable";

const initState = new Map({
  invoices: {},
  invoices_working: false,
  invoice: null,
  recurring: {},
  recurring_working: false,
});

export default function invoicesReducer(state = initState, action) {
  switch (action.type) {

    case "INVOICES_RECURRING_UPDATE":
      return state
        .set("recurring_working", true)
    case "INVOICES_RECURRING_UPDATE_SUCCESS":
      var recurring_update = {...state.get("recurring")}
      recurring_update.data = recurring_update.data.map(item => {
        if(item.id === action.data.data.id){
          return action.data.data;
        }
        return item;
      })
      return state
        .set("recurring", {...recurring_update})
        .set("recurring_working", false)
    case "INVOICES_RECURRING_UPDATE_FAILED":
      return state
        .set("recurring_working", false)

    case "INVOICES_RECURRING_GET_ALL":
      return state
        .set("recurring_working", true)
    case "INVOICES_RECURRING_GET_ALL_SUCCESS":
      return state
        .set("recurring", {...action.data.data})
        .set("recurring_working", false)
    case "INVOICES_RECURRING_GET_ALL_FAILED":
      return state
        .set("recurring_working", false)

    case "INVOICES_UPDATE_INVOICE_STATUS":
      return state
        .set("invoices_working", true)
    case "INVOICES_UPDATE_INVOICE_STATUS_SUCCESS":
      return state
        .set("invoice", {...action.data.data})
        .set("invoices_working", false)
    case "INVOICES_UPDATE_INVOICE_STATUS_FAILED":
      return state
        .set("invoices_working", false)

    case "INVOICES_UPDATE_INVOICE":
      return state
        .set("invoices_working", true)
    case "INVOICES_UPDATE_INVOICE_SUCCESS":
      return state
        .set("invoice", {...action.data.data})
        .set("invoices_working", false)
    case "INVOICES_UPDATE_INVOICE_FAILED":
      return state
        .set("invoices_working", false)

    case "INVOICES_GET_INVOICE":
      return state
        .set("invoice", null)
        .set("invoices_working", true)
    case "INVOICES_GET_INVOICE_SUCCESS":
      return state
        .set("invoice", {...action.data.data})
        .set("invoices_working", false)
    case "INVOICES_GET_INVOICE_FAILED":
      return state
        .set("invoice", null)
        .set("invoices_working", false)

    case "INVOICES_ADD_INVOICE":
      return state
        .set("invoices_working", true)
    case "INVOICES_ADD_INVOICE_SUCCESS":
      var invoices_add = {...state.get("invoices")}
      if(invoices_add?.data){
        invoices_add.data = [action.data.data, ...invoices_add?.data];
      } else {
        invoices_add.data = [action.data.data];
      }
      return state
        .set("invoices", {...invoices_add})
        .set("invoices_working", false)
    case "INVOICES_ADD_INVOICE_FAILED":
      return state
        .set("invoices_working", false)

    case "INVOICES_GET_ALL_FOR_CLIENT":
      return state
        .set("invoices", {})
        .set("invoices_working", true)
    case "INVOICES_GET_ALL_FOR_CLIENT_SUCCESS":
      return state
        .set("invoices", {...action.data.data})
        .set("invoices_working", false)
    case "INVOICES_GET_ALL_FOR_CLIENT_FAILED":
      return state
        .set("invoices", {})
        .set("invoices_working", false)

		default:
			return state;
  }
}
