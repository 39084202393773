import React, { Component } from "react"
import Select from 'react-select'

const property_options = [
  { value: 1, label: 'Beverly Hills' },
  { value: 2, label: 'Newport Beach' }
]

class SelectProperty extends Component {
  render() {
    const { menuPlacement, propertyId, onChange } = this.props
    const value = property_options.find(item => item.value === propertyId)

    return (
      <Select
        options={property_options}
        menuPlacement={menuPlacement ? menuPlacement : 'bottom'}
        value={value}
        onChange={onChange}
      />
    )
  }
}

export default SelectProperty