import React, { Component } from 'react'
import { connect } from "react-redux";
import { digitСonversion, sdMessage } from "../../helpers/utils"
import { Button, Field, Label, Switch } from "@headlessui/react"
import { NavLink  } from "react-router-dom"
//import Pagination from "../../components/Pagination"
import ConfirmDialog from "../../components/ConfirmDialog"
import { withRouter } from "react-router"
import invoicesActions from "../../redux/invoices/actions";
import moment from "moment-timezone";

const {
  invoicesRecurringGetAll,
  invoicesRecurringUpdate,
} = invoicesActions;

class InvoiceTemplates extends Component {
  state = {
    deleteId: null
  }

  componentDidMount = () => {
    this.props.invoicesRecurringGetAll({
      client_id: this.props.userData?.active_client?.id,
    });
  }

  activate = (item) => {
    this.props.invoicesRecurringUpdate({
      client_id: this.props.userData?.active_client?.id,
      id: item.id,
      is_active: !item.is_active,
    });
  }

  render() {
    const {
      userData,
      recurring,
      //recurring_working,
    } = this.props;

    return (
      <div>
        <h1 className="text-xl font-semibold leading-none mb-5">Recurring Transactions</h1>
        <>
          <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4">
          {recurring?.data?.length > 0 ?
            <table className="w-full text-[15px]">
              <thead>
                <tr className="border-b-2 border-slate-200">
                  <th className="p-3">Template Name</th>
                  <th className="p-3">Interval</th>
                  <th className="p-3">Previous Date</th>
                  <th className="p-3">Vendor</th>
                  <th className="p-3 text-right">Amount</th>
                  <th className="p-3">Status</th>
                  <th className="p-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {recurring?.data?.map(item => (
                <tr key={`template-${item.id}`} className="border-t border-slate-200">
                  <td className="p-2">
                    <NavLink to={`/app/client/${userData?.active_client?.slug}/invoices/templates/edit-template/${item.invoice}`} className="no-underline text-blue-700 hover:text-blue-500 font-normal">{item.template_name}</NavLink>
                  </td>
                  <td className="p-3">{item?.recurring_period_human}</td>
                  <td className="p-3">{item?.last_date ? moment(item?.last_date).format("L") : "N/A"}</td>
                  <td className="p-3">{item?.vendor}</td>
                  <td className="p-3 text-right">{digitСonversion(item.amount, 'currency')}</td>
                  <td className="p-3">
                    <Field className="flex items-center gap-2">
                      <Switch
                        checked={item.is_active}
                        onChange={() => this.activate(item)}
                      />
                      <Label className="text-slate-800">{item.is_active ? 'Active' : 'Inactive'}</Label>
                    </Field>
                  </td>
                  <td className="p-3">
                    <Button
                      className="btn-danger btn-sm"
                      onClick={() => this.setState({ deleteId: item.id })}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
            :
            <p>Currenly no recurring transactions found.</p>
          }
          <ConfirmDialog
            open={this.state.deleteId !== null }
            type="danger"
            title="Delete invoice template"
            text="Are you sure want to delete this invoice template?"
            confirmBtnText="Yes"
            onClose={() => this.setState({ deleteId: null })}
            onConfirm={() => {
              sdMessage('Invoice has been deleted.', 'success')
              this.setState({ deleteId: null })
            }}
          />
          </div>
          {/*
          <div className="flex flex-col items-end">
            <Pagination
              data={{page: 1, pages: 3}}
              //callback={}
              callback_params={{client_id: userData?.active_client?.id}}
            />
          </div>
          */}
        </>
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      recurring: state.Invoices.get("recurring"),
      recurring_working: state.Invoices.get("recurring_working"),
    }),
    {
      invoicesRecurringGetAll,
      invoicesRecurringUpdate,
    }
  )(InvoiceTemplates)
)
