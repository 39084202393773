import { Button } from "@headlessui/react"
import { connect } from "react-redux";
import React, { Component } from 'react'
import { Tooltip } from "react-tooltip"
import Spinner from "../../components/Spinner"

class SearchVendorDuplicates extends Component {
  render() {
    const {
      searchField,
      vendors_autofill,
      vendors_autofill_working,
      ignore,
    } = this.props

    if(vendors_autofill?.field !== searchField || ignore) { return null }

    return (
      <div>
        {( vendors_autofill_working) ?
        <div className="absolute top-10 left-0 bg-white border rounded shadow-lg min-w-96 z-10">
          <div className="px-4 py-2 flex items-center gap-1 text-slate-500"><Spinner fillColorClass="fill-slate-500" /> Finding possible duplicates...</div>
        </div>
        :
        vendors_autofill?.data?.length > 0 &&
        <div className="absolute top-10 left-0 bg-white border rounded shadow-lg min-w-96 z-10">
          <div className="flex justify-between px-4 py-2 border-b">
            <h4 className="font-semibold text-orange-500">Found possible duplicates</h4>
            <Button className="btn-tertiary btn-sm" onClick={this.props.ignoreFindings}>Ignore</Button>
          </div>
          <div
            className="flex flex-col overflow-y-auto max-h-52"
          >
            {vendors_autofill?.data?.map(item => (
              <>
              <div
                className="border-t px-4 py-2 hover:bg-slate-50"
                data-tooltip-id={`t-result-${item.id}`}
                onClick={() => {
                  this.props.onSelect(item);
                  this.props.ignoreFindings();
                }}
              >
                <div className="font-medium">{item.name}</div>
                {searchField === 'address_line_1' ?
                <div className="text-slate-500">
                  {item.address_line_1}, {item.address_line_2}, {item.address_city}, {item.address_state_data?.state_code || ""}, {item.address_country_data?.iso2 || ""}, {item.address_zip}
                </div>
                :
                <div className="text-slate-500">Bank Account: {item.bank_account_number}</div>
                }
              </div>
              <Tooltip
                id={`t-result-${item.id}`}
                place="right"
                positionStrategy="fixed"
              >
                {searchField === 'address_line_1' ?
                <span>Bank Account: {item.bank_account_number}</span>
                :
                <>
                  {item.address_line_1}, {item.address_line_2}<br/>
                  {item.address_city}, {item.address_state_data?.state_code || ""}, {item.address_country_data?.iso2 || ""}, {item.address_zip}<br/>
                </>
                }
              </Tooltip>
              </>
            ))}
          </div>
        </div>
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    vendors_autofill: state.Vendors.get("vendors_autofill"),
    vendors_autofill_working: state.Vendors.get("vendors_autofill_working"),
  }),
  {
  }
)(SearchVendorDuplicates)
