import React, { Component } from 'react'
import { digitСonversion, momentDateToISOFormatConversion } from "../../helpers/utils";

class InvoicesReport extends Component {

  render() {
    const {
      userData,
      report,
    } = this.props;

    return (
      <div>
        <div className="border rounded px-4 py-8">
          <div className="text-center mb-6">
            <h3 className="text-2xl mb-2">{userData?.active_client?.name}</h3>
            <div>
              <h4 className="font-semibold">Invoices</h4>
              <p>Period: {momentDateToISOFormatConversion(report?.start_date, 'MM/DD/YYYY')} - {momentDateToISOFormatConversion(report?.end_date, 'MM/DD/YYYY')}</p>
            </div>
          </div>
          <table className="table-auto w-full">
            <thead className="border-b-2 border-slate-200">
              <tr>
                <th className="p-1">Invoice ID</th>
                <th className="p-1">Vendor Name</th>
                <th className="p-1">Amount</th>
                <th className="p-1">Created</th>
                <th className="p-1">Invoice Date</th>
                <th className="p-1">Due Date</th>
                <th className="p-1">Check Date</th>
                <th className="p-1">Desc</th>
                <th className="p-1">Status</th>
              </tr>
            </thead>
            <tbody>
              {report?.data?.map((item, idx) => (
              <tr
                key={`${item?.id}-${idx}`}
                className="border-b-2 border-slate-200"
              >
                <td className="p-1">{item?.invoice_id}</td>
                <td className="p-1">{item?.vendor?.name}</td>
                <td className="p-1">{digitСonversion(item?.amount, "currency")}</td>
                <td className="p-1">{item?.created_at
                  ? momentDateToISOFormatConversion(item?.created_at, 'MM/DD/YYYY')
                  : null
                }</td>
                <td className="p-1">{item?.invoice_date
                  ? momentDateToISOFormatConversion(item?.invoice_date, 'MM/DD/YYYY')
                  : null
                }</td>
                <td className="p-1">{item?.invoice_due_date
                  ? momentDateToISOFormatConversion(item?.invoice_due_date, 'MM/DD/YYYY')
                  : null
                }</td>
                <td className="p-1">{item?.invoice_check_date
                  ? momentDateToISOFormatConversion(item?.invoice_check_date, 'MM/DD/YYYY')
                  : null
                }</td>
                <td className="p-1">{item?.description}</td>
                <td className="p-1">{item?.invoice_status?.replace("_", " ")}</td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default InvoicesReport;
