import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { useState } from "react";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function MonthDatePicker({ onChange, disabled, value=null }) {
  const[selectedDate, setSelectedDate] = useState(value ? new Date(`2024/${value}`) : "")
  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeMonth,
      }) => (
        <div className="px-2">
          <select
            value={moment(date).format('MMMM')}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
            className="w-full"
          >
            {months.map(month => (
              <option key={month} value={month}>{month}</option>
            ))}
          </select>
        </div>
      )}
      dateFormat="MMMM, dd"
      placeholderText="Select..."
      calendarClassName="calendar-month-date-picker"
      selected={selectedDate}
      enableTabLoop={false}
      disabled={disabled ? true : false}
      onChange={(date) => {
        setSelectedDate(new Date(`2024/${moment(date).format('MM')}/${moment(date).format('DD')}`))
        onChange(`${moment(date).format('MM')}/${moment(date).format('DD')}`)
      }}
    />
  )
}
