import { Button, Field, Input, Label } from "@headlessui/react";
import React, { Component } from 'react'
import Spinner from "../../components/Spinner";
import { sdMessage } from "../../helpers/utils";
import ConfirmDialog from "../../components/ConfirmDialog";

const users = [
  {user_id: 1, username: 'username1', email: 'user1@gmail.com', first_name: 'First Name', last_name: 'Last Name'},
  {user_id: 2, username: 'username2', email: 'user2@gmail.com', first_name: 'First Name', last_name: 'Last Name'},
  {user_id: 3, username: 'username3', email: 'user3@gmail.com', first_name: 'First Name', last_name: 'Last Name'},
]

class Users extends Component {
  state = {
    data_loading: false,
    mode: 'list-user',
    add_working: false,
    show_pw: false,
    edit_working: false,
    deleteUserId: null
  }

  componentDidMount() {
    this.setState({ data_loading: true })
    setTimeout(() => {
      this.setState({ data_loading: false })
    }, 1500);
  }
  
  addUser = () => {
    this.setState({ add_working: true })
    setTimeout(() => {
      this.setState({ add_working: false, mode: 'list-user' })
      sdMessage('A new user has been added.', 'success')
    }, 1500);
  }

  editUser = () => {
    this.setState({ edit_working: true })
    setTimeout(() => {
      this.setState({ edit_working: false, mode: 'list-user' })
      sdMessage('User data has been changed.', 'success')
    }, 1500);
  }

  render() {
    if(this.state.mode === 'list-user') {
      return (
        <div>
          {this.state.data_loading ?
          <UsersLoading />
          :
          <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4">
            <div className="flex justify-between mb-4">
              <Button
                className="btn-primary btn-sm"
                onClick={() => this.setState({ mode: 'add-user' })}
              >+ Add new user</Button>
            </div>
            {users.length > 0 ?
            <table className="w-full text-[15px] table-fixed">
              <thead>
                <tr>
                  <th className="text-left border-b-2 border-slate-200">Username</th>
                  <th className="border-b-2 border-slate-200">Name</th>
                  <th className="border-b-2 border-slate-200">Email</th>
                  <th className="border-b-2 border-slate-200">Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map(item => (
                <tr key={`user-${item.user_id}`}>
                  <td className="w-24 border-t py-2 border-slate-200">{item.username}</td>
                  <td className="w-24 border-t py-2 border-slate-200">{`${item.first_name} ${item.last_name}`}</td>
                  <td className="w-24 border-t py-2 border-slate-200">{item.email}</td>
                  <td className="w-24 border-t py-2 border-slate-200">
                    <Button
                      className="btn-secondary btn-sm"
                      onClick={() => this.setState({
                        mode: 'edit-user'
                      })}
                    >Change</Button>
                    <Button
                      className="btn-danger btn-sm ml-2"
                      onClick={() => this.setState({
                        deleteUserId: item.user_id
                      })}
                    >Delete</Button>
                    <ConfirmDialog
                      open={this.state.deleteUserId !== null }
                      type="danger"
                      title="Delete user"
                      text="Are you sure want to delete this user?"
                      onClose={() => this.setState({ deleteUserId: null })}
                      onConfirm={() => {
                        console.log('delete user id: ', this.state.deleteUserId)
                        sdMessage('A user has been deleted.', 'success')
                        this.setState({ deleteUserId: null })
                      }}
                    />
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
            :
            <p className="text-slate-500 text-[15px]">You don't have any team member.</p>
            }
          </div>
          }
        </div>
      )
    } else if(this.state.mode === 'add-user') {
      return (
        <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4 max-w-xl">
          <h2 className="font-semibold mb-5">Add New User</h2>
          <div className="flex gap-4 mt-4">
            <Field className="field flex-1">
              <Label>Username</Label>
              <Input type="text" />
            </Field>
            <Field className="field flex-1">
              <Label>Email</Label>
              <Input type="email" />
            </Field>
          </div>
          <div className="flex gap-4 mt-4">
            <Field className="field flex-1">
              <Label>First Name</Label>
              <Input type="text" />
            </Field>
            <Field className="field flex-1">
              <Label>Last Name</Label>
              <Input type="text" />
            </Field>
          </div>
          <div className="flex gap-4 mt-4">
            <Field className="field flex-1">
              <Label>Password</Label>
              <div className="relative">
                <Input
                  type={this.state.show_pw ? 'text' : 'password'}
                  className="w-full"
                  autoComplete="new-password"
                />
                <small
                  className="absolute top-3 right-2 cursor-pointer text-slate-500"
                  onClick={() => this.setState({ show_pw: !this.state.show_pw })}
                >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
              </div>
            </Field>
            <Field className="field flex-1">
              <Label>Confirm Password</Label>
              <div className="relative">
                <Input
                  type={this.state.show_pw ? 'text' : 'password'}
                  className="w-full"
                  autoComplete="new-password"
                />
                <small
                  className="absolute top-3 right-2 cursor-pointer text-slate-500"
                  onClick={() => this.setState({ show_pw: !this.state.show_pw })}
                >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
              </div>
            </Field>
          </div>
          <div className="flex justify-between mt-4">
            <Button
              className="btn-primary"
              disabled={this.state.add_working}
              onClick={this.addUser}
            >
              <span className="flex gap-1 justify-center items-center">
                {this.state.add_working && <Spinner />}
                <span>Add User</span>
              </span>
            </Button>
            <Button
              className="btn-tertiary"
              onClick={() => this.setState({ mode: 'list-user' })}
            >Cancel</Button>
          </div>
        </div>
      )
    } else if(this.state.mode === 'edit-user') {
      return (
        <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4 max-w-xl">
          <h2 className="font-semibold mb-5">Add New User</h2>
          <div className="flex gap-4 mt-4">
            <Field className="field flex-1">
              <Label>Username</Label>
              <Input type="text" defaultValue="username1" />
            </Field>
            <Field className="field flex-1">
              <Label>Email</Label>
              <Input type="email" defaultValue="user1@gmail.com" />
            </Field>
          </div>
          <div className="flex gap-4 mt-4">
            <Field className="field flex-1">
              <Label>First Name</Label>
              <Input type="text" defaultValue="FirstName" />
            </Field>
            <Field className="field flex-1">
              <Label>Last Name</Label>
              <Input type="text" defaultValue="LastName" />
            </Field>
          </div>
          <div className="flex gap-4 mt-4">
            <Field className="field flex-1">
              <Label>Password</Label>
              <div className="relative">
                <Input
                  type={this.state.show_pw ? 'text' : 'password'}
                  className="w-full"
                  autoComplete="new-password"
                />
                <small
                  className="absolute top-3 right-2 cursor-pointer text-slate-500"
                  onClick={() => this.setState({ show_pw: !this.state.show_pw })}
                >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
              </div>
            </Field>
            <Field className="field flex-1">
              <Label>Confirm Password</Label>
              <div className="relative">
                <Input
                  type={this.state.show_pw ? 'text' : 'password'}
                  className="w-full"
                  autoComplete="new-password"
                />
                <small
                  className="absolute top-3 right-2 cursor-pointer text-slate-500"
                  onClick={() => this.setState({ show_pw: !this.state.show_pw })}
                >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
              </div>
            </Field>
          </div>
          <div className="flex justify-between mt-4">
            <Button
              className="btn-primary"
              disabled={this.state.edit_working}
              onClick={this.editUser}
            >
              <span className="flex gap-1 justify-center items-center">
                {this.state.edit_working && <Spinner />}
                <span>Save Changes</span>
              </span>
            </Button>
            <Button
              className="btn-tertiary"
              onClick={() => this.setState({ mode: 'list-user' })}
            >Cancel</Button>
          </div>
        </div>
      )
    }
  }
}

const UsersLoading = () => (
  <>
    <div className="bg-slate-100/75 rounded-lg mb-5 px-3 py-5 animate-pulse">
      <div className="bg-slate-300 rounded-lg mb-5 px-2 py-2 w-32 animate-pulse mb-5" />
      <div className="grid grid-cols-3 mb-5 gap-10 content-evenly">
        <div className="h-2 bg-slate-300 rounded" />
        <div className="h-2 bg-slate-300 rounded" />
        <div className="h-2 bg-slate-300 rounded" />
      </div>
      {Array.from(Array(5)).map((item,idx) =>(
      <div key={`skeletion-${idx}`} className="grid grid-cols-8 gap-5 mb-5 h-8 bg-slate-300 rounded"></div>
      ))}
    </div>
  </>
)

export default Users