
export default function SortableHead({ title, field, active_field, active_direction, sort }) {
  const IconAsc = () => <span className="inline-block w-3 h-5 text-slate-400 ml-2">&uarr;</span>
  const IconDesc = () => <span className="inline-block w-3 h-5 text-slate-400 ml-2">&darr;</span>
  
  return (
    <th className="py-2">
      <span
        role="button"
        onClick={sort}
      >{title}</span>
      {field === active_field ?
        (active_direction === 'asc' ? <IconAsc /> : <IconDesc />)
      :
      <span className="inline-block w-3 h-5 ml-2">&nbsp;</span>
      } 
    </th>
  )
}