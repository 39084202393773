import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Select from 'react-select'
import { debounce } from "lodash";
import accountingActions from "../redux/accounting/actions";

const { accountingGLAccountsSearch } = accountingActions;

const callDebounce = debounce((func, data) => func(data), 1000);

class SelectGLAccount extends Component {
  state = {
    query: "",
  }


  searchAccounts = (query) => {
    if(query.length > 1){
      this.props.accountingGLAccountsSearch({q: query})
    }
  }

  generateAccountsOptions = () => {
    const { accounts_search } = this.props;
    if(!accounts_search){ return []; }
    return accounts_search.map(item => {
      item.label = `${item.account_number} - ${item.title}`;
      item.value = item.id;
      return item;
    })
  }

  render() {
    const {
      menuPlacement,
      onChange,
      value,
      accounts_search_working,
      containerClassNames
    } = this.props

    return (
      <Select
        options={this.generateAccountsOptions()}
        menuPlacement={menuPlacement ? menuPlacement : 'bottom'}
        value={value}
        onChange={onChange}
        onInputChange={(e) => callDebounce(this.searchAccounts, e)}
        disabled={accounts_search_working}
        placeholder="Search..."
        noOptionsMessage={() => "Type account number or description"}
        isLoading={accounts_search_working}
        classNames={{
          container: () => containerClassNames,
          menu: () => 'min-w-96',
        }}
      />
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      accounts_search: state.Accounting.get("accounts_search"),
      accounts_search_working: state.Accounting.get("accounts_search_working"),
    }),
    {
      accountingGLAccountsSearch,
    }
  )(SelectGLAccount)
);
