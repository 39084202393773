import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom"
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import Caret from '../components/Caret'
import { digitСonversion } from '../helpers/utils'
import clientsActions from "../redux/clients/actions";

const {
  clientDashboardClientsActivity,
  clientDashboardClientsTasks,
} = clientsActions;

const invoice_tasks = [
  {title: 'Invoice to Enter', count: 5, path: 'enter', key: "invoice_to_enter"},
  {title: 'Invoice to Review', count: 2, path: 'all/TO_REVIEW', key: "TO_REVIEW"},
  {title: 'Invoice to Approve', count: 4, path: 'all/TO_APPROVE', key: "TO_APPROVE"},
  {title: 'Invoice to Pay', count: 3, path: 'all/TO_PAY', key: "TO_PAY"}
]

function toMoneyFormat(value) {
  return value !== null ? digitСonversion(value, 'currency') : 'N/A'
}

class Home extends Component {

  componentDidMount = () => {
    this.props.clientDashboardClientsActivity();
    this.props.clientDashboardClientsTasks();
  }

  dummyDataBalance = () => {
    const { dashboard_clients_activity } = this.props;
    if(!dashboard_clients_activity){ return []; }
    var clients = [...dashboard_clients_activity];
    clients = clients.map(client => {
      client.bank_accounts = [];
      for (let i = 0; i < Math.floor(Math.random() * 5); i++){
        let account = {
          account_id: i + 1,
          account_name: `Account #${i + 1}`,
          prev_balance: Math.random() * 10 * Math.floor(Math.random() * 100000),
          curr_balance: Math.random() * 10 * Math.floor(Math.random() * 100000),
          ledger_balance: Math.random() * 10 * Math.floor(Math.random() * 100000),
        }
        client.bank_accounts.push(account);
      }
      return client;
    })
    return clients;
  }

  render() {
    const {
      dashboard_clients_activity_working,
      //dashboard_clients_activity,
      dashboard_clients_tasks_working,
      dashboard_clients_tasks,
    } = this.props;

    return (
      <>
        <div className="mb-14">
          <h1 className="text-xl font-semibold leading-none mb-5">Tasks</h1>
          {dashboard_clients_tasks_working ?
          <TaskLoading />
          :
          <div className="flex gap-5">
          {invoice_tasks.map(item => (
            <Link key={item.title} to={`/app/client/2/invoices/${item.path}`} className="group no-underline flex-1">
              <div className="flex flex-col py-3 px-4 min-w-48 bg-slate-100/75 rounded-lg">
                <span className="text-slate-600">{item.title}</span>
                <span className="text-3xl">{
                  dashboard_clients_tasks[item.key]
                    ? dashboard_clients_tasks[item.key]
                    : 0
                }</span>
                <span className="text-sm no-underline text-blue-700 group-hover:underline">View</span>
              </div>
            </Link>
          ))}
          </div>
          }
        </div>

        <div>
          <div className="flex gap-2 items-center mb-5">
            <h1 className="text-xl font-semibold leading-none">Balance & Activity</h1>
            <span className="text-slate-500">(Group)</span>
          </div>
          {dashboard_clients_activity_working ?
          <BalanceLoading />
          :
          this.dummyDataBalance()?.map(item => (
          <div key={`client-${item.id}`} className="bg-slate-100/75 rounded-lg mb-5 text-slate-600">
            <Disclosure defaultOpen>
              <DisclosureButton className="group p-3 shadow-none text-left w-full text-lg flex justify-between items-center">
                <span className="text-blue-700">{item?.name}</span>
                <div className="flex items-center gap-3">
                  <span className="text-blue-700">{toMoneyFormat(item?.total_balance || 0)}</span>
                  <Caret className="inline group-data-[open]:rotate-180" />
                </div>
              </DisclosureButton>
              <DisclosurePanel className="px-3 pb-3">
                {item?.bank_accounts?.length > 0 ?
                <table className="w-full text-[15px] table-fixed">
                  <thead className="border-b-2 border-slate-200">
                    <tr>
                      <th className="py-2 text-left">Bank Account</th>
                      <th className="py-2 text-right">Previous Balance</th>
                      <th className="py-2 text-right">Current Balance</th>
                      <th className="py-2 text-right">Ledger Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.bank_accounts.map(account => (
                    <tr key={`client-${item.client_id}-${account.account_id}`} className="border-t border-slate-200">
                      <td>{account.account_name}</td>
                      <td className="py-2 text-right">{toMoneyFormat(account.prev_balance)}</td>
                      <td className="py-2 text-right">{toMoneyFormat(account.curr_balance)}</td>
                      <td className="py-2 text-right">{toMoneyFormat(account.ledger_balance)}</td>
                    </tr>
                    ))}
                  </tbody>
                </table>
                :
                <p className="text-slate-500 text-[15px]">No bank accounts data found.</p>
                }
              </DisclosurePanel>
            </Disclosure>
          </div>
          ))}
        </div>
      </>
    )
  }
}

const TaskLoading = () => (
  <div className="flex gap-5">
    {Array.from(Array(4)).map((item,idx) =>
    <div key={`stat-loading-${idx}`} className="flex-1 flex flex-col gap-3 py-3 px-4 bg-slate-100/75 rounded-lg animate-pulse">
      <div className="h-2 bg-slate-300 rounded w-32" />
      <div className="h-2 bg-slate-300 rounded w-10 h-8" />
      <div className="h-2 bg-slate-300 rounded w-14" />
    </div>
    )}
  </div>
)

const BalanceLoading = () => (
  Array.from(Array(5)).map((item,idx) =>
    <div key={`balance-loading-${idx}`} className="bg-slate-100/75 rounded-lg mb-5 px-3 py-5 animate-pulse">
      <div className="h-2 bg-slate-300 rounded w-32" />
      <div className="grid grid-cols-4 gap-4 mt-5">
        <div className="h-2 bg-slate-300 rounded w-40" />
        <div className="h-2 bg-slate-300 rounded w-40" />
        <div className="h-2 bg-slate-300 rounded w-40" />
        <div className="h-2 bg-slate-300 rounded w-40" />
      </div>
      <div className="grid grid-cols-4 gap-4 mt-5">
        <div className="h-2 bg-slate-300 rounded w-24" />
        <div className="h-2 bg-slate-300 rounded w-24" />
        <div className="h-2 bg-slate-300 rounded w-24" />
        <div className="h-2 bg-slate-300 rounded w-24" />
      </div>
    </div>
  )
)

export default withRouter(
  connect(
    state => ({
      dashboard_clients_activity: state.Clients.get("dashboard_clients_activity"),
      dashboard_clients_activity_working: state.Clients.get("dashboard_clients_activity_working"),
      dashboard_clients_tasks: state.Clients.get("dashboard_clients_tasks"),
      dashboard_clients_tasks_working: state.Clients.get("dashboard_clients_tasks_working"),
    }),
    {
      clientDashboardClientsActivity,
      clientDashboardClientsTasks,
    }
  )(Home)
);
