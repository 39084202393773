import React, { Component } from 'react'
import { digitСonversion, momentDateToISOFormatConversion } from "../../helpers/utils";

class TrialBalanceReport extends Component {

  render() {
    const {
      userData,
      report,
    } = this.props;

    return (
      <div>
        <div className="border rounded px-4 py-8">
          <div className="text-center mb-6">
            <h3 className="text-2xl mb-2">{userData?.active_client?.name}</h3>
            <div>
              <h4 className="font-semibold">Trial Balance</h4>
              <p>Period: {momentDateToISOFormatConversion(report?.start_date, 'MM/DD/YYYY')} - {momentDateToISOFormatConversion(report?.end_date, 'MM/DD/YYYY')}</p>
            </div>
          </div>
          <table className="table-auto w-full">
            <thead className="border-b-2 border-slate-200">
              <tr>
                <th className="p-1">Account Number</th>
                <th className="p-1 text-right">Debit</th>
                <th className="p-1 text-right">Credit</th>
              </tr>
            </thead>
            <tbody>
              {report?.data?.report?.map((item, idx) => (
              <tr key={`${item?.id}-${idx}`} className="border-b-2 border-slate-200">
                <td className="p-1">{item?.account__account_number} - {item?.account__title}</td>
                <td className="p-1 text-right">{item?.account__balance === 1
                  ? digitСonversion(item?.total, "currency")
                  : ""
                }</td>
                <td className="p-1 text-right">{item?.account__balance === 0
                  ? digitСonversion(item?.total, "currency")
                  : ""
                }</td>
              </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td><span className="font-semibold">Total</span></td>
                <td className="text-right">{digitСonversion(report?.data?.total_debit, "currency")}</td>
                <td className="text-right">{digitСonversion(report?.data?.total_credit, "currency")}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    )
  }
}

export default TrialBalanceReport;
