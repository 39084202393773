import { CloseButton, Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import { connect } from "react-redux";
import React, { Component } from 'react'
import Caret from "../../components/Caret"
import { ReactComponent as IconFinancials } from '../../assets/icons/icon-financials.svg'
import { ReactComponent as IconAP } from '../../assets/icons/icon-pay.svg'
import { ReactComponent as IconDeposits } from '../../assets/icons/icon-safe.svg'
import { ReactComponent as IconGeneral } from '../../assets/icons/icon-report.svg'
import { ReactComponent as IconApp } from '../../assets/icons/icon-application.svg'
import reportsActions from "../../redux/reports/actions";
import BalanceSheetReport from "./BalanceSheetReport";
import IncomeStatementReport from "./IncomeStatementReport";
import ChartOfAccountsReport from "./ChartOfAccountsReport";
import ReactToPrint from 'react-to-print';
import { ReactComponent as IconPrint } from '../../assets/icons/icon-print.svg'
import CreateReportForm from "./CreateReportForm";
import Spinner from "../../components/Spinner";
import LedgerReportForm from "./ChildrenForms/LedgerReportForm";
import GeneralLedgerReport from "./GeneralLedgerReport";
import TrialBalanceReport from "./TrialBalanceReport";
import VendorsReport from "./VendorsReport";
import InvoicesReport from "./InvoicesReport";
import JournalsReport from "./JournalsReport";

const {
  reportsReportGet,
} = reportsActions;

const menu_reporting = [
  {
    category: 'Financials',
    icon: <IconFinancials className="size-5" />,
    childs: [
      '+Income Statement',
      '+Balance Sheet',
      'Schedules',
      '+Ledger',
      '+Trial Balance',
      'Multi Client Financials',
      'Liquidity',
      'Cashflow',
      'Cash Transaction',
      '+Chart of Accounts'
    ]
  },
  {
    category: 'Accounts Payable',
    icon: <IconAP className="size-5" />,
    childs: [
      '+Invoices',
      'Invoice History',
      '+Vendors',
      'Stop Pay'
    ]
  },
  {
    category: 'Deposits',
    icon: <IconDeposits className="size-5" />,
    childs: [
      'Deposits',
      'Commissions',
      'Deposit History',
      'AR Aging'
    ]
  },
  {
    category: 'General',
    icon: <IconGeneral className="size-5" />,
    childs: [
      '+Journals',
      'Transfer Funds',
      'Budgets',
      'Report Automation'
    ]
  },
  {
    category: 'Application',
    icon: <IconApp className="size-5" />,
    childs: [
      'Delegation',
      'Client Status',
      'Administrative'
    ]
  }
]

const report_key = {
  "+Balance Sheet": "balance_sheet",
  "+Income Statement": "income_statement",
  "+Chart of Accounts": "chart_of_accounts",
  "+Ledger": "ledger",
  "+Trial Balance": "trial_balance",
  "+Vendors": "vendors",
  "+Invoices": "invoices",
  "+Journals": "journals",
}


class Reporting extends Component {
  state = {
    active_report: '',
    date_span: null,
    ledger_options: null,
  }

  dateSpanSelected = (span) => {
    this.setState({date_span: span})
  }

  ledgerOptionsForRequest = (ledger_options) => {
    const ranges = ledger_options.ranges.map(item => {
      return {
        from: item.range_from.account_number,
        to: item.range_to.account_number,
      }
    })
    return {ranges: ranges}
  }

  onCreateReport = (report) => {
    const {
      date_span,
      ledger_options,
    } = this.state;
    const { userData } = this.props;


    this.props.reportsReportGet({
      client_id: userData?.active_client?.id,
      report: report,
      span: date_span,
      data: ["ledger", "journals"].includes(report)
        ? this.ledgerOptionsForRequest(ledger_options)
        : null,
    });
  }

  setLedgerOptions = (options) => {
    this.setState({ledger_options: options});
  }

  render() {
    const {
      userData,
      report,
      report_working,
      date_span,
    } = this.props;

    return (
      <div>
        <div className="mb-5">
          <h1 className="text-xl font-semibold">Reporting</h1>
          <p className="text-slate-500">Select report from category below</p>
        </div>
        <div className="flex gap-2 mb-8">
        {menu_reporting.map(menu => (
          <Popover className="relative" key={`category-${menu.category}`}>
            <PopoverButton className="border border-slate-200 rounded group shadow-none hover:shadow hover:border-slate-300">
              <div className="flex items-center gap-2">
                <span className="size-5 text-slate-500 group-data-[open]:text-blue-700">
                  {menu.icon}
                </span>
                <span className="group-data-[open]:text-blue-700">{menu.category}</span>
                <Caret />
              </div>
            </PopoverButton>
            <PopoverPanel
              anchor={{
                to: 'bottom start',
                gap: 8
              }}
              className="grid grid-cols-2 gap-3 gap-x-6 gap-y-3 bg-white border shadow rounded px-4 py-2"
            >
            {menu.childs.map(title => (
              <CloseButton
                key={title}
                as="span"
                role="button"
                className="text-nowrap text-sm hover:text-blue-700 data-[active]:text-blue-700"
                data-active={this.state.active_report === title ? true : null}
                onClick={() => {
                  this.setState({ active_report: title });
                  if(!["ledger", "journals"].includes(report_key[title])){
                    this.props.reportsReportGet({
                      client_id: userData?.active_client?.id,
                      report: report_key[title],
                      span: date_span,
                    });
                  }
                }}
                disabled={report_working}
              >
                {title}
              </CloseButton>
            ))}
            </PopoverPanel>
          </Popover>
        ))}
        </div>
        {this.state.active_report === '+Income Statement' &&
        <div>
          <h2 className="font-semibold text-lg mb-3">Income Statement</h2>
          <CreateReportForm
            onCreateReport={this.onCreateReport}
            userData={userData}
            dateSpanSelected={this.dateSpanSelected}
            report_type={"income_statement"}
          />
          <div className="mt-12">
            {report_working ?
            <div><Spinner /> Generating report...</div>
            :
            <>
              <div className="flex justify-end mb-2">
                <ReactToPrint
                  trigger={() => {return <IconPrint role="button" className="size-4" />}}
                  content={() => this.componentRef}
                />
              </div>
              <IncomeStatementReport
                report={report}
                report_working={report_working}
                userData={userData}
                ref={el => (this.componentRef = el)}
                date_span={date_span}
              />
            </>
            }
          </div>
        </div>
        }
        {this.state.active_report === '+Balance Sheet' &&
          <div>
            <h2 className="font-semibold text-lg mb-3">Balance Sheet</h2>
            <CreateReportForm
              onCreateReport={this.onCreateReport}
              userData={userData}
              dateSpanSelected={this.dateSpanSelected}
              report_type={"balance_sheet"}
            />
            <div className="mt-12">
              {report_working ?
              <div><Spinner /> Generating report...</div>
              :
              <>
                <div className="flex justify-end mb-2">
                  <ReactToPrint
                    trigger={() => {return <IconPrint role="button" className="size-4" />}}
                    content={() => this.componentRef}
                  />
                </div>
                <BalanceSheetReport
                  report={report}
                  report_working={report_working}
                  userData={userData}
                  ref={el => (this.componentRef = el)}
                  date_span={date_span}
                />
              </>
              }
            </div>
          </div>
        }
        {this.state.active_report === '+Chart of Accounts' &&
        <div>
          <h2 className="font-semibold text-lg mb-3">Chart of Accounts</h2>
          <div className="mt-12">
            {report_working ?
            <div><Spinner /> Generating report...</div>
            :
            <>
              <div className="flex justify-end mb-2">
                <ReactToPrint
                  trigger={() => {return <IconPrint role="button" className="size-4" />}}
                  content={() => this.componentRef}
                />
              </div>
              <ChartOfAccountsReport
                report={report}
                report_working={report_working}
                userData={userData}
                ref={el => (this.componentRef = el)}
                date_span={date_span}
              />
            </>
            }
          </div>
        </div>
        }
        {this.state.active_report === '+Trial Balance' &&
        <div>
          <h2 className="font-semibold text-lg mb-3">Trial Balance</h2>
          <CreateReportForm
            onCreateReport={this.onCreateReport}
            userData={userData}
            dateSpanSelected={this.dateSpanSelected}
            report_type={"trial_balance"}
          />
          <div className="mt-12">
            {report_working ?
            <div><Spinner /> Generating report...</div>
            :
            <>
              <div className="flex justify-end mb-2">
                <ReactToPrint
                  trigger={() => {return <IconPrint role="button" className="size-4" />}}
                  content={() => this.componentRef}
                />
              </div>
              <TrialBalanceReport
                report={report}
                report_working={report_working}
                userData={userData}
                ref={el => (this.componentRef = el)}
                date_span={date_span}
              />
            </>
            }
          </div>
        </div>
        }
        {this.state.active_report === '+Ledger' &&
        <div>
          <h2 className="font-semibold text-lg mb-3">Ledger Report</h2>
          <CreateReportForm
            onCreateReport={this.onCreateReport}
            userData={userData}
            dateSpanSelected={this.dateSpanSelected}
            report_type={"ledger"}
          >
            <LedgerReportForm
              setOptions={this.setLedgerOptions}
            />
          </CreateReportForm>
          <div className="mt-12">
            {report_working ?
              <div><Spinner /> Working...</div>
              :
              <>
                {report?.data?.accounts
                  ? <>
                    <div className="flex justify-end mb-2">
                      <ReactToPrint
                        trigger={() => {return <IconPrint role="button" className="size-4" />}}
                        content={() => this.componentRef}
                      />
                    </div>
                    <GeneralLedgerReport
                      report={report}
                      report_working={report_working}
                      userData={userData}
                      ref={el => (this.componentRef = el)}
                      date_span={date_span}
                    />
                    </>
                  : null
                }
              </>
            }
          </div>
        </div>
        }
        {this.state.active_report === '+Vendors' &&
        <div>
          <div className="mt-12">
            {report_working ?
              <div><Spinner /> Working...</div>
              :
              <>
                {report?.data
                  ? <>
                    <div className="flex justify-end mb-2">
                      <ReactToPrint
                        trigger={() => {return <IconPrint role="button" className="size-4" />}}
                        content={() => this.componentRef}
                      />
                    </div>
                    <VendorsReport
                      report={report}
                      report_working={report_working}
                      userData={userData}
                      ref={el => (this.componentRef = el)}
                      date_span={date_span}
                    />
                    </>
                  : null
                }
              </>
            }
          </div>
        </div>
        }
        {this.state.active_report === '+Invoices' &&
        <div>
          <CreateReportForm
            onCreateReport={this.onCreateReport}
            userData={userData}
            dateSpanSelected={this.dateSpanSelected}
            report_type={"invoices"}
          />
          <div className="mt-12">
            {report_working ?
              <div><Spinner /> Working...</div>
              :
              <>
                {report?.data
                  ? <>
                    <div className="flex justify-end mb-2">
                      <ReactToPrint
                        trigger={() => {return <IconPrint role="button" className="size-4" />}}
                        content={() => this.componentRef}
                      />
                    </div>
                    <InvoicesReport
                      report={report}
                      report_working={report_working}
                      userData={userData}
                      ref={el => (this.componentRef = el)}
                      date_span={date_span}
                    />
                    </>
                  : null
                }
              </>
            }
          </div>
        </div>
        }
        {this.state.active_report === '+Journals' &&
        <div>
          <h2 className="font-semibold text-lg mb-3">Journals</h2>
          <CreateReportForm
            onCreateReport={this.onCreateReport}
            userData={userData}
            dateSpanSelected={this.dateSpanSelected}
            report_type={"journals"}
          >
            <LedgerReportForm
              setOptions={this.setLedgerOptions}
            />
          </CreateReportForm>
          <div className="mt-12">
            {report_working ?
              <div><Spinner /> Working...</div>
              :
              <>
                {report?.data
                  ? <>
                    <div className="flex justify-end mb-2">
                      <ReactToPrint
                        trigger={() => {return <IconPrint role="button" className="size-4" />}}
                        content={() => this.componentRef}
                      />
                    </div>
                    <JournalsReport
                      report={report}
                      report_working={report_working}
                      userData={userData}
                      ref={el => (this.componentRef = el)}
                      date_span={date_span}
                    />
                    </>
                  : null
                }
              </>
            }
          </div>
        </div>
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    report: state.Reports.get("report"),
    report_working: state.Reports.get("report_working"),
  }),
  {
    reportsReportGet,
  }
)(Reporting);
