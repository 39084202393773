import React, { Component } from 'react'
import { ReactComponent as Logo } from '../assets/images/event-ledger-logo.svg'
import { Button, Field, Input, Label } from "@headlessui/react"
import Spinner from "../components/Spinner"

class ForgotPassword extends Component {
  state = {
    email_sent: false,
    reset_loading: false
  }

  sendResetEmail = () => {
    this.setState({ reset_loading: true })
    setTimeout(() => this.setState({
      reset_loading: false,
      email_sent: true
    }), 1500)
  }

  render() {
    return (
      <div className="container mx-auto flex justify-center gap-8">
        <div className="flex flex-col gap-3 py-60 w-80 h-screen">
          <div className="w-[150px] h-[48px] mb-2 mx-auto">
            <Logo />
          </div>
          <h2 className="font-semibold text-2xl mb-3 text-center">Reset your password</h2>
          {!this.state.email_sent ?
          <>
            <p>Please provide your email address associated with your account.</p>
            <Field className="field">
              <Label>Your email</Label>
              <Input type="email" placeholder="youremail@domain.com"/>
            </Field>
            <Button
              className="btn-primary"
              onClick={this.sendResetEmail}
              disabled={this.state.reset_loading}
            >
              <span className="flex gap-1 justify-center items-center">
                {this.state.reset_loading && <Spinner />}
                <span>Reset Password</span>
              </span>
            </Button>
          </>
          :
          <>
            <p>
              An email to reset your password has been sent to your inbox. Please check spam folder if you didn't find it.
            </p>
            <p><span className="text-slate-500">Didn't receive the email?</span> <span className="link">Resend email</span></p>
          </>
          }
        </div>
      </div>
    )
  }
}

export default ForgotPassword