import React, { Component } from 'react'
import { digitСonversion, momentDateToISOFormatConversion } from "../../helpers/utils";

class JournalsReport extends Component {

  render() {
    const {
      userData,
      report,
    } = this.props;

    return (
      <div>
        <div className="border rounded px-4 py-8">
          <div className="text-center mb-6">
            <h3 className="text-2xl mb-2">{userData?.active_client?.name}</h3>
            <div>
              <h4 className="font-semibold">Journals</h4>
              <p>Period: {momentDateToISOFormatConversion(report?.start_date, 'MM/DD/YYYY')} - {momentDateToISOFormatConversion(report?.end_date, 'MM/DD/YYYY')}</p>
            </div>
          </div>
          <table className="table-auto w-full">
            <thead className="border-b-2 border-slate-200">
              <tr>
                <th className="p-1">#</th>
                <th className="p-1">Created</th>
                <th className="p-1">Date</th>
                <th className="p-1">Account</th>
                <th className="p-1">Description</th>
                <th className="p-1">Debit</th>
                <th className="p-1">Credit</th>
              </tr>
            </thead>
            <tbody>
              {report?.data?.map((item, idx) => (
              <tr
                key={`${item?.id}-${idx}`}
                className="border-b-2 border-slate-200"
              >
                <td className="p-1">{idx}</td>
                <td className="p-1">{momentDateToISOFormatConversion(item?.created_at, "MM/DD/YYYY")}</td>
                <td className="p-1">{momentDateToISOFormatConversion(item?.date, "MM/DD/YYYY")}</td>
                <td className="p-1">{item?.account?.account_number} - {item?.account?.title}</td>
                <td className="p-1">{item?.description}</td>
                <td className="p-1">{digitСonversion(item?.debit, "currency")}</td>
                <td className="p-1">{digitСonversion(item?.credit,"currency")}</td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default JournalsReport;
