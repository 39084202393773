import React, { Component } from 'react'
import Select from 'react-select'
import SelectGLAccount from "../../components/SelectGLAccount"
import { Button, Input } from "@headlessui/react"
import CurrencyInput from "react-currency-input-field"
import Select1099Box from "../../components/Select1099Box"
import { ReactComponent as IconTrash } from '../../assets/icons/icon-trash.svg'

const property_options = [
  { value: 1, label: 'Beverly Hills' },
  { value: 2, label: 'Newport Beach' }
]

class InvoiceGLTable extends Component {

  checkDataValid = () => {
    const { data } = this.props;
    var valid = true;
    data.forEach(item => {
      if(valid){
        if(!item.client){ valid = false; };
        if(!item.account){ valid = false; };
        if(!item.description){ valid = false; };
        if(!parseFloat(item.debit) && !parseFloat(item.credit)){ valid = false; };
        if(parseFloat(item.debit) && parseFloat(item.credit)) { valid = false; };
      }
    })
    return valid;
  }

  displayNetData = () => {
    const { data } = this.props;
    var net = {debit: 0, credit: 0};
    data.forEach(item => {
      net.debit = parseFloat(net.debit) + (parseFloat(item?.debit) || 0);
      net.credit = parseFloat(net.credit) + (parseFloat(item?.credit) || 0);
    })
    return (
      <div className="flex flex-row gap-8 font-semibold">
        <span>Total Debit: ${net.debit}</span>
        <span>Total Credit: ${net.credit}</span>
        {/*
        <span>Nett: ${net.credit + net.debit}</span>
        */}
      </div>
    )
  }

  render() {
    const {
      data,
      addRow,
      deleteRow,
    } = this.props

    return (
      <table className="w-full text-[15px] table-auto">
        <thead className="border-b-2 border-slate-200">
          <tr>
            <th className="p-1">#</th>
            <th className="p-1">Account</th>
            <th className="p-1">Property</th>
            <th className="p-1">Description</th>
            <th className="p-1">Debit Amount</th>
            <th className="p-1">Credit Amount</th>
            <th className="p-1">1099 Box</th>
            <th className="p-1">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 && data.map((item, idx) => (
          <tr key={`row-${idx}`} id={`id-${item.id}`}>
            <td className="px-1 py-2 max-w-6">{idx+1}.</td>
            <td className="px-1 py-2">
              <div className="w-44">
                <SelectGLAccount
                  menuPlacement="top"
                  value={item.account}
                  onChange={(e) => this.props.updateRowData(item.id, "account", e)}
                />
              </div>
            </td>
            <td className="px-1 py-2">
              <div className="w-32">
                <Select
                  options={property_options}
                  menuPlacement="top"
                  isDisabled={true}
                />
              </div>
            </td>
            <td className="px-1 py-2">
              <Input
                type="text"
                value={item.description}
                onChange={(e) => this.props.updateRowData(item.id, "description", e.target.value)}
              />
            </td>
            <td className="px-1 py-2">
              <CurrencyInput
                prefix="$"
                className="max-w-32"
                value={item?.debit}
                onValueChange={value => this.props.updateRowData(item.id, "debit", value)}
                decimalsLimit={2}
              />
            </td>
            <td className="px-1 py-2">
              <CurrencyInput
                prefix="$"
                className="max-w-32"
                value={item?.credit}
                onValueChange={value => this.props.updateRowData(item.id, "credit", value)}
                step={1}
              />
            </td>
            <td className="px-1 py-2">
              <div className="w-72">
                <Select1099Box
                  value={item?.form_1099 ? item?.form_1099 : null}
                  onChange={e => this.props.updateRowData(item.id, "form_1099", e)}
                />
              </div>
            </td>
            <td className="px-1 py-2" align="center">
              <span
                role="button"
                onClick={() => deleteRow(item.id)}
              >
                <IconTrash className="text-red-500 size-4" />
              </span>
            </td>
          </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={8}>
              <div className="flex justify-between pt-4">
                {this.displayNetData()}
                <Button
                  className="btn-tertiary btn-sm"
                  onClick={addRow}
                  disabled={data.length > 0 && !this.checkDataValid()}
                >+ Add</Button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    )
  }
}

export default InvoiceGLTable
