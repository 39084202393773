import { Checkbox, Field, Input, Label, Radio, RadioGroup, Switch } from "@headlessui/react"
import React, { Component } from 'react'
import MonthDatePicker from "../../components/MonthDatePicker"
import DateNumberPicker from "../../components/DateNumberPicker"
import DatePicker from "react-datepicker"
import CurrencyInput from "react-currency-input-field"
import Select from 'react-select'

const recurring_period = [
  {value: 'WEEK', label: 'Weekly'},
  {value: 'MONTH', label: 'Monthly'},
  {value: 'YEAR', label: 'Yearly'}
]

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const amount_types = [
  {value: 'ALWAYS_DIFFERENT', label: 'Always different'},
  {value: 'ALWAYS_SAME', label: 'Always the same'},
]

const last_invoice_options = [
  {value: 'recurring_end_date_infinite', label: 'No end date'},
  {value: 'recurring_end_date', label: 'Until date'},
  {value: 'recurring_end_amount', label: 'Until amount'},
  {value: 'recurring_end_occurrences', label: 'Occurrences'},
]

const image_replacement_options = [
  {value: 'OPTIONAL', label: 'Optional'},
  {value: 'MANDATORY', label: 'Mandatory'},
  {value: 'SAME', label: 'Always use the same image'},
]

class RecurringInvoiceSetup extends Component {
  state = {
    every_month_and: false,
    every_year_and: false,
  }

  lastInvoiceDisplayValue = () => {
    const { data } = this.props;
    if(data?.recurring_end_date !== undefined){
      return last_invoice_options.find(item => item.value === "recurring_end_date");
    } else if(data?.recurring_end_amount !== undefined){
      return last_invoice_options.find(item => item.value === "recurring_end_amount");
    } else if (data?.recurring_end_occurrences !== undefined){
      return last_invoice_options.find(item => item.value === "recurring_end_occurrences");
    } else if(data?.recurring_end_date_infinite !== undefined){
      return last_invoice_options.find(item => item.value === "recurring_end_date_infinite");
    } else {
      return null
    }
  }

  listInvoiceOnChange = (selected) => {
    last_invoice_options.forEach(item => {
      if(item.value === selected.value){
        this.props.updateRecurring(selected.value, selected);
      } else {
        this.props.updateRecurring(item.value, undefined);
      }
    })
  }

  listInvoiceValueOnChange = (key, value) => {
    const { data } = this.props;
    if(data[key]){
      var item = {...data[key]};
      item.data_value = value;
       this.props.updateRecurring(key, item);
    }
  }

  updateDays = (day) => {
    const { data } = this.props;
    var days = data?.days;
    if(days){
      days = days.split(",");
      if(days.includes(day)){
        days = days.filter(item => item !== day);
        this.props.updateRecurring("days", days.join(','));
      } else {
        days.push(day)
        this.props.updateRecurring("days", days.join(','));
      };
    } else {
      this.props.updateRecurring("days", day);
    }
  }

  updateDates = (position, date) => {
    const { data } = this.props;
    var dates = data?.dates;
    if(!dates){
      dates = [null, null];
    }
    dates[position] = date;
    this.props.updateRecurring("dates", dates);
  }

  render() {
    const { data } = this.props;

    return (
      <div>
        <div className="grid grid-cols-2">
          <div>
            <Field className="field">
              <Label>Invoice Template Name</Label>
              <Input
                type="text"
                value={data?.template_name || ""}
                onChange={(e) => this.props.updateRecurring("template_name", e.target.value)}
              />
            </Field>
            <Field className="field mt-4">
              <Label>Recurring Period</Label>
              <RadioGroup
                value={data?.period || null}
                onChange={selected => {
                  this.props.updateRecurring('period', selected);
                  this.props.updateRecurring('every', 0);
                  this.props.updateRecurring('days', null);
                  this.props.updateRecurring('dates', null);
                }}
                className="flex gap-8"
              >
                {recurring_period.map(item =>
                  <Field
                    key={`period-${item.value}`}
                    className="flex items-center gap-2"
                  >
                    <Radio className="radio" value={item} />
                    <Label>{item.label}</Label>
                  </Field>
                )}
              </RadioGroup>
            </Field>
            {data?.period?.value === 'WEEK' &&
            <Field className="flex items-center gap-2 mt-4 text-slate-500">
              Every <Input
                      type="number"
                      className="w-14"
                      min={1}
                      value={data?.every ? data?.every : 0}
                      onChange={(e) => this.props.updateRecurring("every", e.target.value)}
                    /> week(s) on:
              {days.map(day =>
                <Field key={`days-${day}`} className="flex items-center gap-1 ml-3">
                  <Checkbox
                    className="checkbox"
                    onClick={() => this.updateDays(day)}
                    checked={data?.days?.includes(day) ? true : false}
                  />
                  <Label>{day}</Label>
                </Field>
              )}
            </Field>
            }
            {data?.period?.value === 'MONTH' &&
            <Field className="flex items-center gap-2 mt-4 text-slate-500">
              Every <Input
                      type="number"
                      className="w-14"
                      min={1}
                      value={data?.every ? data?.every : 0}
                      onChange={(e) => this.props.updateRecurring("every", e.target.value)}
                    /> month(s) on the:
              <DateNumberPicker
                value={data?.dates?.length > 1 ? data?.dates[0] : null}
                onChange={value => this.updateDates(0, value)}
              /> day
              <Field className="flex items-center gap-2 ml-2">
                <Checkbox
                  className="checkbox"
                  checked={this.state.every_month_and}
                  onChange={() => {
                    this.setState({ every_month_and: !this.state.every_month_and });
                    if(this.state.every_month_and){
                      this.updateDates(1, null);
                    }
                  }}
                />
                <Label>AND</Label>
              </Field>
              {this.state.every_month_and &&
              <>
              <DateNumberPicker
                value={data?.dates?.length > 1 ? data?.dates[1] : null}
                onChange={value => this.updateDates(1, value)}
              /> day
              </>
              }
            </Field>
            }
            {data?.period?.value === 'YEAR' &&
            <Field className="flex items-center gap-2 mt-4 text-slate-500">
              Every <Input
                      type="number"
                      className="w-14"
                      min={1}
                      defaultValue={1}
                      value={data?.every ? data?.every : 0}
                      onChange={(e) => this.props.updateRecurring("every", e.target.value)}
                    /> years(s) on:
              <MonthDatePicker
                value={data?.dates?.length > 1 ? data?.dates[0] : null}
                onChange={value => this.updateDates(0, value)}
              />
              <Field className="flex items-center gap-2 ml-2">
                <Checkbox
                  className="checkbox"
                  checked={this.state.every_year_and}
                  onChange={() => {
                    this.setState({ every_year_and: !this.state.every_year_and })
                    if(this.state.every_year_and){
                      this.updateDates(1, null);
                    }
                  }}
                />
                <Label>AND</Label>
              </Field>
              {this.state.every_year_and &&
              <MonthDatePicker
                value={data?.dates?.length > 1 ? (data?.dates[1] || null) : null}
                onChange={value => this.updateDates(1, value)}
              />
              }
            </Field>
            }
          </div>
          <div className="pl-8">
            <Field className="flex items-center gap-2 text-slate-500">
              <Label className="min-w-24">Due date:</Label>
              <Input
                type="number"
                min={0}
                className="w-24"
                value={data?.due_date_offset ? data?.due_date_offset : 0}
                onChange={(e) => this.props.updateRecurring("due_date_offset", e.target.value)}
              /> days after invoice date
            </Field>
            <Field className="flex items-center gap-2 mt-4 text-slate-500">
              <Label className="min-w-24">Check date:</Label>
              <Input
                type="number"
                min={0}
                className="w-24"
                value={data?.check_date_offset ? data?.check_date_offset : 0}
                onChange={(e) => this.props.updateRecurring("check_date_offset", e.target.value)}
              /> days after invoice date
            </Field>
            <Field className="flex items-center gap-2 mt-4">
              <Label>Last Invoice:</Label>
              <Select
                isSearchable={false}
                options={last_invoice_options}
                value={this.lastInvoiceDisplayValue()}
                onChange={selected => this.listInvoiceOnChange(selected)}
              />
              {data?.recurring_end_date !== undefined &&
                <DatePicker
                  placeholderText="Select date"
                  onChange={(e) => this.listInvoiceValueOnChange("recurring_end_date", e)}
                  selected={data?.recurring_end_date ? data?.recurring_end_date?.data_value : null}
                />
              }
              {data?.recurring_end_amount !== undefined &&
                <CurrencyInput
                  prefix="$"
                  placeholder="Provide max amount"
                  value={data?.recurring_end_amount ? data?.recurring_end_amount?.data_value : ""}
                  onValueChange={(value) => this.listInvoiceValueOnChange("recurring_end_amount", value)}
                />
              }
              {data?.recurring_end_occurrences !== undefined &&
                <span className="text-slate-500">
                  <Input
                    type="number"
                    min={1}
                    className="w-24"
                    value={data?.recurring_end_occurrences ? data?.recurring_end_occurrences?.data_value : 0}
                    onChange={(e) => this.listInvoiceValueOnChange("recurring_end_occurrences", e.target.value)}
                  /> times
                </span>
              }
            </Field>
            {/*
            <Field className="flex items-center gap-1 mt-4">
              <Checkbox
                className="checkbox"
              />
              <Label>Generate first invoice</Label>
            </Field>
            */}
          </div>
        </div>
        <hr className="my-6" />
        <div className="grid grid-cols-2">
          <div>
            <Field className="field">
              <Label>Invoice Amount Type</Label>
              <RadioGroup
                value={data?.amount_type ? data?.amount_type : null}
                onChange={selected => {
                  this.props.updateRecurring('amount_type', selected);
                  this.props.updateRecurring('amount', 0);
                }}
                className="flex gap-8"
              >
                {amount_types.map(item =>
                  <Field key={item.value} className="flex items-center gap-2">
                    <Radio className="radio" value={item} />
                    <Label>{item.label}</Label>
                  </Field>
                )}
              </RadioGroup>
            </Field>
            {data?.amount_type?.value === 'ALWAYS_DIFFERENT' ?
            <Field className="field mt-4">
              <Label>Estimate Amount</Label>
              <CurrencyInput
                prefix="$"
                className="max-w-40"
                value={data?.amount ? data?.amount : 0}
                onValueChange={(value) => this.props.updateRecurring("amount", value)}
              />
            </Field>
            :
            <Field className="field mt-4">
              <Label>Amount</Label>
              <CurrencyInput
                prefix="$"
                className="max-w-40"
                value={data?.amount ? data?.amount : 0}
                onValueChange={(value) => this.props.updateRecurring("amount", value)}
              />
            </Field>
            }
          </div>
          <div className="pl-4">
            <Field className="field">
              <Label>Image Requirement</Label>
              <Select
                className="max-w-72"
                isSearchable={false}
                options={image_replacement_options}
                value={data?.image_requirement ? data?.image_requirement : null}
                onChange={selected => this.props.updateRecurring('image_requirement', selected)}
              />
            </Field>
            <Field className="field mt-4">
              <Label>Recurring Status</Label>
              <Field>
                <Label className="flex items-center gap-2">
                  <Switch
                    checked={data?.is_active ? data?.is_active : false}
                    onChange={() => this.props.updateRecurring('is_active', !data?.is_active)}
                  />
                  <span>{data?.is_active ? 'Active' : 'Inactive'}</span>
                </Label>
              </Field>
            </Field>
          </div>
        </div>
      </div>
    )
  }
}

export default RecurringInvoiceSetup
