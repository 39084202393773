import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  withRouter,
  Router,
  Route,
  Switch,
  Redirect
} from  "react-router-dom";
import { appConfig } from './config/app';
import authActions from './redux/auth/actions';
import userActions from './redux/user/actions';

import Login from "./views/Login";
import Spinner from "./components/Spinner";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import Register from "./views/Register";
import UILibrary from "./views/UILibrary";
import TopBar from "./components/TopBar";
import MainNav from "./components/MainNav";
import Home from "./views/Home";
import Settings from "./views/Settings";
import Invoices from "./views/Invoices";
import EnterInvoice from "./views/Invoices/EnterInvoice";
import UploadedDocuments from "./views/Invoices/UploadedDocuments";
import Upload from "./views/Invoices/Upload";
import SelectClient from "./views/SelectClient";
import AddClient from "./views/BM/ClientGroupSettings/AddClient";
import Vendors from "./views/Vendors";
import ViewInvoice from "./views/Invoices/ViewInvoice";
import ClientGroupSettings from "./views/BM/ClientGroupSettings";
import Reporting from "./views/Reporting";
import BMDashboard from "./views/BM/BMDashboard";
import InvoiceTemplates from "./views/Invoices/InvoiceTemplates";
import GLAccounts from "./views/Accounting/GLAccounts";
import ViewInvoiceTemplate from "./views/Invoices/ViewInvoiceTemplate";

const { userRefreshToken } = authActions;
const { userGetData } = userActions;

const RouteUser = ({ component: Component, isLoggedIn, hasNav = true ,...rest }) => {
  return (
    <Route
    {...rest}
    render={matchProps => (
      isLoggedIn ? (
        <TemplateUser hasNav={hasNav}>
          <Component {...matchProps} />
        </TemplateUser>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: matchProps.location }
          }}
        />
      )
    )}
    />
  )
}

class TemplateUser extends Component {
  render() {
    const {
      children,
      userData,
      hasNav
    } = this.props;

    if(userData?.username) {
      return (
        <div className="app">
          <TopBar />
          <div className="2xl:container 2xl:mx-auto">
            <div className="flex">
              {hasNav &&
              <div className="w-48 px-4 py-8 border-r border-r-2 border-slate-100">
                <MainNav userData={userData}/>
              </div>
              }
              <main className="px-4 py-8 flex-1 pl-6">
                {children}
              </main>
            </div>
          </div>
        </div>
      )
    } else {
      this.props.userGetData();
      return (
        <div className="h-screen flex justify-center items-center">
          <div className="flex gap-2">
            <Spinner fillColorClass="fill-slate-500" />
            <span>Loading the app...</span>
          </div>
        </div>
      )
    }
  }
}

TemplateUser = withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
    }),
    {
       userGetData,
    }
  )(TemplateUser)
);

class AppPrivateRoutes extends Component {
  componentDidMount(){
    setInterval(() => {
      this.props.userRefreshToken();},
      appConfig.auth.refreshTokenFrequency
    );
  }

  componentDidUpdate(prevProps) {
  }

  render(){
    return (this.props.children);
  }
}

AppPrivateRoutes = withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
    }),
    {
      userRefreshToken,
    }
  )(AppPrivateRoutes)
);

class AppRoutes extends Component {
  render() {
    const {
      history,
      isLoggedIn
    } = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/ui-library" component={UILibrary} />
          <Route path="/app/">
            <AppPrivateRoutes location={this.props.location}>
              <RouteUser
                exact path="/app/client/:client_id/home"
                component={Home}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/bm/client-group-settings"
                component={ClientGroupSettings}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/client/:client_id/invoices/all"
                component={Invoices}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/client/:client_id/invoices/all/:status"
                component={Invoices}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/client/:client_id/invoices/enter"
                component={UploadedDocuments}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/client/:client_id/invoices/enter/document/:doc_id"
                component={EnterInvoice}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/client/:client_id/invoices/upload"
                component={Upload}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/select-client"
                component={SelectClient}
                isLoggedIn={isLoggedIn}
                hasNav={false}
              />
              <RouteUser
                exact path="/app/bm/add-client"
                component={AddClient}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/settings"
                component={Settings}
                isLoggedIn={isLoggedIn}
                hasNav={false}
              />
              <RouteUser
                exact path="/app/client/:client_id/vendors"
                component={Vendors}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/client/:client_id/invoices/view/:id"
                component={ViewInvoice}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/client/:client_id/reporting"
                component={Reporting}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/client/:client_id/invoices/templates"
                component={InvoiceTemplates}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/client/:client_id/invoices/templates/edit-template/:id"
                component={ViewInvoiceTemplate}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/client/:client_id/accounting/gl-accounts"
                component={GLAccounts}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/bm/dashboard"
                component={BMDashboard}
                isLoggedIn={{isLoggedIn}}
              />
            </AppPrivateRoutes>
          </Route>
          <Redirect from="/" to="/login" />
        </Switch>
      </Router>
    )
  }
}

  export default connect(
    state => ({
      isLoggedIn: state.Auth.get("access_token") !== null,
    }),
    {
      userRefreshToken,
    }
  )(AppRoutes);
