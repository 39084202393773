import React, { Component } from 'react'
import { digitСonversion, momentDateToISOFormatConversion } from "../../helpers/utils";

class BalanceSheetReport extends Component {

  render() {
    const {
      userData,
      report,
    } = this.props;
    console.log("REPORT", report)


    return (
      <div>
        <div className="border rounded px-4 py-8">
          <div className="text-center mb-6">
            <h3 className="text-2xl mb-2">{userData?.active_client?.name}</h3>
            <div>
              <h4 className="font-semibold">Balance Sheet</h4>
              <p>Period: {momentDateToISOFormatConversion(report?.start_date, 'MM/DD/YYYY')} - {momentDateToISOFormatConversion(report?.end_date, 'MM/DD/YYYY')}</p>
            </div>
          </div>
          <div>
            <h5 className="text-lg uppercase">Assets</h5>
            <div>
              {report?.data[0]?.data.map((item, idx) => (
              <div className="flex justify-between hover:bg-slate-50" key={`${item?.account__title}-${idx}`}>
                <span>{item?.account__title}</span>
                <span className="text-right">
                  {
                    item?.debit < 0
                      ? `(${digitСonversion(Math.abs(item?.debit), "currency")})`
                      : digitСonversion(item?.debit, "currency")
                  }
                </span>
              </div>
              ))}
              <div className="flex justify-between font-semibold hover:bg-slate-50">
                <span className="uppercase">Total assets</span>
                <span className="text-right">{digitСonversion(report?.data[0]?.total, "currency")}</span>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <h5 className="text-lg uppercase">Liabilities</h5>
            <div>
              {report?.data[1]?.data.map((item,idx) => (
              <div className="flex justify-between hover:bg-slate-50" key={`${item?.account__title}-${idx}`}>
                <span>{item?.account__title}</span>
                <span className="text-right">
                  {
                    item?.credit < 0
                      ? `(${digitСonversion(Math.abs(item?.credit), "currency")})`
                      : digitСonversion(item?.credit, "currency")
                  }
                </span>
              </div>
              ))}
              <div className="flex justify-between font-semibold hover:bg-slate-50">
                <span className="uppercase">Total liabilities</span>
                <span className="text-right">{digitСonversion(report?.data[1]?.total, "currency")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BalanceSheetReport;
