import { all, fork } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* vendorsGetAllForClient() {
  yield commonSaga(
    actions.VENDORS_GET_ALL_FOR_CLIENT,
    apiCalls.get,
    apiEndpoints.vendors.vendors,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* vendorsAddVendor() {
  yield commonSaga(
    actions.VENDORS_ADD_VENDOR,
    apiCalls.post,
    apiEndpoints.vendors.vendor,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* vendorsUpdateVendor() {
  yield commonSaga(
    actions.VENDORS_UPDATE_VENDOR,
    apiCalls.put,
    apiEndpoints.vendors.vendor,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* vendorsAutofillSearch() {
  yield commonSaga(
    actions.VENDORS_AUTOFILL_SEARCH,
    apiCalls.post,
    apiEndpoints.vendors.vendors_autofill,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export default function* vendorsSaga() {
	yield all([
		fork(vendorsGetAllForClient),
		fork(vendorsAddVendor),
		fork(vendorsUpdateVendor),
		fork(vendorsAutofillSearch),
	]);
}
