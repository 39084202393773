import moment from "moment-timezone"
import React, { Component } from 'react'
import DatePicker from "react-datepicker"
import Select from 'react-select'

const period_options = [
  {value: 'Custom', label: 'Custom'},
  {value: 'Today', label: 'Today'},
  {value: 'This_Week', label: 'This Week'},
  {value: 'This_Month', label: 'This Month'},
  {value: 'This_Quarter', label: 'This Quarter'},
  {value: 'This_Year', label: 'This Year'},
]

class ReportPeriodSelector extends Component {
  state = {
    selectedPeriod: null,
    dateFrom: null,
    dateTo: null,
    dateAsOf: null
  }

  periodChanged = (selectedPeriod, customDates) => {
    let dateFrom = null
    let dateTo = null
    let dateAsOf = null
    
    if(selectedPeriod.value !== 'Today' && selectedPeriod.value !== 'Custom') {
      const unitMap = {
        'This_Week'    : 'isoweek',
        'This_Month'   : 'month',
        'This_Quarter' : 'quarter',
        'This_Year'    : 'year',
      }

      dateFrom = moment().startOf(unitMap[selectedPeriod.value]).toDate();
      dateTo   = moment().endOf(unitMap[selectedPeriod.value]).toDate();
      this.setState({
        dateFrom,
        dateTo
      })
    } else if(selectedPeriod.value === 'Today') {
      dateAsOf = moment().toDate()
      this.setState({ 
        dateAsOf
      })
    } else if(selectedPeriod.value === 'Custom') {
      dateFrom = customDates?.dateFrom
      dateTo = customDates?.dateTo
      this.setState({
        dateFrom,
        dateTo
      })
    }
    this.setState({ selectedPeriod })
    this.props.onPeriodChanged(selectedPeriod, dateFrom, dateTo, dateAsOf)
  }

  render() {
    const { selectedPeriod, dateFrom, dateTo, dateAsOf } = this.state

    return (
      <div className="flex items-center gap-3">
        <Select
          options={period_options}
          classNames={{
            container: () => 'min-w-40',
          }}
          value={selectedPeriod}
          onChange={this.periodChanged}
        />
        <div className="flex items-center gap-2">
          {selectedPeriod?.value === 'Today' ?
          <>
            <span>As of</span>
            <DatePicker
              className="w-32"
              disabled={selectedPeriod?.value !== 'Custom'}
              selected={dateAsOf}
            />
          </>
          :
          <>
            <DatePicker
              className="w-32"
              disabled={selectedPeriod?.value !== 'Custom'}
              selected={dateFrom}
              onChange={selected => {
                this.setState({ dateFrom: selected })
                this.periodChanged(selectedPeriod, {dateFrom: selected, dateTo: dateTo})
              }}
            />
            <span>to</span>
            <DatePicker
              className="w-32"
              disabled={selectedPeriod?.value !== 'Custom'}
              minDate={dateFrom}
              selected={dateTo}
              onChange={selected => {
                this.setState({ dateTo: selected })
                this.periodChanged(selectedPeriod, {dateFrom: dateFrom, dateTo: selected})
              }}
            />
          </>
          }
        </div>
      </div>
    )
  }
}

export default ReportPeriodSelector