import { Button, Field, Input, Label } from "@headlessui/react"
import { connect } from "react-redux";
import React, { Component } from 'react'
import ConfirmDialog from "../../components/ConfirmDialog"
import { sdMessage } from "../../helpers/utils"
import { ReactComponent as IconEdit } from '../../assets/icons/icon-edit.svg'
//import { ReactComponent as IconDelete } from '../../assets/icons/icon-trash.svg'
import Pagination from "../../components/Pagination"
import accountingActions from "../../redux/accounting/actions";
import Select from "react-select";

const {
  accountingAllAccounts,
  accountingAddAccount,
  accountingUpdateAccount,
} = accountingActions;

class GLAccounts extends Component {
  state = {
    editId: null,
    deleteId: null,
    searchBy: 'Number',
    active_sort_field: 'account_number',
    active_sort_direction: 'asc',
    page: 1,
    per_page: 10,
    query: "",
    add_account_number: "",
    add_account_title: "",
    add_balance: null,
    edit_account_title: "",
    edit_balance: null,
  }

  sort = (name) => {
    this.setState({
      active_sort_field: name,
      active_sort_direction: this.state.active_sort_direction === 'asc' ? 'desc' : 'asc'
    })
  }

  componentDidMount = () => {
    const { per_page } = this.state;
    this.props.accountingAllAccounts({count: per_page})
  }

  addAccount = () => {
    const { add_account_number, add_account_title, add_balance } = this.state;
    this.props.accountingAddAccount(
      {
        account_number: add_account_number,
        title: add_account_title,
        balance: add_balance.value,
      },
      {
        success: [
          {
            function: this.setStateLocal,
            params: [
              {
                add_account_number: "",
                add_account_title: "",
                add_balance: null,
              }
            ]
          }
        ]
      }
    )
  }

  updateAccount = (item) => {
    const { edit_account_title, edit_balance } = this.state;
    this.props.accountingUpdateAccount(
      {
        account_id: item.id,
        title: edit_account_title || "",
        balance: edit_balance?.value
          ? edit_balance?.value === item.balance_name
            ? null
            : edit_balance?.value
          : null
      },
      {
        success: [
          {
            function: this.setStateLocal,
            params: [
              {
                edit_account_title: "",
                edit_balance: null,
                editId: null,
              }
            ]
          }
        ]
      }
    )
  }

  setStateLocal = (partialState, callback) => {
    this.setState(partialState, callback)
  }

  isAddValid = () => {
    const { add_account_number, add_account_title, add_balance } = this.state;
    if(!add_account_number || !add_account_title || !add_balance){return false}
    return true;
  }

  render() {
    const { accounts, accounts_working } = this.props;

    return (
      <>
        <div className="mb-5">
          <h1 className="text-xl font-semibold mb-5">GL Accounts</h1>
          <div className="mb-4">
            <Field className="flex items-center gap-2">
              <Label>Search:</Label>
              <Input
                type="text"
                placeholder="Search"
                value={this.state.query}
                onChange={(e) => this.setState({query: e.target.value})}
                disabled={accounts_working}
              />
              <Button
                className="btn-secondary"
                onClick={() => this.props.accountingAllAccounts({
                  count: this.state.per_page,
                  query: this.state.query,
                })}
                disabled={accounts_working}
              >Go</Button>
            </Field>
          </div>
          <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4">
            <table className="w-full text-[15px]">
              <thead>
                <tr className="border-b-2 border-slate-200">
                  <th>Account Number</th>
                  <th>Account Name</th>
                  <th>Balance</th>
                  <th className="p-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {accounts?.data &&  accounts?.data.map(item => (
                  <tr key={`item-${item.account_number}`} className="border-t border-slate-200">
                    <td className="p-2 w-48">
                      <span>{item.account_number}</span>
                    </td>
                    <td className="p-2">
                      {this.state.editId === item.id
                        ? <Input
                            type="text"
                            className="w-full"
                            defaultValue={item.title}
                            onChange={(e) => this.setState({edit_account_title: e.target.value})}
                            disabled={accounts_working}
                          />
                        : <span>{item.title}</span>
                      }
                    </td>
                    <td>
                      {this.state.editId === item.id
                        ? <Select
                            options={[
                              {value: "CREDIT", label: "CREDIT"},
                              {value: "DEBIT", label: "DEBIT"},
                            ]}
                            onChange={(e) => this.setState({edit_balance: e})}
                            value={this.state.edit_balance}
                            disabled={accounts_working}
                          />
                        : <span>{item.balance_name}</span>
                      }
                    </td>
                    <td className="p-2 w-48">
                      <div className="flex items-center gap-3">
                        {this.state.editId === item.id ?
                        <>
                          <Button
                            className="btn-primary"
                            onClick={() => this.updateAccount(item)}
                            disabled={accounts_working}
                          >Save</Button>
                          <Button
                            className="btn-tertiary bg-white"
                            onClick={() => {this.setState({
                              editId: null,
                              edit_account_title: "",
                              edit_balance: null,
                              }
                            )}}
                          >Cancel</Button>
                        </>
                        :
                        item.company ?
                          <>
                            <IconEdit
                              className="text-slate-500 size-4"
                              role="button"
                              onClick={() => this.setState({
                                editId: item.id,
                                edit_balance: {value: item.balance_name, label: item.balance_name}
                              })}
                            />
                            {/*
                            <IconDelete
                              className="text-red-700 size-4"
                              role="button"
                              onClick={() => this.setState({ deleteId: item.id })}
                            />
                            */}
                          </>
                          : null
                        }
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className="border-t border-slate-200">
                  <td className="p-2 w-44">
                    <Input
                      type="text"
                      className="w-full"
                      placeholder="Account number"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength="8"
                      onChange={(e) => this.setState({add_account_number: e.target.value})}
                      value={this.state.add_account_number}
                    />
                  </td>
                  <td className="p-2">
                    <Input
                      type="text"
                      className="w-full"
                      placeholder="Account name"
                      onChange={(e) => this.setState({add_account_title: e.target.value})}
                      value={this.state.add_account_title}
                    />
                  </td>
                  <td>
                    <Select
                      options={[
                        {value: "CREDIT", label: "CREDIT"},
                        {value: "DEBIT", label: "DEBIT"},
                      ]}
                      onChange={(e) => this.setState({add_balance: e})}
                      value={this.state.add_balance}
                    />
                  </td>
                  <td className="p-2 w-48">
                    <Button
                      className="btn-primary"
                      onClick={() => this.addAccount()}
                      disabled={accounts_working || !this.isAddValid()}
                    >Save</Button>
                  </td>
                </tr>
              </tfoot>
            </table>
            <ConfirmDialog
              open={this.state.deleteId !== null }
              type="danger"
              title="Delete account number"
              text={`Are you sure want to delete account number: ${this.state.deleteId}?`}
              confirmBtnText="Yes"
              onClose={() => this.setState({ deleteId: null })}
              onConfirm={() => {
                sdMessage('Account number has been deleted.', 'success')
                this.setState({ deleteId: null })
              }}
            />
          </div>
        </div>
        <div className="flex flex-col items-end">
          <Pagination
            data={accounts}
            callback={this.props.accountingAllAccounts}
            callback_params={{
              count: this.state.per_page,
              query: this.state.query,
            }}
          />
        </div>
      </>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    accounts: state.Accounting.get("accounts"),
    accounts_working: state.Accounting.get("accounts_working"),
  }),
  {
    accountingAllAccounts,
    accountingAddAccount,
    accountingUpdateAccount,
  }
)(GLAccounts);
