import { all, fork } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* invoicesGetAllForClient() {
  yield commonSaga(
    actions.INVOICES_GET_ALL_FOR_CLIENT,
    apiCalls.get,
    apiEndpoints.invoices.invoices,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* invoicesAddInvoice() {
  yield commonSaga(
    actions.INVOICES_ADD_INVOICE,
    apiCalls.post,
    apiEndpoints.invoices.invoice,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* invoicesGetInvoice() {
  yield commonSaga(
    actions.INVOICES_GET_INVOICE,
    apiCalls.get,
    apiEndpoints.invoices.invoice,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* invoicesUpdateInvoice() {
  yield commonSaga(
    actions.INVOICES_UPDATE_INVOICE,
    apiCalls.put,
    apiEndpoints.invoices.invoice,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* invoicesUpdateInvoiceStatus() {
  yield commonSaga(
    actions.INVOICES_UPDATE_INVOICE_STATUS,
    apiCalls.put,
    apiEndpoints.invoices.invoice_status,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* invoicesRecurringGetAll() {
  yield commonSaga(
    actions.INVOICES_RECURRING_GET_ALL,
    apiCalls.get,
    apiEndpoints.invoices.invoice_recurring,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* invoicesRecurringUpdate() {
  yield commonSaga(
    actions.INVOICES_RECURRING_UPDATE,
    apiCalls.put,
    apiEndpoints.invoices.invoice_recurring,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export default function* invoicesSaga() {
	yield all([
		fork(invoicesGetAllForClient),
		fork(invoicesAddInvoice),
		fork(invoicesGetInvoice),
		fork(invoicesUpdateInvoice),
		fork(invoicesUpdateInvoiceStatus),
		fork(invoicesRecurringGetAll),
		fork(invoicesRecurringUpdate),
	]);
}
