import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Field, Input, Label, Radio, RadioGroup } from "@headlessui/react";
import { ReactComponent as Logo } from '../assets/images/event-ledger-logo.svg'
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";
import authActions from "../redux/auth/actions";
import { ReactComponent as IconMail } from '../assets/icons/icon-mail.svg'
import { ReactComponent as IconSMS } from '../assets/icons/icon-sms.svg'

const {
  userLogin,
  userLogin2FA,
  user2FASendCode,
  userClearCodeSent,
} = authActions;

const verification_options = [
  {
    title:'Email',
    name: 'email',
    description: 'We will send verification code to your email.',
    icon: <IconMail className="size-6" />
  },
  {
    title:'SMS',
    name: 'SMS',
    description: 'We will send verification code via SMS to your phone.',
    icon: <IconSMS className="size-6" />
  }
]

const RESEND_COOLDOWN_TIME = 30
let resendInterval = null

class Login extends Component {
  state = {
    email: '',
    pw: '',
    show_pw: false,
    selectedVerification: verification_options[0].name,
    login_success: false,
    resend_timer: RESEND_COOLDOWN_TIME,
    code: "",
  }

  signIn = () => {
    const { email, pw } = this.state;
    const data = {
       username: email,
       password: pw,
    }
    this.props.userLogin(data);
  }

  verify = () => {
    const { email, pw, code } = this.state;
    const data = {
      username: email,
      password: pw,
      code: code,
    }
    this.props.userLogin2FA(data);
    this.setState({code: ""})
  }

  sendCode = () => {
    const { email, pw, selectedVerification } = this.state;
    const data = {
      username: email,
      password: pw,
      send_option: selectedVerification,
    }
    this.props.user2FASendCode(data)

    resendInterval = setInterval(() => {
      this.setState({
        resend_timer: this.state.resend_timer === 0 ? 0 : this.state.resend_timer - 1
      })
      if(this.state.resend_timer === 0) {
        clearInterval(resendInterval)
      }
    }, 1000)
  }

  resendCode = () => {
    this.setState({ resend_timer: RESEND_COOLDOWN_TIME })
  }

  useAnotherMethod = () => {
    this.setState({
      resend_timer: RESEND_COOLDOWN_TIME
    })
    this.props.userClearCodeSent();
  }

  render() {
    const { userData } = this.props;

    return (
      <div className="container mx-auto xl:mx-0 flex justify-center gap-8">
        <div className="hidden flex-1 xl:block bg-[url('./assets/images/login-bg.png')] bg-no-repeat bg-cover h-screen w-[44rem]">&nbsp;</div>
        <div className="xl:flex-1">
          <div className="flex flex-col gap-3 py-60 w-96 bg-white h-screen">
            <div className="w-[150px] h-[48px] mb-2">
              <Logo />
            </div>
            {!userData?.username ?
            <>
            <h2 className="font-semibold text-2xl mb-3">Sign in to your account</h2>
            <Field className="field">
              <Label>Username</Label>
              <Input
                type="text"
                placeholder="Enter username"
                onChange={e => this.setState({ email: e.target.value })}
              />
            </Field>
            <Field className="field mt-2">
              <Label>Password</Label>
              <div className="relative">
                <Input
                  type={this.state.show_pw ? 'text' : 'password'}
                  className="w-full"
                  placeholder="Enter password"
                  onChange={e => this.setState({ pw: e.target.value })}
                />
                <small
                  className="absolute top-3 right-2 cursor-pointer text-slate-500"
                  onClick={() => this.setState({ show_pw: !this.state.show_pw })}
                >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
              </div>
            </Field>
            <Field className="field mt-2">
              <Label>Company Code</Label>
              <Input type="text" placeholder="Enter company code" />
            </Field>
            <Button
              className="btn-primary mt-2"
              onClick={this.signIn}
              disabled={this.props.login_working}
            >
              <span className="flex gap-1 justify-center items-center">
                {this.props.login_working && <Spinner />}
                <span>Sign In</span>
              </span>
            </Button>
            <div className="text-sm mt-2">
              <p><span className="text-slate-500">Don't have an account?</span> <Link to="/register">Sign up</Link></p>
              <p className="mt-1"><Link to="/forgot-password">Forgot password</Link></p>
            </div>
            </>
            :
            !this.props.code_sent_success ?
            <>
            <h2 className="font-semibold text-2xl mb-3">Choose Verification Method</h2>
            <RadioGroup
              value={this.state.selectedVerification}
              onChange={selected => this.setState({ selectedVerification: selected })}
            >
              {verification_options.map(option => (
              <Field
                key={option.name}
                className={`flex items-center gap-3 border hover:border-slate-300 rounded p-2 mb-3 ${this.state.selectedVerification === option.name ? '!border-blue-500' : ''}`}
                role="button"
                onClick={() => this.setState({ selectedVerification: option.name })}
              >
                <Radio className="radio" value={option.name} />
                <div className="flex items-center gap-2 flex-1">
                  {option.icon}
                  <div className="flex-1">
                    <Label className="block text-slate-700 font-semibold">{option.title}</Label>
                    <span className="block text-sm text-slate-500">{option.description}</span>
                  </div>
                </div>
              </Field>
              ))}
            </RadioGroup>
            <Button
              className="btn-primary"
              onClick={this.sendCode}
            >
              <span className="flex gap-1 justify-center items-center">
                {this.props.sendcode_working && <Spinner />}
                <span>Send Code</span>
              </span>
            </Button>
            </>
            :
            <>
            <h2 className="font-semibold text-2xl mb-3">Verify code</h2>
            <p>We've sent code to your {this.state.selectedVerification} at <span className="font-semibold">{userData?.email}</span>, please enter 4 digits code you've received.</p>
            <Field className="field">
              <Input
                type="text"
                autoComplete="off"
                value={this.state.code}
                onChange={(e) => this.setState({code: e.target.value})}
              />
              <p className="text-slate-500">
                <span>Didn't get the code?</span>&nbsp;
                {this.state.resend_timer === 0 ?
                <span
                  role="button"
                  className="underline hover:no-underline"
                  onClick={this.resendCode}
                >Resend</span>
                :
                <span>(Wait {this.state.resend_timer}s)</span>
                }
              </p>
            </Field>
            <Button
              className="btn-primary my-4"
              onClick={this.verify}
              disabled={this.props.login_working || !this.state.code}
            >
              <span className="flex gap-1 justify-center items-center">
                {this.props.login_working && <Spinner />}
                <span>Verify</span>
              </span>
            </Button>
              <small
                role="button"
                className="text-slate-500 underline hover:no-underline"
                onClick={this.useAnotherMethod}
              >&larr; Use another method</small>
            </>
            }
          </div>
        </div>
      </div>
    )
  }
}


export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      login_failed: state.Auth.get("login_failed"),
      login_failed_message: state.Auth.get("login_failed_message"),
      login_working: state.Auth.get("login_working"),
      code_sent_success: state.Auth.get("code_sent_success"),
    }),
    {
      userLogin,
      userLogin2FA,
      user2FASendCode,
      userClearCodeSent,
    }
  )(Login)
);
