import { all, fork } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* reportingReportGet() {
  yield commonSaga(
    actions.REPORTS_REPORT_GET,
    apiCalls.post,
    apiEndpoints.reports.report,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export default function* companiesSaga() {
	yield all([
		fork(reportingReportGet),
	]);
}
