import { Map } from "immutable";

const initState = new Map({
  documents: {},
  documents_working: false,
  document_preview_url: null,
  document_preview_url_working: false,
});

export default function documentsReducer(state = initState, action) {
  switch (action.type) {

    case "DOCUMENTS_DOCUMENT_PREVIEW":
      return state
        .set("document_preview_url", null)
        .set("document_preview_url_working", true)
    case "DOCUMENTS_DOCUMENT_PREVIEW_SUCCESS":
      return state
        .set("document_preview_url", action?.data?.data?.url)
        .set("document_preview_url_working", false)
    case "DOCUMENTS_DOCUMENT_PREVIEW_FAILED":
      return state
        .set("document_preview_url", null)
        .set("document_preview_url_working", false)

    case "DOCUMENTS_ADD_DOCUMENT":
      return state
        .set("documents_working", true)
    case "DOCUMENTS_ADD_DOCUMENT_SUCCESS":
      var documents_add = {...state.get("documents")}
      if(documents_add?.data){
        documents_add.data = [action.data.data, ...documents_add?.data];
      } else {
        documents_add.data = [action.data.data];
      }
      return state
        .set("documents", {...documents_add})
        .set("documents_working", false)
    case "DOCUMENTS_ADD_DOCUMENT_FAILED":
      return state
        .set("documents_working", false)

    case "DOCUMENTS_GET_ALL_FOR_CLIENT":
      return state
        .set("documents", {})
        .set("documents_working", true)
    case "DOCUMENTS_GET_ALL_FOR_CLIENT_SUCCESS":
      return state
        .set("documents", {...action.data.data})
        .set("documents_working", false)
    case "DOCUMENTS_GET_ALL_FOR_CLIENT_FAILED":
      return state
        .set("documents", {})
        .set("documents_working", false)

		default:
			return state;
  }
}
