import { all, fork } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* accountingGLAccountsSearch() {
  yield commonSaga(
    actions.ACCOUNTING_GL_ACCOUNTS_SEARCH,
    apiCalls.get,
    apiEndpoints.accounting.gl_accounts_search,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* accountingGL1099All() {
  yield commonSaga(
    actions.ACCOUNTING_GL_1099_ALL,
    apiCalls.get,
    apiEndpoints.accounting.gl_1099_all,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* accountingAllAccounts() {
  yield commonSaga(
    actions.ACCOUNTING_ALL_ACCOUNTS,
    apiCalls.get,
    apiEndpoints.accounting.gl_accounts,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* accountingAddAccount() {
  yield commonSaga(
    actions.ACCOUNTING_ADD_ACCOUNT,
    apiCalls.post,
    apiEndpoints.accounting.gl_account,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* accountingUpdateAccount() {
  yield commonSaga(
    actions.ACCOUNTING_UPDATE_ACCOUNT,
    apiCalls.put,
    apiEndpoints.accounting.gl_account,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export default function* vendorsSaga() {
	yield all([
		fork(accountingGLAccountsSearch),
		fork(accountingGL1099All),
		fork(accountingAllAccounts),
		fork(accountingAddAccount),
		fork(accountingUpdateAccount),
	]);
}
