import { all, fork } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* clientsGetForUser() {
  yield commonSaga(
    actions.CLIENTS_GET_FOR_USER,
    apiCalls.get,
    apiEndpoints.clients.clients,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* clientCreate() {
  yield commonSaga(
    actions.CLIENT_CREATE,
    apiCalls.post,
    apiEndpoints.clients.client,
    {
      ...default_config,
      ...{
        success_show: true,
        redirect_on_success: "/app/select-client",
      }
    }
  );
}

export function* clientUpdate() {
  yield commonSaga(
    actions.CLIENT_UPDATE,
    apiCalls.put,
    apiEndpoints.clients.client,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* clientGroupAdd() {
  yield commonSaga(
    actions.CLIENT_GROUP_ADD,
    apiCalls.post,
    apiEndpoints.clients.group,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* clientGroupsGet() {
  yield commonSaga(
    actions.CLIENT_GROUPS_GET,
    apiCalls.get,
    apiEndpoints.clients.groups,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* clientGroupUpdate() {
  yield commonSaga(
    actions.CLIENT_GROUPS_UPDATE,
    apiCalls.put,
    apiEndpoints.clients.group,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* clientGroupDelete() {
  yield commonSaga(
    actions.CLIENT_GROUPS_DELETE,
    apiCalls.delete,
    apiEndpoints.clients.group,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* clientDashboardClientsActivity() {
  yield commonSaga(
    actions.CLIENT_DASHBOARD_CLIENTS_ACTIVITY,
    apiCalls.get,
    apiEndpoints.clients.dashboard.clients.activity,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* clientDashboardClientsTasks() {
  yield commonSaga(
    actions.CLIENT_DASHBOARD_CLIENTS_TASKS,
    apiCalls.get,
    apiEndpoints.clients.dashboard.clients.tasks,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export default function* clientsSaga() {
	yield all([
		fork(clientsGetForUser),
		fork(clientCreate),
		fork(clientUpdate),
		fork(clientGroupAdd),
		fork(clientGroupsGet),
		fork(clientGroupDelete),
		fork(clientDashboardClientsActivity),
		fork(clientDashboardClientsTasks),
	]);
}
