import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ReactComponent as IconClient } from '../assets/icons/icon-client.svg'
import clientsActions from "../redux/clients/actions";
import userActions from "../redux/user/actions";

const {
  clientsGetForUser,
} = clientsActions;

const {
  userSwitchClient,
} = userActions;

const tasks = [
  {title: 'Invoice to Enter', count: 5},
  {title: 'Invoice to Review', count: 2},
  {title: 'Invoice to Approve', count: 4},
  {title: 'Invoice to Pay', count: 3}
]

class SelectClient extends Component {
  componentDidMount = () => {
    this.props.clientsGetForUser();
  }

  render() {
    const {
      user_clients,
      user_switch_client_working,
      user_clients_working
    } = this.props;

    return (
      <div>
        <div className="text-center">
          <h1 className="text-xl font-semibold">Select Client</h1>
          <p className="text-slate-500">Please select a client to work on</p>
        </div>
        {user_clients_working ?
        <ClientsLoading />
        :
        <div className="flex flex-col gap-8 mt-8">
          {user_clients.map(item => (
            <div key={`group-${item.id}`} className="">
              <h2 className="uppercase font-semibold text-lg bg-slate-800 text-white px-4 py-1 rounded inline-block mb-1">{item.name}</h2>
              {item?.clients?.length > 0 ? item.clients.map(c => (
              <div
                role="button"
                onClick={() => this.props.userSwitchClient({client_id: c.id})}
                key={`client-${c.slug}`}
                className="py-2 border-b border-slate-200 last:border-none flex justify-between no-underline flex-1 group hover:text-blue-700"
                disabled={user_switch_client_working}
              >
                <div className="flex items-center gap-3">
                  <IconClient className="size-8 text-slate-300 mb-2 group-hover:text-blue-700" />
                  <h2 className="font-medium">{c.name}</h2>
                </div>
                <div className="flex gap-5">
                  {tasks.map(t => (
                    <div key={`task-${c.slug}-${t.title}`} className="group no-underline flex-1">
                      <div className="flex flex-col px-4 text-right">
                        <span className="text-slate-500 group-hover:text-blue-700/75 text-nowrap">{t.title}</span>
                        <span className="text-xl">{t.count}</span>
                      </div>
                    </div>
                  ))}
                  </div>
              </div>
              ))
              :
              <p className="text-slate-500 mt-2">This group currently has no clients.</p>
              }
            </div>
          ))}
        </div>
        }
      </div>
    )
  }
}

const ClientsLoading = () => (
  <div className="flex flex-col gap-8 mt-8">
    {Array.from(Array(5)).map((item,idx) => (
      <div key={`skeleton-${idx}`} className="p-8 rounded flex justify-between flex-1 bg-slate-100/75 animate-pulse">
        <div className="flex items-center gap-3">
          <div className="size-12 bg-slate-300 rounded" />
          <div className="bg-slate-300 w-24 h-5 bg-slate-300 rounded" />
        </div>
        <div className="flex gap-8">
          {Array.from(Array(5)).map((item,idx) => (
          <div key={`skeleton-task-${idx}`} className="flex flex-col items-end gap-2 min-w-24">
            <div className="w-32 h-3 bg-slate-300 rounded-sm" />
            <div className="w-6 h-8 bg-slate-300 rounded-sm" />
          </div>
          ))}
        </div>
      </div>
    ))}
  </div>
)

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      user_switch_client_working: state.User.get("user_switch_client_working"),
      user_clients: state.Clients.get("user_clients"),
      user_clients_working: state.Clients.get("user_clients_working"),
    }),
    {
      clientsGetForUser,
      userSwitchClient,
    }
  )(SelectClient)
);
