import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom"
import { digitСonversion, momentDateStrToLocalFormat } from "../../helpers/utils"
import Select from 'react-select'
import { useParams } from "react-router"
import invoicesActions from "../../redux/invoices/actions";
import vendorsActions from "../../redux/vendors/actions";
import Pagination from "../../components/Pagination";
import { ReactComponent as IconReload } from '../../assets/icons/icon-reload.svg'

const { invoicesGetAllForClient } = invoicesActions;

const { vendorsGetAllForClient } = vendorsActions;

const navitems = [
  {status: null, title: 'All'},
  {status: 'TO_REVIEW', title: 'To Review'},
  {status: 'TO_APPROVE', title: 'To Approve'},
  {status: 'TO_PAY', title: 'To Pay'},
  {status: 'PAID', title: 'Paid'},
  {status: 'HOLD', title: 'Hold'},
  {status: 'REJECTED', title: 'Rejected'},
];

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Invoices extends Component {
  state = {
    filterClient: null,
    filterVendor: null,
    invoice_status: navitems[0],
  }

  componentDidMount() {
    const { userData, params } = this.props;
    var status = params?.status ? params?.status : null;
    status = navitems.find((item) => item.status === status);
    if(!status){ status = navitems[0] };
    this.setState({invoice_status: status});

    this.props.invoicesGetAllForClient({
      client_id: userData?.active_client?.id,
      status: status?.status ? status?.status : null,
    });
    this.props.vendorsGetAllForClient({client_id: userData?.active_client?.id});
  }

  componentDidUpdate(pp, ps) {
    if(pp.params?.status !== this.props.params.status) {
    }
  }

  generateVendorSelect = () => {
    const { vendors } = this.props;
    if(vendors?.data){
      return vendors.data.map(item => {
        return {
          value: item.id,
          label: item.name
        }
      })
    }
    return []
  }

  sumAllInvoices = () => {
    const { invoices } = this.props;
    if(!invoices?.data){ return 0; }
    return invoices?.data?.map(item => item.amount || 0).reduce((a,b)=>a+b);
  }

  render() {
    const { invoices, invoices_working, userData } = this.props;

    return (
      <div>
        <h1 className="text-xl font-semibold leading-none mb-5">Invoices</h1>
        <div className="flex gap-2 mb-5">
          {navitems.map(item => {
            return (
              <span
                key={`status-invoice-${item.status}`}
                className={`navlink-tab ${this.state.invoice_status?.status === item.status ? 'active' : ''}`}
                role="button"
                onClick={() => {
                  this.setState({ invoice_status: item })
                  this.props.invoicesGetAllForClient({
                    client_id: userData?.active_client?.id,
                    vendor: this.state.filterVendor?.value,
                    status: item.status,
                  });
                }}
              >{item.title}</span>
            )
          })}
        </div>
        {invoices_working ?
        <InvoiceLoading />
        :
        <>
          <div className="flex items-center gap-4 mb-4">
            <div className="flex items-center gap-2">
              <span>Vendor:</span>
              <Select
                placeholder="All vendors"
                options={this.generateVendorSelect()}
                value={this.state.filterVendor}
                onChange={selected => {
                  this.setState({ filterVendor: selected })
                  this.props.invoicesGetAllForClient({
                    client_id: userData?.active_client?.id,
                    vendor: selected?.value,
                    status: this.state.invoice_status?.status,
                  });
                }}
                isClearable={true}
              />
            </div>
          </div>
          {invoices?.data?.length > 0 ?
          <>
            <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4">
              <table className="w-full text-[15px] table-fixed">
                <thead>
                  <tr>
                    <th className="border-b-2 border-slate-200 w-5"></th>
                    <th className="text-left border-b-2 border-slate-200">Invoice Number</th>
                    <th className="border-b-2 border-slate-200">Vendor</th>
                    <th className="border-b-2 border-slate-200">Invoice Date</th>
                    <th className="border-b-2 border-slate-200">Due Date</th>
                    <th className="border-b-2 border-slate-200">Approver</th>
                    <th className="border-b-2 border-slate-200 text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices?.data?.map(invoice => (
                  <tr key={`invoice-${invoice.id}`}>
                    <td className="border-t py-1">{invoice.is_recurring ? <IconReload /> : null}</td>
                    <td className="w-24 border-t py-1 border-slate-200">
                      <NavLink to={`/app/client/${userData?.active_client?.id}/invoices/view/${invoice.id}`} className="no-underline text-blue-700 hover:text-blue-500 font-normal">{invoice.invoice_id}</NavLink>
                    </td>
                    <td className="w-24 border-t py-2 border-slate-200">{invoice.vendor?.name}</td>
                    <td className="w-24 border-t py-2 border-slate-200">{momentDateStrToLocalFormat(invoice.invoice_date)}</td>
                    <td className="w-24 border-t py-2 border-slate-200">{momentDateStrToLocalFormat(invoice.invoice_due_date)}</td>
                    <td className="w-24 border-t py-2 border-slate-200">{invoice.approver}</td>
                    <td className="w-24 border-t py-2 border-slate-200 text-right">{digitСonversion(invoice.amount, 'currency')}</td>
                  </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="font-semibold text-lg">
                    <td colSpan={6} className="w-24 border-t py-1 border-slate-200 ">Total</td>
                    <td className="w-24 border-t py-1 border-slate-200 text-right">{digitСonversion(this.sumAllInvoices(), 'currency')}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="flex flex-col items-end">
              <Pagination
                data={invoices}
                callback={this.props.invoicesGetAllForClient}
                callback_params={{client_id: userData?.active_client?.id}}
              />
            </div>
          </>
          :
          <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4">
            {invoices?.data?.length > 0 ?
            <table className="w-full text-[15px] table-fixed">
              <thead>
                <tr>
                  <th className="text-left border-b-2 border-slate-200">Invoice Number</th>
                  <th className="border-b-2 border-slate-200">Vendor</th>
                  <th className="border-b-2 border-slate-200">Invoice Date</th>
                  <th className="border-b-2 border-slate-200">Due Date</th>
                  <th className="border-b-2 border-slate-200">Approver</th>
                  <th className="border-b-2 border-slate-200 text-right">Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoices?.data?.map(invoice => (
                <tr key={`invoice-${invoice.id}`}>
                  <td className="w-24 border-t py-1 border-slate-200">
                    <NavLink to={`/app/client/${userData?.active_client?.id}/invoices/view/${invoice.id}`} className="no-underline text-blue-700 hover:text-blue-500 font-normal">{invoice.invoice_id}</NavLink>
                  </td>
                  <td className="w-24 border-t py-2 border-slate-200">{invoice.vendor?.name}</td>
                  <td className="w-24 border-t py-2 border-slate-200">{momentDateStrToLocalFormat(invoice.invoice_date)}</td>
                  <td className="w-24 border-t py-2 border-slate-200">{momentDateStrToLocalFormat(invoice.invoice_due_date)}</td>
                  <td className="w-24 border-t py-2 border-slate-200">{invoice.approver}</td>
                  <td className="w-24 border-t py-2 border-slate-200 text-right">{digitСonversion(invoice.amount, 'currency')}</td>
                </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className="font-semibold text-lg">
                  <td colSpan={5} className="w-24 border-t py-1 border-slate-200 ">Total</td>
                  <td className="w-24 border-t py-1 border-slate-200 text-right">{digitСonversion(this.sumAllInvoices(), 'currency')}</td>
                </tr>
              </tfoot>
            </table>
            :
            <p className="text-slate-500 text-[15px]">No invoice data found.</p>
            }
          </div>
        }
        </>
      }
      </div>
    )
  }
}

const InvoiceLoading = () => (
  <>
    <div className="flex gap-4">
      <div className="bg-slate-100 rounded-lg mb-5 px-3 py-5 w-48 animate-pulse" />
    </div>
    <div className="bg-slate-100/75 rounded-lg mb-5 px-3 py-5 animate-pulse">
      <div className="grid grid-cols-6 gap-14 mb-5">
        <div className="h-2 bg-slate-300 rounded" />
        <div className="h-2 bg-slate-300 rounded" />
        <div className="h-2 bg-slate-300 rounded" />
        <div className="h-2 bg-slate-300 rounded" />
        <div className="h-2 bg-slate-300 rounded" />
        <div className="h-2 bg-slate-300 rounded" />
      </div>
      {Array.from(Array(10)).map((item,idx) =>(
      <div key={`skeletion-${idx}`} className="grid grid-cols-8 gap-5 mb-5 h-8 bg-slate-300 rounded"></div>
      ))}
    </div>
  </>
)

export default withParams(withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      invoices: state.Invoices.get("invoices"),
      invoices_working: state.Invoices.get("invoices_working"),
      vendors: state.Vendors.get("vendors"),
      vendors_working: state.Vendors.get("vendors_working"),
    }),
    {
      invoicesGetAllForClient,
      vendorsGetAllForClient,
    }
  )(Invoices)
));
