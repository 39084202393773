import { Map } from "immutable";

const initState = new Map({
  user_clients: [],
  user_clients_working: false,
  user_client_create_working: false,
  user_client_update_working: false,
  user_client_groups_working: false,
  user_client_groups: [],
  dashboard_clients_activity: [],
  dashboard_clients_activity_working: false,
  dashboard_clients_tasks: {},
  dashboard_clients_tasks_working: false,
});

export default function clientsReducer(state = initState, action) {
  switch (action.type) {

    case "CLIENT_DASHBOARD_CLIENTS_TASKS":
      return state
        .set("dashboard_clients_tasks_working", true)
    case "CLIENT_DASHBOARD_CLIENTS_TASKS_SUCCESS":
      return state
        .set("dashboard_clients_tasks", {...action.data.data})
        .set("dashboard_clients_tasks_working", false)
    case "CLIENT_DASHBOARD_CLIENTS_TASKS_FAILED":
      return state
        .set("dashboard_clients_tasks_working", false)

    case "CLIENT_DASHBOARD_CLIENTS_ACTIVITY":
      return state
        .set("dashboard_clients_activity_working", true)
    case "CLIENT_DASHBOARD_CLIENTS_ACTIVITY_SUCCESS":
      return state
        .set("dashboard_clients_activity", [...action.data.data])
        .set("dashboard_clients_activity_working", false)
    case "CLIENT_DASHBOARD_CLIENTS_ACTIVITY_FAILED":
      return state
        .set("dashboard_clients_activity_working", false)

    case "CLIENT_GROUPS_DELETE":
      return state
        .set("user_client_groups_working", true)
    case "CLIENT_GROUPS_DELETE_SUCCESS":
      return state
        .set("user_client_groups", [...state.get("user_client_groups").filter(item => item.id !== action.data.data.id)])
        .set("user_client_groups_working", false)
    case "CLIENT_GROUPS_DELETE_FAILED":
      return state
        .set("user_client_groups_working", false)

    case "CLIENT_GROUPS_UPDATE":
      return state
        .set("user_client_groups_working", true)
    case "CLIENT_GROUPS_UPDATE_SUCCESS":
      var user_client_groups_update = [...state.get("user_client_groups")];
      user_client_groups_update = user_client_groups_update.map(item => {
        if(item.id === action.data.data.id){
          return action.data.data;
        }
        return item;
      })
      return state
        .set("user_client_groups", [...user_client_groups_update])
        .set("user_client_groups_working", false)
    case "CLIENT_GROUPS_UPDATE_FAILED":
      return state
        .set("user_client_groups_working", false)

    case "CLIENT_GROUPS_GET":
      return state
        .set("user_client_groups", [])
        .set("user_client_groups_working", true)
    case "CLIENT_GROUPS_GET_SUCCESS":
      return state
        .set("user_client_groups", [...action.data.data])
        .set("user_client_groups_working", false)
    case "CLIENT_GROUPS_GET_FAILED":
      return state
        .set("user_client_groups", [])
        .set("user_client_groups_working", false)

    case "CLIENT_GROUP_ADD":
      return state
        .set("user_client_groups_working", true)
    case "CLIENT_GROUP_ADD_SUCCESS":
      return state
        .set("user_client_groups", [...state.get("user_client_groups"), action.data.data])
        .set("user_client_groups_working", false)
    case "CLIENT_GROUP_ADD_FAILED":
      return state
        .set("user_client_groups_working", false)

    case "CLIENT_UPDATE":
      return state
        .set("user_client_update_working", true)
    case "CLIENT_UPDATE_SUCCESS":
      var user_clients_update = state.get("user_clients");
      user_clients_update = user_clients_update.map(item => {
        item.clients = item.clients.map(client => {
          if(client.id === action.data.data.id){
            client = {...action.data.data}
          }
          return client;
        })
        return item;
      })
      return state
        .set("user_clients", [...user_clients_update])
        .set("user_client_update_working", false)
    case "CLIENT_UPDATE_FAILED":
      return state
        .set("user_client_update_working", false)

    case "CLIENT_CREATE":
      return state
        .set("user_client_create_working", true)
    case "CLIENT_CREATE_SUCCESS":
      return state
        .set("user_client_create_working", false)
    case "CLIENT_CREATE_FAILED":
      return state
        .set("user_client_create_working", false)

    case "CLIENTS_GET_FOR_USER":
      return state
        .set("user_clients", [])
        .set("user_clients_working", true)
    case "CLIENTS_GET_FOR_USER_SUCCESS":
      return state
        .set("user_clients", [...action.data.data])
        .set("user_clients_working", false)
    case "CLIENTS_GET_FOR_USER_FAILED":
      return state
        .set("user_clients", [])
        .set("user_clients_working", false)

		default:
			return state;
  }
}
