import { 
  Button, Checkbox, DialogTitle, Disclosure, DisclosureButton, DisclosurePanel, Field, Input, Label, 
  Menu, MenuButton, MenuItem, MenuItems, MenuSeparator, Radio, RadioGroup, 
  Select, Switch, Tab, TabGroup, TabList, TabPanel, TabPanels 
} from "@headlessui/react"
import React, { Component } from 'react'
import { Link, NavLink } from "react-router-dom"
import ConfirmDialog from "../components/ConfirmDialog"
import Modal from "../components/Modal"
import Spinner from "../components/Spinner"
import { ReactComponent as IconHome } from '../assets/icons/icon-home.svg'
import Caret from "../components/Caret"

class UILibrary extends Component {
  state = {
    checkboxChecked: false,
    checkboxChecked2: false,
    radioValue: 1,
    switchChecked: false,
    defaultModal: false,
    confirmDialog: false
  }

  render() {
    return (
      <>
      <div className="p-5">
        <h2 className="mb-2 font-semibold text-2xl">Buttons</h2>
        <div className="flex flex-wrap gap-2 items-center">
          <Button className="btn-primary">Button Primary</Button>
          <Button className="btn-secondary">Button Secondary</Button>
          <Button className="btn-tertiary">Button Tertiary</Button>
          <Button className="btn-danger">Button Danger</Button>
          <Button className="btn-primary" disabled={true}>Primary Disabled</Button>
          <Button className="btn-secondary" disabled={true}>Secondary Disabled</Button>
          <Button className="btn-danger" disabled>Danger Disabled</Button>
          <Button className="btn-primary" disabled={true}>
            <span className="flex gap-1 justify-center items-center">
              <Spinner />
              <span>Loading Button</span>
            </span>
          </Button>
        </div>
        <div className="flex gap-2 items-center mt-2">
          <Button className="btn-primary btn-sm">Small Primary</Button>
          <Button className="btn-secondary btn-sm">Small Secondary</Button>
        </div>
      </div>
      <hr />
      <div className="p-5">
        <h2 className="mb-2 font-semibold text-2xl">Inputs</h2>
        <div className="flex gap-2 items-center">
          <Input type="text" placeholder="Text input" />
          <Input type="text" placeholder="Text input" disabled={true} />
          <Input type="text" placeholder="Input small" className="input-sm" />
        </div>
      </div>
      <hr />
      <div className="p-5">
        <h2 className="mb-2 font-semibold text-2xl">Checkboxes & Radios</h2>
        <Checkbox
          className="checkbox"
          checked={this.state.checkboxChecked}
          onChange={() => this.setState({ checkboxChecked: !this.state.checkboxChecked })}
        />
        &nbsp;&nbsp;
        <Checkbox
          className="checkbox"
          checked={this.state.checkboxChecked2}
          onChange={() => this.setState({ checkboxChecked2: !this.state.checkboxChecked2 })}
        />
        &nbsp;&nbsp;
        <Checkbox className="checkbox" disabled={true} />
        &nbsp;&nbsp;
        <Checkbox className="checkbox" disabled={true} checked={true} />
        <Field className="flex items-center gap-2">
          <Checkbox
            className="checkbox"
          />
          <Label>Checkbox label</Label>
        </Field>
        <Field className="flex items-center gap-2">
          <Checkbox
            className="checkbox"
            checked={true}
          />
          <Label>Checked checkbox with label</Label>
        </Field>
        <Field className="flex items-center gap-2">
          <Checkbox
            className="checkbox"
            checked={false}
            disabled={true}
          />
          <Label>Disabled checkbox</Label>
        </Field>
        <Field className="flex items-center gap-2">
          <Checkbox
            className="checkbox"
            checked={true}
            disabled={true}
          />
          <Label>Disabled checked with label</Label>
        </Field>
        <br/>
        <RadioGroup value={this.state.radioValue} onChange={(selected) => this.setState({ radioValue: selected })} className="flex gap-2">
          <Radio className="radio" value={1} />
          <Radio className="radio" value={2} />
          <Radio className="radio" value={3} disabled={true} />
          <Radio className="radio" value={1} disabled={true} />
        </RadioGroup>
        <RadioGroup value={this.state.radioValue} onChange={selected => this.setState({ radioValue: selected })} className="flex flex-col gap-1">
          <Field className="flex items-center gap-2">
            <Radio className="radio" value={1} />
            <Label>Radio label</Label>
          </Field>
          <Field className="flex items-center gap-2">
            <Radio className="radio" value={2} />
            <Label>Radio label</Label>
          </Field>
          <Field className="flex items-center gap-2">
            <Radio className="radio" value={3} disabled={true} />
            <Label>Disabled radio</Label>
          </Field>
          <Field className="flex items-center gap-2">
            <Radio className="radio" value={1} disabled={true} />
            <Label>Disabled checked radio</Label>
          </Field>
        </RadioGroup>
      </div>
      <hr/>
      <div className="p-5">
        <h2 className="mb-2 font-semibold text-2xl">Select</h2>
        <Select>
          <option value="active">Active</option>
          <option value="paused">Paused</option>
          <option value="delayed">Delayed</option>
          <option value="canceled">Canceled</option>
        </Select><br/>
        <Select disabled={true}>
          <option value="disabled">Disabled</option>
        </Select>
      </div>
      <hr />
      <div className="p-5">
        <h2 className="mb-2 font-semibold text-2xl">Switch</h2>
        <Switch
          checked={this.state.switchChecked}
          onChange={() => this.setState({ switchChecked: !this.state.switchChecked })}
        />
      </div>
      <hr />
      <div className="p-5">
        <h2 className="mb-2 font-semibold text-2xl">Dropdown Menu</h2>
        <Menu>
          <MenuButton>
            <span>My account</span>
            <Caret className="inline ml-2" />
          </MenuButton>
          <MenuItems anchor="bottom">
            <MenuItem>
              <Link to="/settings">Settings</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/support">Support</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/license">License</Link>
            </MenuItem>
            <MenuSeparator className="my-1 h-px bg-slate-300" />
            <MenuItem>
              <span role="button">Logout</span>
            </MenuItem>
          </MenuItems>
        </Menu>
      </div>
      <hr/>
      <div className="p-5">
        <h2 className="mb-2 font-semibold text-2xl">Tabs</h2>
        <TabGroup>
          <TabList className="mb-5">
            <Tab>Tab 1</Tab>
            <Tab>Tab 2</Tab>
            <Tab>Tab 3</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>Content 1</TabPanel>
            <TabPanel>Content 2</TabPanel>
            <TabPanel>Content 3</TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
      <hr/>
      <div className="p-5">
        <h2 className="mb-2 font-semibold text-2xl">Menu & Collapsible Menu</h2>
        <div className="flex flex-col gap-4 max-w-60">
          <NavLink to="/home" className="flex gap-2 items-center no-underline font-normal text-slate-500 hover:text-blue-700 leading-none">
            <IconHome className=" size-4" />
            <span>Menu Title</span>
          </NavLink>
          <Disclosure>
            <DisclosureButton className="group p-0 shadow-none flex gap-2 items-center font-normal text-slate-500 hover:text-blue-700 leading-none">
              <IconHome className="size-4" />
              <span className="flex-grow text-left">Has Submenu</span>
              <Caret className="group-data-[open]:rotate-180" />
            </DisclosureButton>
            <DisclosurePanel className="flex flex-col gap-2 pl-6">
              <NavLink to="/menu1" className="no-underline font-normal text-slate-500 hover:text-blue-700">Child item 1</NavLink>
              <NavLink to="/menu2" className="no-underline font-normal text-slate-500 hover:text-blue-700">Child item 2</NavLink>
              <NavLink to="/menu3" className="no-underline font-normal text-slate-500 hover:text-blue-700">Child item 3</NavLink>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </div>
      <hr />
      <div className="p-5">
        <h2 className="mb-2 font-semibold text-2xl">Modal & Dialog</h2>
        <Button
          className="btn-secondary"
          onClick={() => this.setState({ defaultModal: true })}
        >Default Modal</Button>
        &nbsp;&nbsp;
        <Button
          className="btn-secondary"
          onClick={() => this.setState({ confirmDialog: true })}
        >Confirm Dialog</Button>
        <Modal
          open={this.state.defaultModal}
          onClose={() => this.setState({ defaultModal: false })}
        >
          <DialogTitle className="font-semibold text-lg mb-2">Title of the modal</DialogTitle>
          <p>The content of the modal</p>
          <div className="flex justify-end mt-4">
            <Button className="btn-tertiary" onClick={() => this.setState({ defaultModal: false })}>Close</Button>
          </div>
        </Modal>
        <ConfirmDialog
          type="danger"
          title="Deactivate account"
          text="Are you sure you want to deactivate your account? All of your data will be permanently removed from our servers forever. This action cannot be undone."
          confirmBtnText="Deactivate"
          open={this.state.confirmDialog}
          onClose={() => this.setState({ confirmDialog: false })}
        />
      </div>
      </>
    )
  }
}

export default UILibrary