import React, { Component } from 'react'
import { ReactComponent as Logo } from '../assets/images/event-ledger-logo.svg'
import { Button, Field, Input, Label } from "@headlessui/react"
import Spinner from "../components/Spinner"
import { Link } from "react-router-dom"

class Register extends Component {
  state = {
    show_pw: false,
    register_loading: false
  }

  register = () => {
    this.setState({ register_loading: true })
    setTimeout(() => this.setState({ register_loading: false}), 1500)
  }

  render() {
    return (
      <div className="container mx-auto xl:mx-0 flex justify-center gap-8">
        <div className="hidden flex-1 xl:block bg-[url('./assets/images/login-bg.png')] bg-no-repeat bg-cover h-screen w-[44rem]">&nbsp;</div>
        <div className="xl:flex-1">
          <div className="flex flex-col gap-3 py-60 w-80 bg-white h-screen">
            <div className="w-[150px] h-[48px] mb-2">
              <Logo />
            </div>
            <h2 className="font-semibold text-2xl mb-3">Create an account</h2>
            <Field className="field">
              <Label>Email Address</Label>
              <Input type="email" placeholder="youremail@domain.com"/>
            </Field>
            <Field className="field mt-2">
              <Label>Password</Label>
              <div className="relative">
                <Input
                  type={this.state.show_pw ? 'text' : 'password'}
                  className="w-full"
                  placeholder="Set password"
                />
                <small
                  className="absolute top-3 right-2 cursor-pointer text-slate-500"
                  onClick={() => this.setState({ show_pw: !this.state.show_pw })}
                >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
              </div>
            </Field>
            <Field className="field mt-2">
              <Label>Confirm Password</Label>
              <div className="relative">
                <Input
                  type={this.state.show_pw ? 'text' : 'password'}
                  className="w-full"
                  placeholder="Confirm password"
                />
                <small
                  className="absolute top-3 right-2 cursor-pointer text-slate-500"
                  onClick={() => this.setState({ show_pw: !this.state.show_pw })}
                >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
              </div>
            </Field>
            <Button
              className="btn-primary mt-2"
              onClick={this.register}
              disabled={this.state.register_loading}
            >
              <span className="flex gap-1 justify-center items-center">
                {this.state.register_loading && <Spinner />}
                <span>Create Account</span>
              </span>
            </Button>
            <div className="text-sm mt-2">
              <p><span className="text-slate-500">Already have an account?</span> <Link to="/login" className="link">Sign in</Link></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Register