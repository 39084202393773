import { Dialog, DialogPanel, Transition } from "@headlessui/react"
import React, { Component } from 'react'

class Modal extends Component {
  render() {
    const { open, onClose, children } = this.props

    return (
      <Transition
        show={open}
        enter="duration-200 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-300 ease-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog onClose={onClose}>
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-gray-950/50">
            <DialogPanel className="border bg-white rounded-md min-w-72 max-w-xl shadow-xl relative">
              <span
                role="button"
                className="absolute right-5 top-2 text-slate-400 hover:text-slate-500"
                onClick={onClose}
              >✕</span>
              <div className="px-5 pt-7 pb-5">
              {children}
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      </Transition>
    )
  }
}

export default Modal