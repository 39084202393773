import React, { Component } from 'react'
import {
  Button,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Field,
  Label,
  //Radio,
  //RadioGroup
} from "@headlessui/react"
import ReportPeriodSelector from "./ReportPeriodSelector"
import Caret from "../../../components/Caret"
import moment from "moment-timezone";

/*
const accountingMethodOptions = [
  {value: 'CASH', label: 'Cash'},
  {value: 'ACCRUAL', label: 'Accrual'},
]
*/

class CreateReportForm extends Component {
  state = {
    report_options: {
      selected_period: null,
      period_dates: {dateFrom: null, dateTo: null, dateAsOf: null},
    }
  }

  render() {
    //const { report_options } = this.state
    //const { onCreateReport, userData } = this.props

    return (
      <Disclosure defaultOpen as="div" className="bg-slate-50 px-4 pt-1 rounded relative">
        <DisclosurePanel className="pt-2 pb-6">
          <div className="flex flex-wrap items-center gap-x-12">
            <Field className="field">
              <Label>Report Period</Label>
              <ReportPeriodSelector
                onPeriodChanged={(selectedPeriod, dateFrom, dateTo, dateAsOf) => {
                  let report_options = this.state.report_options
                  report_options['selected_period'] = selectedPeriod
                  report_options['period_dates'] = {dateFrom: dateFrom, dateTo: dateTo, dateAsOf: dateAsOf}
                  this.setState({ report_options })
                  this.props.dateSpanSelected(
                    dateAsOf
                      ? `${moment(dateAsOf).format("DDMMYYYY")}-${moment(dateAsOf).format("DDMMYYYY")}`
                      : `${moment(dateFrom).format("DDMMYYYY")}-${moment(dateTo).format("DDMMYYYY")}`
                  )
                }}
              />
            </Field>
            {/* <Field className="field">
              <Label className="mb-2">Accounting Method</Label>
              <RadioGroup
                className="flex flex-row gap-6"
                value={accountingMethodOptions?.find(item => item.value === userData?.active_client?.accounting_type_human)}
                disabled={true}
              >
                {accountingMethodOptions.map(item => (
                <Field className="flex gap-1" key={`method-${item.value}`}>
                  <Radio
                    className="radio"
                    value={item}
                  /> <Label>{item.label}</Label>
                </Field>
                ))}
              </RadioGroup>
            </Field> */}
            {this.props.children}
          </div>
          <div className="flex items-center justify-end flex-1 gap-3 mt-4">
            {/* <Button className="btn-tertiary">
              Customize Format
            </Button> */}
            <Button
              className="btn-primary"
              onClick={() => this.props.onCreateReport(this.props.report_type)}
            >Create Report</Button>
          </div>
        </DisclosurePanel>
        <DisclosureButton as="span" role="button" className="group bg-slate-200 p-1 rounded-sm absolute left-4 -bottom-2">
          <Caret className="group-data-[open]:rotate-180" />
        </DisclosureButton>
      </Disclosure>
    )
  }
}

export default CreateReportForm
