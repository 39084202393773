import React, { Component } from 'react'
import { ReactComponent as Logo } from '../assets/images/event-ledger-logo.svg'
import { Button, Field, Input, Label } from "@headlessui/react"
import { Link } from "react-router-dom"
import Spinner from "../components/Spinner"

class ResetPassword extends Component {
  state = {
    show_pw: false,
    pwd_change_success: false,
    pw_change_loading: false
  }

  changePassword = () => {
    this.setState({ pw_change_loading: true })
    setTimeout(() => this.setState({
      pw_change_loading: false,
      pwd_change_success: true
    }), 1500)
  }

  render() {
    return (
      <div className="container mx-auto flex justify-center gap-8">
        <div className="flex flex-col gap-3 py-60 w-80 h-screen">
          <div className="w-[150px] h-[48px] mb-2 mx-auto">
            <Logo />
          </div>
          <h2 className="font-semibold text-2xl mb-3 text-center">Create New Password</h2>
          {!this.state.pwd_change_success ?
          <>
            <Field className="field">
              <Label>New Password</Label>
              <div className="relative">
                <Input type={this.state.show_pw ? 'text' : 'password'} className="w-full" />
                <small
                  className="absolute top-3 right-2 cursor-pointer text-slate-500"
                  onClick={() => this.setState({ show_pw: !this.state.show_pw })}
                >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
              </div>
            </Field>
            <Field className="field mt-2">
              <Label>Confirm New Password</Label>
              <div className="relative">
                <Input type={this.state.show_pw ? 'text' : 'password'} className="w-full" />
                <small
                  className="absolute top-3 right-2 cursor-pointer text-slate-500"
                  onClick={() => this.setState({ show_pw: !this.state.show_pw })}
                >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
              </div>
            </Field>
            <Button
              className="btn-primary mt-2"
              onClick={this.changePassword}
              disabled={this.state.pw_change_loading}
            >
              <span className="flex gap-1 justify-center items-center">
                {this.state.pw_change_loading && <Spinner />}
                <span>Reset Password</span>
              </span>
            </Button>
          </>
          :
          <>
            <p className="text-green-700">Your password has been successfully changed. Please sign in with the new password.</p>
            <Link to="/login" className="link">Sign in</Link>
          </>
          }
        </div>
      </div>
    )
  }
}

export default ResetPassword