import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ClientSettings from "./ClientSettings"
import { Button, Field, Input, Label, Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react"
import Spinner from "../../../components/Spinner"
import clientsActions from "../../../redux/clients/actions";

const {
  clientGroupAdd,
  clientGroupsGet,
  clientGroupUpdate,
  clientGroupDelete,
} = clientsActions;

class ClientGroupSettings extends Component {
  state = {
    group_name: null,
    group_update: {},
  }

  componentDidMount = () => {
    this.props.clientGroupsGet();
  }

  editGroupUpdate = (id, key, value) => {
    var group_update = {...this.state.group_update}
    if(group_update[id]){
      group_update[id][key] = value;
    } else {
      group_update[id] = {[key]: value}
    }
    this.setState({group_update: group_update})
  }

  render() {
    const {
      user_client_groups,
      //userData,
      user_client_groups_working,
    } = this.props;

    const {
      group_update,
      group_name,
    } = this.state;

    return (
      <>
        <h1 className="text-xl font-semibold leading-none mb-5">Client & Group Settings</h1>
        <TabGroup>
          <TabList className="mb-5">
            <Tab>Client Settings</Tab>
            <Tab>Group Settings</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ClientSettings />
            </TabPanel>
            <TabPanel>
              <div className="grid grid-cols-2">
                <div>
                  {user_client_groups.map(item => {
                    return (
                    <div
                      key={`group-${item.id}`}
                      className="py-3 px-4 mb-3 rounded bg-slate-100/75"
                    >
                      <div className="flex justify-between gap-4">
                        <Input
                          type="text"
                          className="flex-1"
                          defaultValue={item.name}
                          onChange={(e) => this.editGroupUpdate(item.id, "name", e.target.value)}
                        />
                        <div className="flex items-center gap-4">
                          <Button
                            className="btn-primary"
                            disabled={!group_update[item.id]?.name || user_client_groups_working}
                            onClick={() => this.props.clientGroupUpdate({
                              id: item.id,
                              name: group_update[item.id]?.name,
                            })}
                          >
                            <span className="flex gap-1 justify-center items-center">
                              {user_client_groups_working && <Spinner />}
                              <span>Save Changes</span>
                            </span>
                          </Button>
                          <span
                            role="button"
                            className="text-red-700 data-[disabled]:text-slate-500/50 data-[disabled]:pointer-events-none"
                            //data-disabled={user_client_groups_working}
                            onClick={() => this.props.clientGroupDelete({id: item.id})}
                          >Delete</span>
                        </div>
                      </div>
                    </div>
                  )})}
                  <h2 className="font-semibold mt-8 mb-4">Create a new group</h2>
                  <div className="py-3 px-4 mb-3 rounded bg-slate-100/75">
                    <Field className="field">
                      <Label>Group Name</Label>
                      <Input
                        type="text"
                        placeholder="New group name"
                        className="flex-1"
                        onChange={(e) => this.setState({ group_name: e.target.value})}
                      />
                    </Field>
                    <Button
                      className="btn-primary mt-4"
                      disabled={!group_name || user_client_groups_working}
                      onClick={() => this.props.clientGroupAdd({
                        name: group_name,
                      })}
                    >
                      <span className="flex gap-1 justify-center items-center">
                        {user_client_groups_working && <Spinner />}
                        <span>Add Group</span>
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      user_client_groups_working: state.Clients.get("user_client_groups_working"),
      user_client_groups: state.Clients.get("user_client_groups"),
    }),
    {
      clientGroupAdd,
      clientGroupsGet,
      clientGroupUpdate,
      clientGroupDelete,
    }
  )(ClientGroupSettings)
);
