import React, { Component } from 'react'

class VendorsReport extends Component {

  render() {
    const {
      userData,
      report,
    } = this.props;

    return (
      <div>
        <div className="border rounded px-4 py-8">
          <div className="text-center mb-6">
            <h3 className="text-2xl mb-2">{userData?.active_client?.name}</h3>
            <div>
              <h4 className="font-semibold">Vendors</h4>
            </div>
          </div>
          <table className="table-auto w-full">
            <thead className="border-b-2 border-slate-200">
              <tr>
                <th className="p-1">Vendor Name</th>
                <th className="p-1">Account Number</th>
                <th className="p-1">Contact</th>
                <th className="p-1">Phone</th>
                <th className="p-1">Email</th>
                <th className="p-1">Address</th>
              </tr>
            </thead>
            <tbody>
              {report?.data?.map((item, idx) => (
              <tr
                key={`${item?.id}-${idx}`}
                className="border-b-2 border-slate-200"
              >
                <td className="p-1">{item?.name}</td>
                <td className="p-1">{item?.account_number}</td>
                <td className="p-1">{item?.account_number}</td>
                <td className="p-1">{item?.contact_person}</td>
                <td className="p-1">{item?.contact_person_phone}</td>
                <td className="p-1">{item?.contact_person_email}</td>
                <td className="p-1">
                  {item.address_line_1}<br/>
                  {item.address_line_2}<br/>
                  {item.address_city}, {item.address_state}, {item.address_zip}<br/>
                  {item.address_country}
                </td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default VendorsReport;
