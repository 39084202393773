import { Button, Field, Input, Label } from "@headlessui/react"
import { connect } from "react-redux";
import { withRouter } from "react-router";
import React, { Component } from 'react'
import Spinner from "../../../components/Spinner"
import Select from 'react-select'
import { sdMessage } from "../../../helpers/utils"
import clientsActions from "../../../redux/clients/actions";

const {
  clientCreate,
} = clientsActions;

class AddClient extends Component {
  state = {
    client_name: null,
    add_working: false,
    client_added: false,
    selectedUsers: null,
    assign_working: false,
    user_assigned: false
  }

  addClient = () => {
    this.props.clientCreate({name: this.state.client_name})
  }

  assignClient = () => {
    this.setState({ assign_working: true })
    setTimeout(() => {
      this.setState({
        assign_working: false,
        user_assigned: true
      })
      sdMessage('New client has been assigned to user(s)', 'success')
      setTimeout(() => this.props.history.push('/app/select-client'), 2000)
    }, 1500)
  }

  render() {
    const { user_client_create_working } = this.props;

    return (
      <div>
      <div className="flex justify-between items-center">
          <div>
            <h1 className="text-xl font-semibold">Add Client</h1>
            <p className="text-slate-500">Add a new client and assign user</p>
          </div>
          <Button className="btn-tertiary" onClick={() => this.props.history.goBack()}>
            {this.state.client_added ? 'Skip' : <span>&larr; Cancel</span>}
          </Button>
        </div>
        {!this.state.client_added ?
        <div className="mt-8">
          <Field className="field">
            <Label>Select Group</Label>
            <Select
              placeholder="Select client group..."
              options={[
                {value: 1, label: 'Luna Starlight'},
                {value: 2, label: 'The Rhythm Rebels'},
              ]}
            />
          </Field>
          <Field className="field mt-4">
            <Label>Client Name</Label>
            <Input
              type="text"
              placeholder="Provide client name"
              onChange={e => this.setState({client_name: e.target.value})}
              value={this.state.client_name}
            />
          </Field>
          <Button
            className="btn-primary mt-4"
            disabled={!this.state.client_name || user_client_create_working}
            onClick={this.addClient}
          >
            <span className="flex gap-1 justify-center items-center">
              {user_client_create_working && <Spinner />}
              <span>Add Client</span>
            </span>
          </Button>
        </div>
        :
        <div className="mt-8">
          <Field className="field">
            <Label>Assign user(s)</Label>
            <Select
              placeholder="Select one or more users"
              options={[
                {value: 1, label: 'username1 (FirstName LastName)'},
                {value: 2, label: 'username2 (FirstName LastName)'},
                {value: 3, label: 'username3 (FirstName LastName)'}
              ]}
              value={this.state.selectedUsers}
              onChange={selected => this.setState({ selectedUsers: selected })}
              isMulti
              isDisabled={this.state.user_assigned}
            />
          </Field>
          {/* Since I assume multiple users can be assigned to a client, so we need a button to save the relationship */}
          <Button
            className="btn-primary mt-4"
            disabled={this.state.selectedUsers === null || this.state.assign_working || this.state.user_assigned}
            onClick={this.assignClient}
          >
            <span className="flex gap-1 justify-center items-center">
              {this.state.assign_working && <Spinner />}
              <span>Assign to client</span>
            </span>
          </Button>
        </div>
        }
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      user_client_create_working: state.Clients.get("user_client_create_working"),
    }),
    {
      clientCreate,
    }
  )(AddClient)
);
