import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useParams } from "react-router"
import "react-datepicker/dist/react-datepicker.css"
import invoicesActions from "../../redux/invoices/actions";
import EnterInvoice from "./EnterInvoice";
import documentsActions from "../../redux/documents/actions";

const { invoicesGetInvoice } = invoicesActions;

const {
  documentsDocumentPreview,
} = documentsActions;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class ViewInvoice extends Component {

  componentDidMount = () => {
    const { userData, params } = this.props;
    this.props.invoicesGetInvoice({
      client_id: userData?.active_client?.id,
      invoice_id: params.id,
    })
  }

  componentDidUpdate = (pp) => {
    const { userData, invoice } = this.props;
    if(pp.invoice === null && invoice){
      this.props.documentsDocumentPreview({
        client_id: userData?.active_client?.id,
        document: invoice?.document,
      })
    }
  }

  render() {
    const { invoice } = this.props;

    return (
      <EnterInvoice
        data={invoice ? invoice : null}
        edit={true}
      />
    )
  }
}

export default withParams(withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      document_preview_url: state.Documents.get("document_preview_url"),
      document_preview_url_working: state.Documents.get("document_preview_url_working"),
      vendors: state.Vendors.get("vendors"),
      vendors_working: state.Vendors.get("vendors_working"),
      invoices_working: state.Invoices.get("invoices_working"),
      invoice: state.Invoices.get("invoice"),
    }),
    {
      invoicesGetInvoice,
      documentsDocumentPreview,
    }
  )(ViewInvoice)
));
