import { Button, Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react"
import React, { Component } from 'react'
import Account from "./Account"
import Users from "./Users"

class Settings extends Component {
  render() {
    return (
      <>
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-semibold leading-none mb-5">Settings</h1>
          <Button
            className="btn-tertiary"
            onClick={() => this.props.history.goBack()}
          >&larr; Back</Button>
        </div>
        <TabGroup>
          <TabList className="mb-5">
            <Tab>Account</Tab>
            <Tab>Users</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Account />
            </TabPanel>
            <TabPanel>
              <Users />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </>
    )
  }
}

export default Settings