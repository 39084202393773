import { Button, Field, Label, Textarea, Transition } from "@headlessui/react"
import { connect } from "react-redux";
import { withRouter } from "react-router";
import React, { Component, useCallback, useState } from 'react'
import { useDropzone } from "react-dropzone"
import Spinner from "../../components/Spinner"
import documentsActions from "../../redux/documents/actions";
import { ReactComponent as IconFilePDF } from '../../assets/icons/icon-file-pdf.svg'

const {
  documentsAddDocument,
} = documentsActions;

const MAX_FILESIZE_NUMERIC = 5
const MAX_FILESIZE_BYTES = MAX_FILESIZE_NUMERIC * 1048576

function FileUpload({ setFile }) {
  const [fileName, setFileName] = useState()
  const [errors, setErrors] = useState([])

  const onDrop = useCallback((acceptedFiles, rejectedFiles)  => {
    setErrors([])
    if(acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      setFileName(file.name)
      setFile(file)
    }

    if(rejectedFiles.length > 0) {
      const file = rejectedFiles[0]
      setErrors(file.errors)
    }
  }, [setFile])

  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept: {'application/pdf': ['.pdf']},
    maxSize: MAX_FILESIZE_BYTES,
    onDrop
  })

  return (
    <div 
      {...getRootProps()} 
      className="border bg-slate-50 rounded-md px-5 flex flex-col items-center justify-center text-center cursor-pointer min-h-[330px]"
    >
      <input {...getInputProps()} />
      <Transition
        show={!!fileName}
        enter="duration-500 ease-in"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        <div className="mb-1">
          <IconFilePDF className="size-16 inline" />
          <p className="font-semibold mt-1">{fileName}</p>
        </div>
      </Transition>
      {errors.length > 0 && errors.map(err => <p key={err.code} className="text-red-500">{err.message}</p>)}
      <div className="text-slate-500">
        <p>Drag and drop your PDF file here.</p>
        <p>Max size: {MAX_FILESIZE_NUMERIC} MB</p>
      </div>
    </div>
  )
}

class Upload extends Component {
  state = {
    selectedFile: null
  }

  upload = () => {
    const { selectedFile, description } = this.state;
    const { userData } = this.props;

    var postData = new FormData();
    postData.append('document', selectedFile);
    postData.append('description', description);
    postData.append('client_id', userData.active_client.id);
    postData.append('document_type', "invoice");

    this.props.documentsAddDocument(postData);
  }

  render() {
    const { userData, documents_working } = this.props;

    return (
      <div>
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-xl font-semibold leading-none">Upload Invoice</h1>
          <Button
            className="btn-secondary"
            onClick={() => this.props.history.push(`/app/client/${userData.active_client.slug}/invoices/enter`)}
          >Enter Invoice</Button>
        </div>
        <FileUpload
          setFile={file => this.setState({ selectedFile: file })}
        />
        <Field className="field mt-4">
          <Label>Description</Label>
          <Textarea
            onChange={(e) => this.setState({description: e.target.value})}
          />
        </Field>
        <Button
          className="btn-primary mt-4"
          disabled={documents_working}
          onClick={this.upload}
        >
          <span className="flex gap-1 justify-center items-center">
            {documents_working && <Spinner />}
            <span>Upload File</span>
          </span>
        </Button>
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      documents_working: state.Documents.get("documents_working"),
    }),
    {
      documentsAddDocument,
    }
  )(Upload)
);
