import { Map } from "immutable";

const initState = new Map({
  users: [],
  users_working: false,
});

export default function companiesReducer(state = initState, action) {
  switch (action.type) {

    case "COMPANIES_GET_USERS":
      return state
        .set("users", [])
        .set("users_working", true)
    case "COMPANIES_GET_USERS_SUCCESS":
      return state
        .set("users", [...action.data.data])
        .set("users_working", false)
    case "COMPANIES_GET_USERS_FAILED":
      return state
        .set("users", [])
        .set("users_working", false)

		default:
			return state;
  }
}
