import { Button, Field, Label } from "@headlessui/react"
import React, { Component } from 'react'
//import Select from 'react-select'
import SelectGLAccount from '../../../components/SelectGLAccount'

const report_type_options = [
  {value: 'general_ledger', label: 'General Ledger'},
  {value: 'activity_ledger', label: 'Activity Ledger'}
]

class LedgerReportForm extends Component {
  state = {
    options: {
      ranges: [
      ],
      report_type: report_type_options[0]
    }
  }

  addRange = () => {
    let options = {...this.state.options}
    options.ranges.push({
      id: crypto.randomUUID(),
      range_from: null,
      range_to: null
    })
    this.setState({ options })
    this.props.setOptions(options);
  }

  removeRange = (id) => {
    let options = {...this.state.options}
    options['ranges'] = options.ranges.filter(r => r.id !== id)
    this.setState({ options })
    this.props.setOptions(options);
  }

  updateGlAccount = (id, range, value) => {
    var options = {...this.state.options}
    const ranges = options.ranges.map(item => {
      if(item.id === id){
        item[range] = value;
      }
      return item;
    });
    options.ranges = [...ranges];
    this.setState({ options })
    this.props.setOptions(options);
  }

  render() {
    const { options } = this.state

    return (
      <>
        {/*
        <Field className="field">
          <Label>Report Type</Label>
          <Select
            value={options?.report_type}
            options={report_type_options}
            onChange={selected => {
              let current_options = options
              current_options['report_type'] = selected
              this.setState({ options: current_options })
            }}
          />
        </Field>
        */}
        <div className="basis-full h-0">&nbsp;</div> {/* break row */}
        <Field className="field mt-4">
          <Label>Account Ranges</Label>
          {options?.ranges.map((range, idx) => (
          <div className="flex gap-2 items-center flex-1" key={`ranges-${idx}`}>
            <SelectGLAccount
              containerClassNames="w-60"
              onChange={(value) => this.updateGlAccount(range.id, "range_from", value)}
            />
            <span className="text-slate-500">to</span>
            <SelectGLAccount
              containerClassNames="w-60"
              onChange={(value) => this.updateGlAccount(range.id, "range_to", value)}
            />
            {idx > 0 &&
            <Button
              className="btn-tertiary btn-sm text-lg"
              onClick={() => this.removeRange(range.id)}
            >-</Button>
            }
          </div>
          ))}
          <div className="mt-1">
            <Button
              className="btn-tertiary btn-sm inline-block"
              onClick={this.addRange}
            >+ Add range</Button>
          </div>
        </Field>
      </>
    )
  }
}

export default LedgerReportForm
