import { Button, Input, Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import React, { Component } from "react";
import VendorForm from "./VendorForm";
import { formatPhoneNumberIntl } from 'react-phone-number-input'
//import ConfirmDialog from "../../components/ConfirmDialog";
import vendorsActions from "../../redux/vendors/actions";
import SortableHead from "../../components/SortableHead";
import Pagination from "../../components/Pagination";

const { vendorsGetAllForClient } = vendorsActions;

class Vendors extends Component {
  state = {
    mode: 'list-vendor',
    deleteVendorId: null,
    edit_vendor_data: null,
    active_sort_field: 'name',
    active_sort_direction: 'asc'
  }

  componentDidMount() {
    const { userData } = this.props;
    this.props.vendorsGetAllForClient({
      client_id: userData?.active_client?.id,
      pagination: true,
    });
  }

  componentDidUpdate = (pp) => {
    const { vendors } = this.props;
    if(vendors?.data && vendors?.data?.length !== pp.vendors?.data?.length){
      this.setState({mode: "list-vendor"})
    }
  }

  sort = (name) => {
    this.setState({
      active_sort_field: name,
      active_sort_direction: this.state.active_sort_direction === 'asc' ? 'desc' : 'asc'
    })
  }

  render() {
    const { vendors, vendors_working, userData } = this.props;

    if(this.state.mode === 'list-vendor') {
      return (
        <>
          <div className="flex justify-between items-center mb-5">
            <h1 className="text-xl font-semibold leading-none">Vendors</h1>
            <Button className="btn-primary" onClick={() => this.setState({ mode: 'add-vendor' })}>Add vendor</Button>
          </div>
          {vendors_working ?
          <VendorsLoading />
          :
          <>
            <div className="mb-4">
              <Input type="text" placeholder="Search" />
            </div>
            <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4">
              <table className="w-full text-[15px] table-auto">
                <thead className="border-b-2 border-slate-200">
                  <tr>
                    <SortableHead
                      title="Vendor Name"
                      field="name"
                      active_field={this.state.active_sort_field}
                      active_direction={this.state.active_sort_direction}
                      sort={() => this.sort('name')}
                    />
                    <SortableHead
                      title="Vendor Account Number"
                      field="account_number"
                      active_field={this.state.active_sort_field}
                      active_direction={this.state.active_sort_direction}
                      sort={() => this.sort('account_number')}
                    />
                    <th className="py-2">Contact Person</th>
                    <th className="py-2">Phone</th>
                    <th className="py-2">Email</th>
                    <th className="py-2">Address</th>
                    <th className="py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {vendors?.data && vendors?.data?.map(item => (
                    <tr key={`row-${item.id}`} className="border-t border-slate-200">
                      <td className="py-2">{item.name}</td>
                      <td className="py-2">{item.account_number}</td>
                      <td className="py-2">{item.contact_person}</td>
                      <td className="py-2">{formatPhoneNumberIntl(item.contact_person_phone)}</td>
                      <td className="py-2">{item.contact_person_email}</td>
                      <td className="py-2">
                        <span>{item.address_state}, {item.address_country}</span>
                        <Popover className="relative">
                          <PopoverButton as="span" className="text-blue-700 text-sm" role="button">View more</PopoverButton>
                          <PopoverPanel anchor="bottom start" className="popover-panel">
                            {item.address_line_1}<br/>
                            {item.address_line_2}<br/>
                            {item.address_city}, {item.address_state}, {item.address_zip}<br/>
                            {item.address_country}
                          </PopoverPanel>
                        </Popover>
                      </td>
                      <td className="py-2">
                        <Button
                          className="btn-secondary btn-sm"
                          onClick={() => this.setState({
                            mode: 'edit-vendor',
                            edit_vendor_data: item,
                          })}
                        >Change</Button>
                        {/*
                        <Button
                          className="btn-danger btn-sm ml-2"
                          onClick={() => this.setState({
                            deleteVendorId: item.id
                          })}
                        >Delete</Button>
                        Not sure about deleting vendor here, perhaps for now only vendor without transaction data can be deleted
                        Example case: user just created duplicate vendor data and the newly created vendor need to be deleted
                        So delete button is conditionally displayed
                        <ConfirmDialog
                          open={this.state.deleteVendorId !== null }
                          type="danger"
                          title="Delete Vendor"
                          text="Are you sure want to delete this vendor?"
                          onClose={() => this.setState({ deleteVendorId: null })}
                          onConfirm={() => {
                            sdMessage('A vendor has been deleted.', 'success')
                            this.setState({ deleteVendorId: null })
                          }}
                        />
                        */}
                      </td>
                    </tr>
                  ))
                  }
                </tbody>
                {vendors?.data?.length === 0 &&
                <tfoot>
                  <tr>
                    <td colSpan={6} className="py-2 text-slate-500 text-center">No data found.</td>
                  </tr>
                </tfoot>
                }
              </table>
            </div>
            <div className="flex flex-col items-end">
              <Pagination
                data={vendors}
                callback={this.props.vendorsGetAllForClient}
                callback_params={{client_id: userData?.active_client?.id}}
              />
            </div>
          </>
          }
        </>
      )
    } else if(this.state.mode === 'add-vendor') {
      return (
        <VendorForm
          mode={this.state.mode}
          back={() => this.setState({
            mode: 'list-vendor',
            edit_vendor_data: null,
          })}
        />
      )
    } else if(this.state.mode === 'edit-vendor') {
      return (
        <VendorForm
          mode={this.state.mode}
          back={() => this.setState({
            mode: 'list-vendor',
            edit_vendor_data: null,
          })}
          data={this.state.edit_vendor_data}
        />
      )
    }
  }
}

const VendorsLoading = () => (
  <>
    <div className="bg-slate-100/75 rounded-lg mb-5 px-3 py-5 animate-pulse">
      <div className="grid grid-cols-7 mb-5 gap-10 content-evenly">
      {Array.from(Array(7)).map((item,idx) =>(
        <div key={`skeleton-head-${idx}`} className="h-2 bg-slate-300 rounded" />
      ))}
      </div>
      {Array.from(Array(5)).map((item,idx) =>(
      <div key={`skeleton-${idx}`} className="mb-5 h-8 bg-slate-300 rounded"></div>
      ))}
    </div>
  </>
)


export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      vendors: state.Vendors.get("vendors"),
      vendors_working: state.Vendors.get("vendors_working"),
    }),
    {
      vendorsGetAllForClient,
    }
  )(Vendors)
);
